import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import React, { useState, forwardRef, useEffect } from "react";
import PropType from "prop-types";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState , ContentState} from "draft-js";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from "html-to-draftjs";
import DOMPurify from 'dompurify';

const Media = (props) => {
  const { block, contentState } = props;
  const entity = contentState.getEntity(block.getEntityAt(0));
  const { src } = entity.getData();
  const type = entity.getType();

  let media;

  if (type === 'IMAGE') {
    media = <img src={src} alt="media" />;
  } else if (type === 'EMBEDDED_LINK') {
    media = <iframe title="embedded media" src={src} frameBorder="0" />;
  } else if (type === 'VIDEO_FILE') {
    media = (
      <video controls width="853" height="480">
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    );
  }

  return media;
};

const RichTextEditor = forwardRef(({ getMarkdownValue, desc }, ref) => {
  const [value, setValue] = useState(null);

  useEffect(() => {
    if(desc){
    const blocksFromHtml = htmlToDraft(desc);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const newEditorState = EditorState.createWithContent(contentState);
      setValue(newEditorState);
    }
    else{
      setValue(EditorState.createEmpty());
    }
  }, [desc]);


  const editorStateChange = (editorState) => {
    
    setValue(editorState);
  };

  const handleBlur = () => {
    const currentContent = value.getCurrentContent();
    const rawContent = convertToRaw(currentContent);
    const html = draftToHtml(rawContent);
    const sanitizedHtml = DOMPurify.sanitize(html);
    getMarkdownValue(sanitizedHtml);
  };

  function myBlockRenderer(contentBlock) {
    const type = contentBlock.getType();
    // Convert image type to mediaComponent
    if (type === 'atomic') {
      return {
        component: Media,
        editable: false,
        props: {
          foo: 'bar',
        },
      };
    }
  }

  return (
    <>
      <Editor
        editorState={value}
        ref={ref}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        editorStyle={{
          border: "1px solid #ccc",
          minHeight: "20rem",
        }}
        blockRendererFn={myBlockRenderer}
        toolbar={{
          options: ["inline", "blockType", "fontSize", "fontFamily", "list", "textAlign", "colorPicker", "link", "remove", "image", "history", "emoji"],
          image: {
            uploadCallback: undefined,
            alt: { present: true, mandatory: false },
            previewImage: true,
            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
            defaultSize: {
              height: 'auto',
              width: 'auto',
            },
          },
        }}
        onEditorStateChange={editorStateChange}
        onBlur={handleBlur}
      />
    </>
  );
});

RichTextEditor.propType = {
  getHtmlValue: PropType.func.isRequired,
};

export default RichTextEditor;
