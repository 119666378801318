import React from 'react';
import { Spin } from 'antd';

const Loader = (props) => {


    console.log("props Loader", props)

    return (
        <>
            {Object?.keys(props).length === 0 && <Spin size="default" style={{ marginLeft: 10, marginRight: 10 }} />}
        </>


    )



}

export default Loader;
