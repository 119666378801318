/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
  Input,
  Checkbox,
  Form,
  Space,
  Descriptions,
  Col,
  message,
  Row,
  Popconfirm,
} from "antd";

import Loader from "../../components/Loading/Loader";
import Button from "antd-button-color";
import { BiDollar } from "react-icons/bi";
import UserActions from "../../actions";
import { useHistory } from "react-router-dom";
import { Uploader, DocViewer } from "../../components";
import { UploadOutlined } from "@ant-design/icons";

const FormItem = Form.Item;

const Invoice = (props) => {
  let history = useHistory();
  const {
    indv,
    indv: { merchantId, bizName, bizid, address },
  } = useSelector(({ auth }) => auth);
  const { inv, current_location, interaction, callback } = props;
  const [isLoading, setIsLoading] = useState(false);

  const PENDING = "pending";
  const [objid, setObjid] = useState(null);
  // const [invoiceDt, setInvoiceDt] = useState(null);
  const [currency, setCurrency] = useState(indv.currency);
  const [currencySymbol, setCurrencySymbol] = useState(indv.currencySymbol);
  const [title, setTitle] = useState(`Invoice from ${bizName}`);
  const [invoiceNumber, setInvoiceNumber] = useState(null);
  const [serverName, setServerName] = useState(null);
  const [msg, setMsg] = useState(null);
  const [baseAmount, setBaseAmount] = useState(null);
  const [isTaxIncluded, setIsTaxIncluded] = useState(true);
  const [taxAmount, setTaxAmount] = useState(null);
  const [total, setTotal] = useState(null);
  const [taxRate, setTaxRate] = useState(null);
  const [postalCode, setPostalCode] = useState(null);
  const [country, setCountry] = useState(null);
  const [img, setImg] = useState(null);
  const [status, setStatus] = useState(null);
  // const [buyerName, setBuyerName] = useState(null);
  const [imgUrl, setImgUrl] = useState(null);
  const [fileExtn, setFileExtn] = useState(null);
  const [resetUploader, setResetUploader] = useState(false);

  useEffect(() => {
    if (inv) {
      const { invoiceDetails } = inv;
      setObjid(inv.objid);

      //react form controller component
      setValue("msg", invoiceDetails.cust_message);
      setValue("baseAmount", inv.baseAmount);
      setValue("title", invoiceDetails.title);
      setValue("invoiceNumber", invoiceDetails.invoice_nbr);
      setValue("serverName", invoiceDetails.server_name);

      setTaxAmount(inv.taxAmount + "");
      setCurrency(inv.currency);
      setCurrencySymbol(inv.currencySymbol);
      // setInvoiceDt(inv.invoiceDt);
      setStatus(inv.status);
      // setBuyerName(inv.buyerFname + " " + inv.buyerLname);
      setIsTaxIncluded(inv.includesTax);
      const tot = Number(inv.taxAmount) + Number(inv.baseAmount);
      setTotal(tot.toFixed(2));
      setPostalCode(inv.buyerPostalCode);
      setCountry(inv.buyerCountry);
      if (invoiceDetails.img_url) {
        setImgUrl(invoiceDetails.img_url);
      }
    }
  }, [inv]);

  useEffect(() => {
    if (current_location) {
      setPostalCode(current_location.postalCode);
      setCountry(current_location.country);
    }
  }, [current_location]);

  const handleChangeAmount = async (value) => {
    if (isNaN(value)) return "Amount not valid";
    else if (value === 0) return "Amount not valid";

    setValue("baseAmount", value);
    setTotal(null);
    setTaxAmount(null);
    let tax = 0;

    if (!isTaxIncluded) {
      let rate = taxRate ? taxRate : await getTaxRate();
      tax = Number(value) * Number(rate);
      setTaxAmount(tax.toFixed(2));
    }

    if (value) {
      const tot = tax + Number(value);
      setTotal(tot.toFixed(2));
    }
  };

  const handleChangeIncludeTax = async (isIncluded) => {
    const value = getValues("baseAmount");
    setTaxAmount(null);
    setIsTaxIncluded(isIncluded);
    if (!isIncluded && value) {
      //fetch applicable tax
      let rate = taxRate ? taxRate : await getTaxRate();
      if (rate) {
        let tax = Number(value) * Number(rate);
        setTaxAmount(tax.toFixed(2));
        const tot = tax + Number(value);
        setTotal(tot.toFixed(2));
      }
    } else if (value) {
      setTotal(Number(value).toFixed(2));
    }
  };

  const getTaxRate = async () => {
    if (taxRate) return taxRate;
    //fetch applicable tax
    const resp = await UserActions.getTax({ postalCode, country });

    if (!resp.success) {
      message.error("Tax could not be obtained");
      return false;
    }
    setTaxRate(resp.data.taxRate);
    return resp.data.taxRate;
  };

  const sendInvoice = async () => {
    const payload = {
      objid,
      bizid,
      merchantId,
      baseAmount: getValues("baseAmount"),
      taxAmount,
      currency,
      currencySymbol,
      invoiceDetails: {
        business_name: bizName,
        business_country: address.country,
        interaction_id: inv
          ? inv.invoiceDetails.interaction_id
          : interaction.id,
        title: getValues("title"),
        invoice_nbr: getValues("invoiceNumber"),
        server_name: getValues("serverName"),
        cust_message: getValues("msg"),
      },
      includesTax: isTaxIncluded,
      channel: "interaction",
    };

    //add mode
    if (interaction) {
      payload.buyerQRId = interaction.qrId;
    }
    if (img) {
      payload.invoiceDetails.img = img;
      payload.invoiceDetails.invoiceFileExtn = fileExtn;
    }

    setIsLoading(true);
    let response = await UserActions.saveInvoice(payload, "rcpt");
    setIsLoading(false);
    if (response.success) {
      message.success("Invoice Sent");
      if (callback) callback();
      else {
        history.goBack();
      }
    } else {
      message.error(response.msg);
    }
  };

  const cancelInvoice = async () => {
    inv.status = "canceled";
    inv.bizid = bizid;

    setIsLoading(true);
    const resp = await UserActions.saveInvoice(inv, "rcpt");
    setIsLoading(false);
    if (resp.success) {
      message.success("Invoice Canceled");
      if (callback) callback();
    } else {
      message.error(resp.msg);
    }
  };

  const { handleSubmit, errors, control, getValues, setValue } = useForm({
    mode: "onChange",
  });

  const layout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 4,
      span: 16,
    },
  };

  return (
    <Form {...layout}>
      <FormItem
        label="Title"
        help={errors.title?.message}
        validateStatus={errors.title ? "error" : "success"}
      >
        <Controller
          as={<Input type="text" placeholder="Title" />}
          control={control}
          defaultValue={title}
          name="title"
          rules={{
            required: true,
          }}
          onChange={(e) => setTitle(e.target.value)}
        />
      </FormItem>
      <FormItem
        label="Invoice #"
        help={errors.invoiceNumber?.message}
        validateStatus={errors.invoiceNumber ? "error" : "success"}
      >
        <Controller
          as={<Input type="text" placeholder="Invoice #" />}
          control={control}
          defaultValue={invoiceNumber}
          name="invoiceNumber"
          rules={{
            required: true,
          }}
          onChange={(e) => setInvoiceNumber(e.target.value)}
        />
      </FormItem>
      <FormItem label="Associate Name">
        <Controller
          as={<Input type="text" placeholder="Associate Name" />}
          control={control}
          defaultValue={serverName}
          name="serverName"
          onChange={(e) => setServerName(e.target.value)}
        />
      </FormItem>
      <FormItem label="Message">
        <Controller
          as={<Input type="text" placeholder="Message to customer" />}
          control={control}
          defaultValue={msg}
          name="msg"
          onChange={(e) => setMsg(e.target.value)}
        />
      </FormItem>
      <FormItem
        label="Amount"
        help={errors.baseAmount?.message}
        validateStatus={errors.baseAmount ? "error" : "success"}
      >
        <Controller
          as={
            <Input
              type="text"
              prefix={<BiDollar className="prefix" />}
              placeholder="Amount"
            />
          }
          control={control}
          defaultValue={baseAmount}
          name="baseAmount"
          rules={{
            required: true,
            validate: (value) => handleChangeAmount(value),
          }}
          onChange={(e) => setBaseAmount(e.target.value)}
        />
      </FormItem>
      <FormItem {...tailLayout}>
        <Descriptions title="" size="small">
          <Descriptions.Item label="">
            <Checkbox
              checked={isTaxIncluded}
              onChange={(e) => handleChangeIncludeTax(e.target.checked)}
            >
              Invoice includes taxes
            </Checkbox>
          </Descriptions.Item>
          <Descriptions.Item label="Tax">
            {taxAmount && Number(taxAmount) > 0 ? `$${taxAmount}` : ""}
          </Descriptions.Item>
          <Descriptions.Item label="Total">
            {total ? `$${total}` : ""}
          </Descriptions.Item>
        </Descriptions>
        <br />

        <Row>
          {imgUrl !== undefined && (
            <Row justify="start" style={{ marginRight: "5rem" }}>
              <Col>
                <DocViewer uri={imgUrl} title={title} />
              </Col>
            </Row>
          )}
          <Col span={10} style={{ marginLeft: "-5rem" }}>
            <Uploader
              uploaderType="text"
              label={<Button icon={<UploadOutlined />}>Select File</Button>}
              allowedTypes={["image", "pdf"]}
              // imgUrl={imgUrl}
              callback={({ name, img }) => {
                setImg(img.substring(img.indexOf("base64,") + 7));
                setResetUploader(false);
                setFileExtn(name.split(".").pop());
              }}
              aspect={0.7}
              reset={resetUploader}
            />
          </Col>
        </Row>
      </FormItem>

      <FormItem {...tailLayout}>
        <Row>
          <Row justify="start">
            <Col>
              <Space direction="horizontal">
                {(status === PENDING || !inv) && (
                  <Button
                    disabled={isLoading}
                    type="primary"
                    onClick={() => {
                      handleSubmit(
                        () => sendInvoice(),
                        (err) => console.log("...error....", err)
                      )();
                    }}
                  >
                    Save & Send
                  </Button>
                )}
                {status === PENDING && (
                  <Popconfirm
                    title="Are you sure to cancel this invoice?"
                    onConfirm={cancelInvoice}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="warning" disabled={isLoading}>
                      Cancel Invoice
                    </Button>
                  </Popconfirm>
                )}
                {isLoading && <Loader />}
              </Space>
            </Col>
          </Row>
        </Row>
      </FormItem>
    </Form>
  );
};

export default Invoice;
