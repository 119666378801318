import React, { useRef, useState } from "react";
import { Row, Form, Button, message, Space } from "antd";
import {
  Container as ContainerWrapper,
  CatalogueForm as FormCatalogue,
} from "../../components";
import { useForm } from "react-hook-form";
import { FormSchema } from "../../common";
import { useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers";
import Loader from "../../components/Loading/Loader";

import UserAction from "../../actions";
const EditCategory = (props) => {
  const { isEdit, callback, item } = props;

  const nameRef = useRef(null);
  const descRef = useRef(null);
  const { CatalogueForm } = FormSchema;

  const {
    indv: { bizid },
  } = useSelector(({ auth }) => auth);

  const [payload, setPayload] = useState({
    name: item?.name || "",
    description: item?.description || null,
    images: item?.images || [],
  });
  const [descError] = useState({
    status: false,
    message: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const layout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const { handleSubmit, errors, control } = useForm({
    mode: "onBlur",
    shouldUnregister: false,
    resolver: yupResolver(CatalogueForm),
  });
  const restFormValidation = async (data) => {
    let dispatchPayload = {
      images: payload.images,
      description: payload.description,
      name: data.name,
      bizid,
    };
    //API call for the Add category
    let ImageArray = dispatchPayload.images;
    ImageArray.map((item, index) => {
      let keys = Object.keys(item);
      if (keys.includes("img_url")) {
        ImageArray.splice(index, 1);
      }
      return null;
    });

    if (ImageArray.length < 1) {
      delete dispatchPayload.images;
    }
    if (ImageArray[0]?.img === null) {
      dispatchPayload.images = [];
    }
    setIsLoading(true);
    if (item?.objid) {
      dispatchPayload.objid = item.objid;
    }

    let resp = await UserAction.addCategory([dispatchPayload]);
    resp.success ? message.success(resp.message) : message.error(resp.message);

    callback(false);
  };
  const handleAddCategory = () => {
    handleSubmit(
      (data) => {
        restFormValidation(data);
      },
      (err) => console.log("error", err)
    )();
  };
  return (
    <ContainerWrapper title={isEdit ? `Edit : ${item?.name}` : "Add Category"}>
      <Row justify="start">
        <Form style={{ minWidth: "100%", padding: "2rem" }} {...layout}>
          <FormCatalogue
            formHook={{ errors, handleSubmit, control }}
            nameRef={nameRef}
            descRef={descRef}
            getMarkdownValue={(value) => {
              setPayload({ ...payload, description: value });
            }}
            desc={payload.description}
            name={payload.name}
            imgs={payload.images}
            descError={descError}
            imageCallback={(value) => {
              let imgs = payload.images.filter(
                (row) => row.index !== value.index
              );
              imgs.push(value);
              imgs.sort((a, b) => a.index > b.index);
              setPayload({ ...payload, images: imgs });
            }}
          />
        </Form>
      </Row>
      <Row style={{ width: "100%" }} justify="end">
        <Space>
          <Button
            disabled={isLoading}
            type="warning"
            onClick={() => callback(false)}
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            type="primary"
            onClick={handleAddCategory}
          >
            {isEdit ? "Update" : "Save"}
            {isLoading && <Loader />}
          </Button>
        </Space>
      </Row>
    </ContainerWrapper>
  );
};

export default EditCategory;
