import React, { useEffect, useState } from "react";
import { isMobile } from "../../common/util";
import { Row, Col, Image, Divider, Typography, message } from "antd";
import { useSelector } from "react-redux";
import UserAction from "../../actions";
import walletpage_img from "../../assets/images/walletpage_img .svg";
import wallet_coin from "../../assets/images/wallet_coin.svg";
import wallet_blue from "../../assets/images/wallet_blue.svg";
import send from "../../assets/images/send.svg";
import crown from "../../assets/images/crown.svg";
import dotted_line from "../../assets/images/dotted_line.svg";
import clipboard from "../../assets/images/clipboard.svg";
import typeGreen from "../../assets/images/type-green.svg";
import typeOrange from "../../assets/images/type-orange.svg";
import redeemed from "../../assets/images/redeemed.svg";
import promoIcon from "../../assets/images/promo-code.svg";
import tick from "../../assets/images/tick.svg";
import WalletDropdown from "../../components/WalletDropdown/WalletDropDown";
import getEnvVars from "../../environment";
import { useHistory } from "react-router-dom";
import HelmetFyndr from "../../components/HelmetFyndr";
import moment from "moment";

const { Text } = Typography;
const { web } = getEnvVars();
export default function WalletSection() {
  const {
    indv: { userTimeZone },
  } = useSelector(({ auth }) => auth);
  const history = useHistory();

  const {
    indv,
    indv: {
      qrcode,
      indvid,
      onBoarded,
      payoutsEnabled,
      chargesEnabled,
      merchantId,
      linkrefferalcode,
    },
    referral: { business_referral_amount, customer_referral },
  } = useSelector(({ auth }) => auth);

  const [isCopied, setIsCopied] = useState(false);
  const [textToCopy] = useState(indv?.referralCode);
  console.log("indv?.referralCode", indv?.referralCode);
  const [EnterpriseChallenge] = useState([
    {
      image: send,
      text: "Refer a friend or business with your unique referral link.",
      dotted_line: dotted_line,
    },
    {
      image: crown,
      text: "The friend/Business signs up",
      dotted_line: dotted_line,
    },
    {
      image: wallet_blue,
      dotted_line: dotted_line,
      text: `You both receive $5 worth of Fyndr cash in your wallets instantly when an individual is referred.`,
    },
    {
      image: wallet_blue,
      text: `If a business is referred, you both receive $20 worth of Fyndr cash once the referred business completes their Stripe Integration.`,
    },
  ]);
  const [walletData, setWalletData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${month}-${day}-${year}`;
  };

  const info = walletData.walletTransactionsList
    ? walletData.walletTransactionsList.map((item) => {      
        let customItem = {
          // date: moment(item.transactionDt).format("MM-DD-YYYY"),
          date: moment.tz(item.transactionDt, userTimeZone).utc().format("MM-DD-YYYY"),
          time: new Date(item.transactionDt).toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
          }),
          color: "",
          money:
            item.amount >= 0
              ? `+${walletData.currencySymbol}${item.amount.toFixed(2)}`
              : `-${walletData.currencySymbol}${Math.abs(item.amount).toFixed(2)}`,
          reason: item.reason,
          invoiceId: item.invoiceId,
        };
        switch (item.reason) {
          case "REDEEMED":
            customItem.icon = redeemed;
            customItem.text1 = "Fyndr cash redeemed";
            customItem.value1 = item.businessName;
            customItem.value2 = `ID:${item.invoiceId}`;
            customItem.color = "#FF7878";
            customItem.buyerId = item.buyerId;
            customItem.className = "walletRow";
            break;
          case "CASHBACK":
            customItem.icon = promoIcon;
            customItem.text1 = "Cashback reward received";
            customItem.value1 = item.businessName;
            customItem.value2 = `ID:${item.invoiceId}`;
            customItem.className = "walletRow2";
            customItem.color = "green";
            break;
          case "REFERRAL":
            customItem.icon = typeGreen;
            customItem.text1 = "Referral reward received by using code";
            customItem.text2 = item.referralCode;
            customItem.className = "walletRow2";
            customItem.color = "green";
            break;
          case "PROMOCODE":
            customItem.icon = typeOrange;
            customItem.text1 = "Referral reward received by using code";
            customItem.text2 = item.referralCode;
            customItem.className = "walletRow2";
            customItem.color = "green";
            break;
          case "REGISTRATION_PROMOCODE":
            customItem.icon = promoIcon;
            customItem.text1 = "Promocode reward received by using code";
            customItem.text2 = item.promoCode;
            customItem.className = "walletRow2";
            customItem.color = "green";
            break;
          default:
            break;
        }
        return customItem;
      })
    : [];

  const handleItemClick = (item) => {
    history.push({
      pathname: "/billing/transactions",
      state: {
        invoiceId: item.invoiceId,
        buyerId: item.buyerId,
      },
    });
  };

  useEffect(() => {
    const isLogin = indv?.indvid ? true : false;
    if (!isLogin) {
      history.replace("/");
    }
  });

  useEffect(() => {
    walletTransaction();
  }, []);
  const walletTransaction = async (payload) => {
    const data = {
      userId: indv.indvid,
      pgStart: 1,
      pgSize: 10,
    };
    let response = await UserAction.walletTransaction(data);
    setPagination({
      ...pagination,
      total: response?.count,
    });
    if (response.success === true) {
      setWalletData(response);
    } else {
      message.error(response.message);
      console.log("error");
    }
  };
  const copyTextToClipboard = () => {
    const referralCode = indv?.referralCode;
    const urlWithReferralCode = `${web}/register?referralCode=${referralCode}`;
    navigator.clipboard
      .writeText(urlWithReferralCode)
      .then(() => {
        console.log("Text copied to clipboard:", urlWithReferralCode);
      })
      .catch((error) => {
        console.error("Error copying text:", error);
      });
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  return (
    <>
      <div className="container-xl">
        {
          <HelmetFyndr
            title="Wallet"
            // imgUrl={imgLink}
            url={`${web}/wallet`}
            descr="wallet"
          />
        }
        <article className="article">
          <Row justify={"center"}>
            <Col
              className="wallet_content"
              style={{ marginTop: isMobile() ? "1.25rem" : "1.5rem" }}
            >
              <Row className="wallet_heading">Fyndr Wallet</Row>
              <Divider className="wallet_divider" />
              <Row style={{ width: "100%", padding: "0rem 1.5rem" }}>
                <Col xl={16} md={16}>
                  <Row
                    className="wallet_row"
                    style={{ marginRight: isMobile() ? "0rem" : "1.5rem" }}
                  >
                    <Col
                      xl={13}
                      xs={24}
                      md={13}
                      sm={24}
                      style={{ color: "#fff" }}
                    >
                      <Row>
                        <Col xs={6} xl={4} sm={2} md={6}>
                          <Image src={wallet_coin} preview={false} />
                        </Col>
                        <Col
                          xl={14}
                          xs={16}
                          sm={18}
                          md={14}
                          className="wallet_text"
                        >
                          Total Wallet Balance
                        </Col>
                      </Row>
                      <Row className="wallet_amount">
                        {walletData?.currencySymbol}
                        {walletData?.balance?.toFixed(2)}
                      </Row>

                      {isCopied ? (
                        <Row className="wallet_copy-success">
                          <Image src={tick} preview={false} />
                          Copied Succesfully!
                        </Row>
                      ) : (
                        <Row style={{ width: "100%" }}>
                          <Col className="wallet_copy">
                            Your referral code : {textToCopy}{" "}
                            <span>
                              <Image
                                src={clipboard}
                                preview={false}
                                onClick={() => copyTextToClipboard()}
                                style={{ marginLeft: "8px", cursor: "pointer" }}
                              ></Image>
                            </span>
                          </Col>
                        </Row>
                      )}
                      {/* <Row className="wallet_refer">
                        {`Earn up to $${
                          indv?.biz
                            ? business_referral_amount?.value[0]?.countryValue
                            : customer_referral?.value[0]?.countryValue
                        }* Fyndr Cash per Referral`}
                      </Row> */}
                    </Col>
                    <Col
                      align={isMobile() ? "middle" : "end"}
                      xs={24}
                      sm={17}
                      md={11}
                      xl={11}
                    >
                      <Image src={walletpage_img} preview={false} />
                      <div>
                        <span className="wallet_refer">
                          {" "}
                          *Terms and Conditions Apply
                        </span>
                      </div>
                    </Col>
                    <Row style={{ paddingTop: "24px" }}>
                      <Col>
                        <Text className="wallet_refer">
                          Refer an individual and receive $5, or refer a
                          business and receive $20 once they complete their
                          Stripe integration!
                        </Text>
                      </Col>
                    </Row>
                  </Row>
                </Col>
                <Col xl={8} xs={24} md={8} style={{ marginBottom: "1.5rem" }}>
                  <Text className="wallet_workhow">How does it work?</Text>
                  <Row style={{ width: "100%", marginTop: "0.75rem" }}>
                    <Col xl={24} xs={24}>
                      {EnterpriseChallenge.map((e, i) => {
                        return (
                          <Row className="wallet_workunder">
                            <Col xs={3} sm={2} md={2} lg={4} xl={4} xxl={2}>
                              <Image preview={false} src={e.image} />
                            </Col>
                            <Col
                              xs={21}
                              sm={22}
                              md={22}
                              lg={20}
                              xl={20}
                              xxl={21}
                            >
                              {e.text}
                            </Col>
                            {i !== EnterpriseChallenge.length - 1 && (
                              <Col
                                className="mainEnterpriseChallengeDottedLine"
                                // align={"middle"}
                                xs={2}
                                sm={1}
                                md={1}
                                lg={2}
                                xl={3}
                                xxl={2}
                              >
                                <Image preview={false} src={e.dotted_line} />
                              </Col>
                            )}
                          </Row>
                        );
                      })}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="wallet_activity">
                <Col>Activity</Col>
              </Row>
              <Row
                justify={"center"}
                style={{ padding: "0rem 1.5rem 1.5rem 1.5rem" }}
              >
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  {info.length === 0 ? (
                    <div>
                      <Divider />
                      <Row justify={"center"}>
                        <h5>No activity available.</h5>
                      </Row>
                    </div>
                  ) : (
                    info.map((item, index) => (
                      <div key={index}>
                        <div
                          onClick={() => {
                            if (item.reason === "REDEEMED") {
                              handleItemClick(item);
                            }
                          }}
                        >
                          <WalletDropdown
                            key={index}
                            icon={item.icon}
                            time={item.time}
                            date={item.date}
                            text1={item.text1}
                            text2={item.text2}
                            value1={item.value1}
                            value2={item.value2}
                            color={item.color}
                            money={item.money}
                            // style={{ paddingRight: "0.8vw" }}
                            classNameWalletRow={item.className}
                            classNameWalletDate="walletDate"
                            classNameWalletText="walletText"
                            classNameWalletMoney="walletMoney"
                          />
                        </div>
                      </div>
                    ))
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </article>
      </div>
    </>
  );
}
