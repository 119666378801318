/* eslint-disable import/no-anonymous-default-export */
import { Actions } from "../common";
import { filterEvents, makeWebstr } from "../common/util";

const {
  GET_EVENTS_BIZ,
  LOG_OUT,
  GET_EVENTS,
  FILTER_EVENTS,
  TERMS_INIT,
  SET_TERMS_LIST,
  TERM_EDITED,
  SET_PENDING_NOTIS,
} = Actions;

const INITIAL_STATE = {
  checkin: { interactionId: null, scannedFor: null },
  terms_list: [],
  termsModalVisible: false,
  web_list: "",
  events: [],
  filtered_events: [],
  searchTxt: "",
  days: "",
  indirect: { visitors: null, interacts: null, count: null, days: null },
  bizEvents: { bizVisits: {}, termsAccepted: {}, termsDenied: {} },
  pendingNotifications: 0,
  prices: { term: [], interaction: [], indv: [] },
  cmpnSearchParams: { bizName: null, lat: null, lng: null, postalCode: null },
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_EVENTS_BIZ: {
      const { bizVisits, termsAccepted, termsDenied } = action.payload;
      return {
        ...state,
        bizEvents: {
          bizVisits: bizVisits[0],
          termsAccepted: termsAccepted[0],
          termsDenied: termsDenied[0],
        },
      };
    }

    case TERMS_INIT:
      return { ...state, terms_list: action.payload };
    case SET_TERMS_LIST:
      return {
        ...state,
        terms_list: action.payload,
        web_list: makeWebstr(action.payload),
      };
    case TERM_EDITED:
      const arr = state.terms_list.filter(
        (row) => row.id !== action.payload.id
      );
      const new_list = [...arr, action.payload];
      return { ...state, terms_list: new_list, web_list: makeWebstr(new_list) };

    case GET_EVENTS:
      const { direct, indirect } = action.payload;
      return {
        ...state,
        events: direct,
        filtered_events: filterEvents(direct, state.days, state.searchTxt),
        indirect,
      };
    case FILTER_EVENTS:
      const { events, searchTxt, days } = action.payload;
      return {
        ...state,
        filtered_events: filterEvents(events, days, searchTxt),
        searchTxt,
        days,
      };
    case SET_PENDING_NOTIS:
      return { ...state, pendingNotifications: action.payload };
    case LOG_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}
