import React from 'react'
import { Button, Row, Col, Popover, Space } from "antd";
import { Theme } from '../common';
export default function Buttons(props) {
    const { popvisible } = props
    return (
        <Row
            style={{ marginTop: "1rem" }}
            gutter={[16]}
            justify="space-between"
        >
            <Col span={12}>
                <Button
                    type={props.btntype}
                    onClick={props.onClick1}
                    className={props.classname}
                    style={props.style}
                    size={props.size}
                >
                    {props.btn1}
                </Button>
            </Col>
            {popvisible ?
                <Col span={12}>
                    <Space><h6 style={{ fontWeight: "300" }}>In case of any issues  <br />regarding disputes please contact <br /> <a
                        style={{ color: Theme.blue }}
                        onClick={() => window.location = 'mailto:dispute@fyndr.com'}>
                        dispute@fyndr.com
                    </a></h6>
                        <Popover content="Dispute can only be raised within 30 days of purchase">
                            <i
                                style={{ color: Theme.blue }}
                                className="fa fa-info-circle"
                                aria-hidden="true"
                            />
                        </Popover>
                    </Space>
                </Col>
                :
                <Col span={12}>
                    <Button
                        type={props.type}
                        onClick={props.onClick2}
                        className={props.classname1}
                        style={props.disputeStyle}

                    >
                        {props.btn2}
                    </Button>
                </Col>
}

        </Row>
    )
}
