/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */

import {
  fetch_retry_get,
  fetch_retry_post,
  fetch_retry_put,
  fetch_retry_delete,
  getToken,
  xmlGETRequest,
} from "../network/ApiManager";
import { parseTerms } from "../common/util";
import { Actions } from "../common";
import getEnvVars from "../environment";
import { debug } from "./index";

const {
  GET_EVENTS_BIZ,
  UPDATE_QR_LOGO,
  UPDATE_MAIN_LOGO,
  TERMS_FOUND,
  CARDS_LISTED,
  SET_PENDING_NOTIS,
} = Actions;

const { base, gatewayNew, payment } = getEnvVars();

const getBizMessage = (list, val) => {
  let rec = list.filter((row) => row.value === val)[0];
  return rec.bizValue;
};

export const getBizInteractions = (data) => async (dispatch) => {
  const endpoint = `${gatewayNew}/interactions/biz/activity`;
  debug && console.log(endpoint, data);
  const response = await fetch_retry_post(endpoint, data);
  try {
    if (response.ok) {
      const resp = await response.json();
      await dispatch({ type: GET_EVENTS_BIZ, payload: resp });
      return { success: true };
    }
  } catch ({ message }) {
    console.error(message);
  }
  return { success: false };
};

export const uploadLogo = (data, isQRLogo) => async (dispatch) => {
  const endpoint = `${gatewayNew}/upload-logo`;
  try {
    const response = await fetch_retry_put(endpoint, data);
    if (response.ok) {
      var resp = await response.json();

      dispatch({
        type: isQRLogo === "qr" ? UPDATE_QR_LOGO : UPDATE_MAIN_LOGO,
        payload: resp.url,
      });
      return { success: true, message: resp.message };
    } else {
      return {
        success: false,
        message: resp.message ? resp.message : "Error uploading logo",
      };
    }
  } catch ({ message }) {
    console.error(message);
  }
  return { success: false };
};

export const sendMsg = (data) => async (dispatch) => {
  const footer =
    "Please note that Fyndr does not collect or validate medical records/claims from its users. These messages are solely intended for cautionary purposes in order to minimize the spread of Covid-19.";
  const { when, msg, rcpts, sender, messages } = data;
  let enhancedBizMsg = `${getBizMessage(
    messages,
    msg
  )} The approximate date of exposure is ${when}`;
  let enhancedIndMsg = `${msg} The approximate date of exposure is ${when}.\n${footer}`;

  let bizArr = [];
  if (rcpts.biz) {
    rcpts.biz.forEach((line) => {
      let finalMsg = `${enhancedBizMsg} Impacted places: ${line.locs.join(
        ", "
      )}.\n${footer}`;
      bizArr.push({ qrId: line.qrId, msg: finalMsg });
    });
  }

  const payload = {
    sender,
    biz: bizArr,
    indv: {
      rcpts: rcpts.indv,
      msg: enhancedIndMsg,
    },
  };

  const endpoint = `${gatewayNew}/interactions/notify`;
  try {
    const response = await fetch_retry_post(endpoint, payload);
    if (response.ok) {
      return { success: true, msg: "Messages sent successfully" };
    } else {
      return { success: false, msg: "Error sending message" };
    }
  } catch ({ message }) {
    console.error(message);
  }
  return { success: false, msg: "Error sending message" };
};
export const createTerms = ({ data, currentActiveId }) => async (dispatch) => {
  const endpoint = `${base}/identity/term`;
  try {
    const response = await fetch_retry_post(endpoint, data);
    const resp = await response.json();
    if (response.ok) {
      await dispatch({ type: TERMS_FOUND, payload: parseTerms(resp) });
      const activated =
        resp.length > 0 &&
        resp.filter((row) => {
          row.status === "active" && row.objid > currentActiveId;
        }) >= 0;
      return {
        success: true,
        msg: activated
          ? "New terms activated"
          : "Terms will be activated after review",
      };
    } else {
      return {
        success: false,
        msg: resp.message ? resp.message : "Error saving terms",
      };
    }
  } catch ({ message }) {
    return { success: false, message };
  }
};
export const updateTerms = (data) => async (dispatch) => {
  const endpoint = `${base}/identity/term`;
  try {
    const response = await fetch_retry_post(endpoint, data);
    const resp = await response.json();

    if (response.ok) {
      await dispatch({ type: TERMS_FOUND, payload: parseTerms(resp) });
      return { success: true, msg: "preference Updated successfully " };
    } else {
      return {
        success: false,
        msg: resp.message ? resp.message : "Error saving terms",
      };
    }
  } catch ({ message }) {
    return { success: false, msg: message };
  }
};
export const uploadLogoTwo = async (data, isQRLOGO) => {
  const endpoint = `${gatewayNew}/upload-logo`;
  let token = await getToken(endpoint);
  try {
    const response = await fetch(endpoint, {
      body: data,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Fyndr-Auth-Token": token,
      },
      method: "PUT",
    });
    let resp = await response.json();
    if (response.ok) {
      return { success: true };
    } else {
      return {
        success: false,
        message: resp.message ? resp.message : "Error uploading logo",
      };
    }
  } catch ({ message }) {
    return { success: false, message };
  }
};
export const searchBizTerms = (data) => async (dispatch) => {
  const endpoint = `${base}/identity/getTermsList`;
  try {
    const response = await fetch_retry_post(endpoint, data);

    const resp = await response.json();

    if (response.ok) {
      await dispatch({ type: TERMS_FOUND, payload: parseTerms(resp) });
      return { success: true, data: resp };
    } else {
      return [];
    }
  } catch ({ message }) {
    console.error(message);
    return { success: false, msg: message };
  }
};
export const DownloadFileTwo = (id) => async (dispatch) => {
  const endpoint = `${base}/identity/termForConstent?objid=${id}`;
  await xmlGETRequest(endpoint);
};
export const DownloadFile = (id) => async (dispatch) => {
  const endpoint = `${base}/identity/termForConstent?objid=${id}`;
  try {
    let response = await fetch_retry_get(endpoint);
    if (response.status === 200) {
      await response.text();
    }
  } catch (error) {
    console.error(error);
  }
};
export const deleteCard = (data) => async (dispatch) => {
  const endpoint = `${payment}/secure/card`;
  debug && console.log("deleteCard", endpoint, data);
  try {
    let response = await fetch_retry_delete(endpoint, data);
    const resp = await response.json();
    if (response.ok) {
      await dispatch({ type: CARDS_LISTED, payload: resp });
      return { success: true };
    } else {
      return { success: false, message: resp.message };
    }
  } catch (error) {
    return { success: false, msg:error?.response?.data?.message };
  }
};

export const makeCardDefault = (data) => async (dispatch) => {
  const endpoint = `${payment}/secure/card`;
  debug && console.log("makeDefault", endpoint, data);
  const response = await fetch_retry_put(endpoint, data);
  try {
    const resp = await response.json();
    if (response.ok) {
      await dispatch({ type: CARDS_LISTED, payload: resp });
      return { success: true };
    } else {
      return { success: false, message: resp.message };
    }
  } catch ({ message }) {
    return { success: false, message };
  }
};

export const updatePayMethods = (params) => async (dispatch) => {
  const { subscriptionId } = params;
  const endpoint = `${payment}/secure/subscription/${subscriptionId}/update_payment_method`;
  delete params.subscriptionId;
  debug && console.log("makeDefault", endpoint, subscriptionId);
  const response = await fetch_retry_put(endpoint, params);
  try {
    const resp = await response.json();
    if (response.ok) {
      return { success: true, data: resp };
    } else {
      return { success: false, msg: resp.message };
    }
  } catch ({ message }) {
    return { success: false, message };
  }
};

export const cancelSubscription = (subscriptionId,payload) => async (dispatch) => {
  const endpoint = `${payment}/secure/subscription/${subscriptionId}`;
  debug && console.log("deleteCard", endpoint);
  const response = await fetch_retry_delete(endpoint,payload);
  try {
    const resp = await response.json();
    if (response.ok) {
      return { success: true, msg: resp.message };
    } else {
      return { success: false, msg: resp.message };
    }
  } catch ({ message }) {
    return { success: false, message };
  }
};

export const updateSubscription = (params) => async (dispatch) => {
  const { subscriptionId } = params;

  const endpoint = `${payment}/secure/subscription/${subscriptionId}`;
  delete params.subscriptionId;
  const response = await fetch_retry_put(endpoint, params);
  try {
    const resp = await response.json();
    if (response.ok) {
      return { success: true, data: resp };
    } else {
      return { success: false, msg: resp.message };
    }
  } catch ({ message }) {
    return { success: false, message };
  }
};

export const updateMethods = (data) => async (dispatch) => {
  const endpoint = `${payment}/secure/update_methods`;
  debug && console.log("updateMethods", endpoint, data);
  const response = await fetch_retry_post(endpoint, data);
  try {
    const resp = await response.json();
    if (response.ok) {
      await dispatch({ type: CARDS_LISTED, payload: resp });
      return { success: true };
    } else {
      return {
        success: false,
        message: resp.message ? resp.message : "Error updating payment methods",
      };
    }
  } catch ({ message }) {
    return { success: false, message };
  }
};
export const processNotifications = (data) => async (dispatch) => {
  const endpoint = `${gatewayNew}/interactions/indv/notification`;
  debug && console.log("getNotifications", endpoint, data);
  const response = await fetch_retry_post(endpoint, data);
  try {
    if (response.ok) {
      const resp = await response.json();
      debug && console.log(resp);
      const pendingCount = resp.filter((row) => row.status === "pending")
        .length;
      dispatch({ type: SET_PENDING_NOTIS, payload: pendingCount });

      return { success: true, data: resp };
    }
  } catch ({ message }) {
    console.log(message);
  }
  return { success: false };
};
export const coreActions = {
  getBizInteractions,
  uploadLogo,
  uploadLogoTwo,
  sendMsg,
  updateTerms,
  createTerms,
  searchBizTerms,
  DownloadFile,
  DownloadFileTwo,
  updateMethods,
  deleteCard,
  makeCardDefault,
  processNotifications,
  cancelSubscription,
  updatePayMethods,
  updateSubscription
};
