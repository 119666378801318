import React, { Suspense } from "react";
import { Provider } from "react-redux";

import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "../store";
import Loader from "./Loading/Loader";

// import * as serviceWorker from "./serviceWorker";

import AppLayout from "./AppLayout/";
import "../main.scss";
import RootMain from "./RootMain";
// import { useSelector, useDispatch } from "react-redux";


const Root = () => {
  // const { loader } = useSelector(({ auth }) => auth);

  return (

    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense maxDuration={500} fallback={<Loader />}>
         <RootMain/>
        </Suspense>
      </PersistGate>
    </Provider>
  );
};

export default Root;
