import React, { useRef, useState } from "react";
import { Row, Form, Popover, Col, Button, message, Radio, Space } from "antd";
import {
  Container as ContainerWrapper,
  CatalogueForm as FormCatalogue,
} from "../../components";
import { useForm } from "react-hook-form";
import { FormSchema } from "../../common";
import { useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers";
import { Theme } from "../../common";
import UserAction from "../../actions";
import LoadingOverlay from "react-loading-overlay";
import Loader from "../../components/Loading/Loader";

const EditModifer = (props) => {
  const { isEdit, callback, item } = props;

  const FormItem = Form.Item;
  const nameRef = useRef(null);
  const descRef = useRef(null);
  const { CatalogueForm } = FormSchema;

  const {
    indv: { bizid },
  } = useSelector(({ auth }) => auth);
  const [isLoading, setIsLoading] = useState(false);

  const [payload, setPayload] = useState({
    modName: item?.modName || "",
    description: item?.description || null,
    images: item?.images || [],
    modType: item?.modType || "whole",
  });
  const [descError] = useState({
    status: false,
    message: null,
  });
  const layout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const { handleSubmit, errors, control } = useForm({
    mode: "onBlur",
    shouldUnregister: false,
    resolver: yupResolver(CatalogueForm),
  });
  const restFormValidation = async (data) => {
    let dispatchPayload = {
      images: payload.images,
      description: payload.description,
      modName: data.name,
      modType: payload.modType,
      bizid,
    };
    //API call for the Add category
    let ImageArray = dispatchPayload.images;

    ImageArray.map((item, index) => {
      let keys = Object.keys(item);
      if (keys.includes("img_url")) {
        ImageArray.splice(index, 1);
      }
      return null;
    });

    if (ImageArray.length < 1) {
      delete dispatchPayload.images;
    }
    if (ImageArray[0]?.img === null) {
      dispatchPayload.images = [];
    }
    if (item?.objid) {
      dispatchPayload.objid = item.objid;
    }
    setIsLoading(true);
    let resp = await UserAction.addModifier([dispatchPayload]);
    resp.success ? message.success(resp.message) : message.error(resp.message);

    // dispatch({ type: UPDATE_MODIFIER, payload: dispatchPayload });
    callback(false);
  };
  const handleAddCategory = () => {
    handleSubmit(
      (data) => {
        restFormValidation(data);
      },
      (err) => console.log("error", err)
    )();
  };
  const CONTENT = (
    <div>
      <ol>
        <li>
          <p>
            <span style={{ fontWeight: "bold" }}>Addon: </span>Multiple
            modifiers of type "addon" can be added while ordering the item.
          </p>
        </li>
        <li>
          <p>
            <span style={{ fontWeight: "bold" }}>Whole: </span>Only one modifier
            of type "whole" can be selected while making the order.
          </p>
        </li>
      </ol>
    </div>
  );
  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      text=""
      styles={{
        overlay: (base) => ({
          ...base,
          background: Theme.overlay,
        }),
      }}
    >
      <ContainerWrapper title={isEdit ? `Edit : ${item?.name}`: "Add Modifier"}>
        <Row justify="start">
          <Form style={{ minWidth: "100%", padding: "2rem" }} {...layout}>
            <Row style={{ minWidth: "100%" }}>
              <Col span={24}>
                <FormItem label="Type" {...formItemLayout}>
                  <Space>
                    <Radio.Group
                      onChange={({ target: { value } }) => {
                        setPayload({ ...payload, modType: value });
                      }}
                      value={payload.modType}
                    >
                      <Radio value={"whole"}>whole</Radio>
                      <Radio value={"addon"}>add on</Radio>
                    </Radio.Group>
                    <Popover content={CONTENT} title="Modifier Type">
                      <i
                        style={{ color: Theme.blue }}
                        className="fa fa-info-circle"
                        aria-hidden="true"
                      />
                    </Popover>
                  </Space>
                </FormItem>
              </Col>
            </Row>
            <FormCatalogue
              formHook={{ errors, handleSubmit, control }}
              nameRef={nameRef}
              descRef={descRef}
              getMarkdownValue={(value) => {
                setPayload({ ...payload, description: value });
              }}
              desc={payload.description}
              name={payload.modName}
              imgs={payload.images}
              descError={descError}
              imageCallback={(value) => {
                let imgs = payload.images.filter(
                  (row) => row.index !== value.index
                );
                imgs.push(value);
                imgs.sort((a, b) => a.index > b.index);
                setPayload({ ...payload, images: imgs });
              }}
              isMulti={false}
              formtype="modifier"
            />
          </Form>
        </Row>
        <Row justify="end" style={{ width: "100%" }}>
          <Space>
            <Button
              disabled={isLoading}
              type="warning"
              onClick={() => callback(false)}
            >
              Cancel
            </Button>
            <Button
              disabled={isLoading}
              type="primary"
              onClick={handleAddCategory}
            >
              {isEdit ? "Update" : "Save"}
              {isLoading && <Loader />}
            </Button>
          </Space>
        </Row>
      </ContainerWrapper>
    </LoadingOverlay>
  );
};

export default EditModifer;
