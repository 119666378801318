import React, { useState, useEffect } from "react";
import { Upload, Button, Image } from "antd";
import PromoCode2 from "../../../assets/images/PromoCode2.png";

const ImageUploader = ({ onFileSelect, imgUrl }) => {
  const [imageUrl, setImageUrl] = useState(PromoCode2);
  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    if (imgUrl) {
      setImageUrl(imgUrl);
    }
  }, [imgUrl]);

  const convertToBase64 = (file, callback) => {
    const reader = new FileReader();
    reader.onload = (e) => callback(e.target.result);
    reader.readAsDataURL(file);
  };

  const handleChange = (info) => {
    if (info.file.status === "done") {
      convertToBase64(info.file.originFileObj, (base64Image) => {
        setImageUrl(base64Image);
        setImageFile(info.file.originFileObj);
        onFileSelect(base64Image);
      });
    }
  };

  const beforeUpload = (file) => {
    convertToBase64(file, (base64Image) => {
      setImageUrl(base64Image);
      setImageFile(file);
      onFileSelect(base64Image);
    });
    return false;
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Upload
        name="image"
        listType="picture"
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        <div
          style={{
            position: "relative",
            width: "200px",
            height: "200px",
            borderRadius: "10px",
            overflow: "hidden",
          }}
        >
          <Image
            preview={false}
            src={imageUrl}
            alt="Uploaded"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "10px",
            }}
          />
          <div
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              width: "24px",
              height: "24px",
              backgroundColor: "#00000099",
              borderRadius: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <Button
              style={{ background: "none", border: "none", color: "#fff",background: "#aebdc980", borderRadius: "100%"  }}
            >
              +
            </Button>
          </div>
        </div>
      </Upload>
    </div>
  );
};

export default ImageUploader;