const getEnvVars = () => {
  let variables = {};
  if (process.env.REACT_APP_STAGE === "Production") {
    variables = ENV.prod;
  } else {
    variables = ENV.dev;
  }
  variables.app = apps;
  return variables;
};
const apps = {
  facbookAppId: "339365493739503",
  googleClientId:
    // "283240579326-ksjrato0l23hf9ck57mqd85cn038kts9.apps.googleusercontent.com",
    // "1068896131755-vckn3h3sgl50i1or7ohn2m2hpfqco5dg.apps.googleusercontent.com",// testing account
    // "387371231418-58k3j1jj17b06d42ru6mjl5p1d3tfhsc.apps.googleusercontent.com",
    "283240579326-2jp6j8jvekme9o4ddcsesa8rl81evidf.apps.googleusercontent.com",
  calendarApi: "1068896131755-vckn3h3sgl50i1or7ohn2m2hpfqco5dg.apps.googleusercontent.com",
  support: "support@fyndr.us",
  // GOOGLE_API_KEY: "AIzaSyDqsGk4KyezWjF5glzSbSiF58-XsXMISMY",
  GOOGLE_API_KEY: "AIzaSyBsPTDoUoR2ggCehu9GPIlEw6-LhXoQFEg",

};
const ENV = {
  dev: {
    api_token:
      "xS*KkawsJ36ADKpwbba^Z6g!_f2eanPfz@pKT_2C85Z3q8-#$5Kw@y=#cA%AmR+t",
    base: "https://api.dev.fyndr.us:443",
    // base: "http://localhost:8080",
    gateway: "https://api.gw.dev.fyndr.us",
    gatewayNew: "https://api-gateway.dev.fyndr.us",
    // gatewayNew: "https://api.dev.fyndr.us",
    payment: "https://api-gateway.dev.fyndr.us/payment",
    map: "https://maps.googleapis.com/maps/api/geocode/json",
    qrPrefix: "https://www.web.dev.fyndr.us/qr/",
    web:
      process.env.NODE_ENV === "development"
        ? "http://localhost:3000"
        : "https://www.web.dev.fyndr.us",
    cmpn: "https://api.dev.fyndr.us:443",
    promo: "https://api-gateway.dev.fyndr.us:443",
    auth: "https://api-gateway.dev.fyndr.us",
  },
  prod: {
    api_token:
      "yS*SwapJ36ADKpwbba^Z6g!_f2ashieanPfzShu@pdeveKHimT_2C85Z3aliq8-#$5Kwfia@y=#cA%AmR+t",
    base: "https://api.prod.fyndr.us:443",
    gateway: "https://api.gw.prod.fyndr.us",
    gatewayNew: "https://api-gateway.prod.fyndr.us",
    payment: "https://api-gateway.prod.fyndr.us/payment",
    map: "https://maps.googleapis.com/maps/api/geocode/json",
    qrPrefix: "https://fyndr.us/qr/",
    web: "https://fyndr.us",
    cmpn: "https://api.prod.fyndr.us:443",
    promo: "https://api-gateway.prod.fyndr.us:443",
    auth: "https://api-gateway.prod.fyndr.us",
  },
};
export default getEnvVars;
