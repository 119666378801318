import React from "react";
import { Select } from "antd";
const { Option } = Select;

export default function DropdownComponent(props) {
  return (
    <div className={props.className}>
      <Select
        showSearch={props.showSearch}
        suffixIcon={props.suffixIcon}
        style={props.style}
        className={props.newclassnmae}
        defaultValue={props.defaultValue}
        disabled={props.disabled}
        value={props.value}
        mode={props.mode}
        bordered={props.bordered}
        placeholder={props.placeholder}
        onChange={props.onChange}
        getPopupContainer={(trigger) => trigger.parentNode}
        dropdownRender={props.dropdownRender}
        allowClear={props.allowClear}
        onClick={props.onClick}
      >
        {props.options?.map((x, key) => {
          return (
            <Option
              value={x.value}
              key={key}
              disabled={x.disabled || props?.disabledKeys?.includes(x.value)}
            >
              <span
                className={x.color}
                style={{
                  justifyContent: "center",
                  marginRight:
                    props.title === "Update Platform Variable" ||
                    props.mode === "multiple"
                      ? "0rem"
                      : "1rem",
                  marginBottom: ".2rem",
                }}
              ></span>
              <text style={props.textStyle}>{x.label}</text>
            </Option>
          );
        })}
      </Select>
    </div>
  );
}
