export const LocationType = {
  Retail: "RE",
  Office: "OF",
  Warehouse: "WH",
  Gym: "GY",
  Classroom: "CL",
  "Factory (Manufacturing)": "FA",
  "Clinic (Hospital)": "CL",
  Restaurant: "RT",
  "Personal Care": "PC",
  "Personal Grooming": "PG",
  "Sports Activity": "SA",
  Library: "LI",
  Others: "OT",
};

export const DeliveryTypes = [
  { key: "instore", val: "In Store" },
  { key: "takeout", val: "Take Out" },
  { key: "curbside", val: "Curbside" },
  // { key: "homedel", val: "Home Delivery" },
  // { key: "homeservice", val: "Home Service" },
];

export const WholeUnits = ["each", "set", "box", "pair"];

export const days = {
  Mo: "Monday",
  Tu: "Tuesday",
  We: "Wednesday",
  Th: "Thursday",
  Fr: "Friday",
  Sa: "Saturday",
  Su: "Sunday",
};
