import React from 'react'
import { Pie } from 'react-chartjs-2';
import "chart.js/auto";

export default function PieChart(props) {

    return (
        <div style={{height:"210px", width:"200px"}}>
        <Pie
            data={props.data}
            height={props.height}
            width={props.width}
            updateMode="none"
            options={props.options}
        />
        </div>
    )
}
