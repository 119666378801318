/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Actions } from "../../common";
import UserActions from "../../actions";
import { RichTextEditor } from "../../components";
import ModalRaiseADispute from "../../components/ModalRaiseADispute/ModalRaiseADispute";
import {
  Row,
  Col,
  Divider,
  Typography,
  Button,
  Card,
  message,
  Input,
} from "antd";
import ImageViewer from "react-simple-image-viewer";
import { BizCard } from "../../components";
import {
  getFormatedDateTime,
  getFormattedDt,
  capitalize,
  getTotal,
  parseAddress,
  getDisplayStatus,
  getFormattedDateTimeNew,
} from "../../common/util";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Loader from "../../components/Loading/Loader";
import { DownloadOutlined } from "@ant-design/icons";
import Axios from "axios";
import { ConfirmationModal } from "../../components";
import Buttons from "../../components/Buttons";
const { CART_LOADED } = Actions;
const { Text } = Typography;

const InvoiceView = (props) => {
  const { inv, type, desc } = props;
  const dispatch = useDispatch();
  const [vouchers, setVouchers] = useState(null);
  const [, setScreen] = useState(null);
  const [biz, setBiz] = useState(null);
  const [last4, setLast4] = useState();
  const [brand, setBrand] = useState();
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [raiseDispute, setRaiseDispute] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [, setSelectedVoucher] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [invoiceId, setInvoiceId] = useState()
  const [purchaseLoc,setPurchaseLoc]=useState({});

  let history = useHistory();
  const { voucherUpdated } = useSelector(({ fin }) => fin);
  const {
    indv: { indvid, bizid, userTimeZone },
  } = useSelector(({ auth }) => auth);


  const {
    objid,
    baseAmount,
    taxAmount,
    tipAmount,
    invoiceDt,
    currencySymbol,
    status,
    invoiceDetails: {
      title,
      offers,
      cmpn_title,
      cust_message,
      server_name,
      invoice_nbr,
      img_url,
      deliveryTime,
      deliveryType,
      locName,
    },
    buyerFname,
    buyerLname,
    channel,
  } = inv;

  const totalAmount = getTotal(inv);

  const fetchInvoiceDetails = async () => {
    let payload = {
      invoiceId: objid,
    };

    if (type === "receivable") payload.bizid = bizid;
    else payload.buyerId = indvid;

    setIsLoading(true);
    const { success, resp } = await UserActions.getInvoiceDetails(payload);
    setIsLoading(false);
    if (success) {
      setBiz(resp.biz);
      setVouchers(resp.offers);
      setInvoiceId(resp.invoiceId);
      setPurchaseLoc(resp?.businessLocationAddress);


      if (resp.payments) {
        setBrand(resp?.payments?.cardBrand);
        setLast4(resp?.payments?.cardLast4);
      }
    }
  };
  const getchannelBought = (channel) => {
    switch (channel) {
      case 'offers':
      case 'offer_appointment':
        return 'Offers';
      case 'events':
        return 'Events';
      case 'interaction':
        return 'Interactions';
      case 'catalog':
      case 'catalog_appointment':
        return 'Catalogs';
      case 'cmpn_promo':
        return 'Campaign';
      default:
        return 'Unknown';
    }
  };
  const cancelInvoice = async () => {
    inv.status = "canceled";
    inv.bizid = bizid;

    setIsLoading(true);
    const resp = await UserActions.saveInvoice(inv, "rcpt");
    setIsLoading(false);

    if (resp.success) {
      message.success("Invoice Canceled");
      setConfirmModal(false);
      //  callback();
    } else {
      message.error(resp.msg);
    }
  };
  useEffect(() => {
    (async () => {
      await fetchInvoiceDetails();
    })();
  }, [voucherUpdated, inv]);

  const download = async (e, uri, title) => {
    e.stopPropagation();
    let fileDownload = require("js-file-download");
    const res = await Axios.get(uri, {
      responseType: "blob",
    });
    fileDownload(res.data, `${title}.pdf`);
  };
  const loadCart = async (mode) => {
    const payload = {};
    inv.invoiceDetails.items.forEach((row) => {
      payload[row.catalogue_item_id] = row.details;
    });
    const details = inv.invoiceDetails;

    const catalogueLocation = {
      locId: details.locId,
      locName: details.locName,
      parentLocation: details.parentLocation,
      biz: {
        bizid: details.parentLocation.bizid,
        bizName: details.business_name,
        country: details.business_country,
        merchantId: inv.merchantId,
      },
    };

    const catalogue = {
      currency: inv.currency,
      currencySymbol: inv.currencySymbol,
      name: details.catalogueName,
      objid: details.catalogueId,
    };

    dispatch({
      type: CART_LOADED,
      payload: { cart: payload, catalogueLocation, catalogue, inv },
    });
    if (mode === "edit") {
      //    setShowCart(true);
    } else {
      setIsLoading(true);
      const resp = await UserActions.getCatalogueByLoc({
        locId: details.locId,
      });

      setIsLoading(false);
      if (!resp.success) {
        message.error("Error retrieving catalogue");
      } else {
        //  onCart(details.locId);

        history.push(`/catalogue/store/${resp?.payload?.catalogue?.url}`, {
          catalogueDetail: resp.payload,
          fromBizAccount: true,
        });
      }
    }
  };


  function sumQuantities(items) {
    let sum = 0;
    items?.forEach((item) => {
      sum += item?.details?.qty || item?.qty;
    });

    return sum;
  }
  const getChannelName = (channel) => {
    switch (channel) {
      case 'offers':
      case 'offer_appointment':
        return 'Offer';
      case 'events':
        return 'Event';
      case 'interaction':
        return 'Interaction';
      case 'catalog':
      case 'catalog_appointment':
        return 'Catalog';
      case 'cmpn_promo':
      case 'promo':
        return 'Campaign';
      default:
        return 'Unknown';
    }
  };

  const renderListItems = () => {
    return (
      <Row>
        <Row style={{ marginBottom: "8px" }}>
          <Col span={24}>
            <Text className="invoiced_to">Item Details:</Text>
          </Col>
        </Row>
        <Col style={{ width: "100%" }} span={24}>
          {inv?.invoiceDetails?.items.map((item, key) => {
            return (
              <div key={key}>
                <Row className="bizCard" style={{ width: "100%", marginBottom: "8px" }}>
                  <Col style={{ width: "100%" }}>
                    <Row
                      justify={"space-between"}
                    >
                      <Col span={8}>
                        <Text className="invoice_text">
                          {item.details?.mitem.name}
                          {item.details?.wholeDetails.modName
                            ? `(${item.details?.wholeDetails.modName})`
                            : ""}{`${" "}(X ${Number(item.details?.qty).toFixed(2)})`}
                        </Text>
                      </Col>
                      <Col>
                        <Text className="invoice_value">{`${item.details?.currencySymbol}${Number(
                          item.details?.total
                        ).toFixed(2)}`}</Text>
                      </Col>
                    </Row>


                    <div
                      style={{
                        marginTop: ".5rem",
                      }}
                    >
                      {item.details?.addon.length > 0 &&
                        item.details?.addonDetails.map((addOnItem) => {
                          return (
                            <Row className="addonDisplay" justify="space-between">
                              <Col span={8}>
                                <Text className="invoice_text">{addOnItem.modName}</Text>
                              </Col>
                              <Col span={6}>
                                <Text className="invoice_value">
                                  +{currencySymbol}
                                  {addOnItem.price.toFixed(2)}
                                </Text>
                              </Col>
                              <Col span={10}></Col>
                            </Row>
                          );
                        })}
                    </div>
                  </Col>
                </Row>
              </div>
            );
          })}
        </Col>

      </Row>
    );
  };
  return (
    <>
      {biz !== null && (
        <div>
          <BizCard
            businessLogo={biz.mainLogo}
            businessName={biz.bizName}
            businessWebsite={biz.website}
            businessAddonUrl={biz.addonUrl}
            businessAddress={parseAddress(biz)}
            businessPhone={biz.phone}
            invoiceID={invoiceId}
          />
        </div>
      )}
      <div>
        <div className="bizCard" style={{ marginBottom: "1rem" }}>
          <Row style={{ width: "100%" }}>
            <Col style={{ width: "100%" }}>
              <Row justify="space-between" style={{ marginBottom: "12px" }}>
                <Col span={8}>
                  <Text className="invoiced_to">Invoiced to:</Text>
                </Col>
                <Col>
                  <Text className="invoiced_to">
                    {buyerFname} {buyerLname}
                  </Text>
                </Col>
              </Row>
              <Row justify="space-between" style={{ marginBottom: "12px" }}>
                <Col span={8}>
                  <Text className="invoice_text">{getChannelName(channel)} Name:</Text>
                </Col>
                <Col>
                  <Text className="invoice_value">
                    {title ? title : cmpn_title}
                  </Text>
                </Col>
              </Row>
              <Row justify="space-between" style={{ marginBottom: "12px" }}>
                <Col span={8}>
                  <Text className="invoice_text">Location:</Text>
                </Col>
                <Col>
                  <Text className="invoice_value">
                    {purchaseLoc}
                  </Text>
                </Col>
              </Row>
              <Row justify={"space-between"} style={{ marginBottom: "12px" }}>
                <Col span={8}>
                  <Text className="invoice_text">Payment Status:</Text>
                </Col>
                <Col>
                  <Text className="invoice_value">{capitalize(status)}</Text>
                </Col>
              </Row>
              {brand && (
                <Row>
                  <Col span={8} style={{ marginBottom: "12px" }}>
                    <Text className="invoice_text">Payment Method:</Text>
                  </Col>
                  <Text className="invoice_value">
                    {brand} - {last4}
                  </Text>
                </Row>
              )}
              {channel === "catalog" && (
                <Row justify={"space-between"} style={{ marginBottom: "12px" }}>
                  <Col span={8}>
                    <Text className="invoice_text">Delivery Time:</Text>
                  </Col>
                  <Col>
                    <Text className="invoice_value">
                      {capitalize(deliveryTime)}
                    </Text>
                  </Col>
                </Row>
              )}
              <Row justify="space-between" style={{ marginBottom: "12px" }}>
                <Col span={8}>
                  <Text className="invoice_text">Time:</Text>
                </Col>
                <Col>
                  <Text className="invoice_value">{moment.tz(invoiceDt, userTimeZone).format("DD MMM, YYYY, hh:mm A")}</Text>
                </Col>
              </Row>
              {channel === "interaction" && (
                <div>
                  <Row style={{ marginBottom: "12px" }}>
                    <Col span={8}>
                      <Text className="invoice_text">Invoice #:</Text>
                    </Col>
                    <Col>
                      <Text className="invoice_value">{invoice_nbr}</Text>
                    </Col>
                  </Row>
                  {cust_message !== null && (
                    <Row style={{ marginBottom: "12px" }}>
                      <Col span={8}>
                        <Text className="invoice_text">Message:</Text>
                      </Col>
                      <Col>
                        <Text className="invoice_value">{cust_message}</Text>
                      </Col>
                    </Row>
                  )}
                  <Row style={{ marginBottom: "12px" }}>
                    <Col span={8}>
                      <Text className="invoice_text">Associate Name:</Text>
                    </Col>
                    <Col>
                      <Text className="invoice_value">{server_name}</Text>
                    </Col>
                  </Row>
                </div>
              )}
            </Col>
            <Col>
              {img_url !== null &&
                img_url !== undefined &&
                img_url.indexOf(".pdf") === -1 && (
                  <div>
                    <img
                      alt="invoice"
                      onClick={() => setIsViewerOpen(true)}
                      className="catalogpending_invoice-img"
                      src={img_url}
                    />
                    {isViewerOpen && (
                      <ImageViewer
                        onClose={() => {
                          setIsViewerOpen(false);
                        }}
                        activeIndex={0}
                        src={[img_url]}
                      />
                    )}
                  </div>
                )}
              {img_url !== null &&
                img_url !== undefined &&
                img_url.indexOf(".pdf") !== -1 && (
                  <Button
                    type="default"
                    icon={<DownloadOutlined />}
                    size="large"
                    onClick={(e) => download(e, img_url, title)}
                  >
                    Download Invoice
                  </Button>
                )}
            </Col>
          </Row>
          <Row>
            {offers &&
              offers.map((row) => {
                const {
                  offer_id,
                  retail_price,
                  offer_price,
                  qty_total,
                  row_total,
                  row_tax,
                } = row;
                const vhrs =
                  vouchers &&
                  vouchers.filter((row) => row.offerId === offer_id);

                return (
                  <Card
                    key={offer_id}
                    style={{ marginTop: "1rem", width: "100%" }}
                    className="bizCard"
                  >
                    <h5>{row.title}</h5>
                    <Row style={{ marginBottom: "8px" }} >
                      <Col span={4}>
                        <Text className="invoice_text">Retail Price:</Text>
                      </Col>
                      <Col>
                        <Text className="invoice_value">
                          {currencySymbol}
                          {retail_price}
                        </Text>
                      </Col>
                    </Row>
                    <Row style={{ marginBottom: "8px" }}>
                      <Col span={4}>
                        <Text className="invoice_text">Offer Price:</Text>
                      </Col>
                      <Text className="invoice_value">
                        {currencySymbol}
                        {offer_price}
                      </Text>
                    </Row>

                    <Row style={{ marginBottom: "8px" }}>
                      <Col span={4}>
                        <Text className="invoice_text">Amount:</Text>
                      </Col>
                      <Text className="invoice_value">
                        {currencySymbol}
                        {qty_total}
                      </Text>
                    </Row>
                    <Row style={{ marginBottom: "8px" }}>
                      <Col span={4}>
                        <Text className="invoice_text">Tax:</Text>
                      </Col>
                      <Text className="invoice_value">
                        {currencySymbol}
                        {row_tax}
                      </Text>
                    </Row>
                    <Row style={{ marginBottom: "8px" }}>
                      <Col span={4}>
                        <Text className="invoice_text">Total Paid:</Text>
                      </Col>
                      <Text className="invoice_value">
                        {currencySymbol}
                        {row_total}
                      </Text>
                    </Row>
                    {isLoading && <Loader />}
                    {vhrs &&
                      vhrs.map((vhr) => {
                        const { redeemptionStatus, validTill } = vhr;
                        vhr.currencySymbol = currencySymbol;
                        return (
                          <div
                            key={vhr.objid}
                            style={{ marginTop: 10, width: "100%" }}
                          >
                            <Row style={{ marginBottom: "8px" }}>
                              <Col span={4}>
                                <Text className="invoice_text">Status:</Text>
                              </Col>
                              <Text className="invoice_value">
                                {getDisplayStatus(redeemptionStatus)}
                              </Text>
                            </Row>
                            <Row style={{ marginBottom: "8px" }}>
                              <Col span={4}>
                                <Text className="invoice_text">Valid Till:</Text>
                              </Col>
                              <Text type="danger" className="invoice_value">
                                {getFormattedDt(validTill)}
                              </Text>
                            </Row>
                            <Row style={{ marginBottom: "8px" }}>
                              <Col span={4}>
                                <Text className="big">Voucher ID:</Text>
                              </Col>

                              <Col>
                                <Button
                                  className="invoice_print"
                                  onClick={() => {
                                    setSelectedVoucher(vhr);
                                    setScreen("edit");
                                  }}
                                >
                                  <Text className="invoice_value" style={{ color: "#257CDB" }}>
                                    {vhr
                                      ? `VHR-${(vhr.objid + "").padStart(
                                        10,
                                        0
                                      )}`
                                      : ""}
                                  </Text>
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        );
                      })}
                  </Card>
                );
              })}
          </Row>
        </div>
        {inv.channel === "catalog" && renderListItems()}


        <div className="bizCard" style={{ marginBottom: "1rem" }}>
          {sumQuantities(inv?.invoiceDetails?.items || inv?.invoiceDetails?.offers) !== 0 &&
            <Row justify={"space-between"} style={{ marginBottom: "12px" }}>
              <Col span={12}>
                <Text className="invoice_text">
                  Total {capitalize(getchannelBought(channel))} Bought:
                </Text>
              </Col>
              <Col>
                <Text className="invoice_value">
                  {sumQuantities(inv?.invoiceDetails?.items || inv?.invoiceDetails?.offers)}
                </Text>
              </Col>
            </Row>}
          <Row justify="space-between" style={{ marginBottom: "12px" }}>
            <Col span={8}>
              <Text className="invoice_text">
                Total Amount:
              </Text>
            </Col>
            <Col>
              <Text className="invoice_value">
                {currencySymbol}
                {baseAmount.toFixed(2)}
              </Text>
            </Col>
          </Row>
          <Row justify="space-between" style={{ marginBottom: "12px" }}>
            <Col span={8}>
              <Text className="invoice_text">
                Total Tax:
              </Text>
            </Col>
            <Col>
              <Text className="invoice_value">
                {!isNaN(taxAmount) && taxAmount
                  ? `${currencySymbol}${taxAmount?.toFixed(2)}`
                  : "Included in Amount"}
              </Text>
            </Col>
          </Row>

          {tipAmount > 0 && (
            <Row justify="space-between" style={{ marginBottom: "12px" }}>
              <Col span={8}>
                <Text className="invoice_text">
                  Tip:
                </Text>
              </Col>
              <Col span={10}>
                <Text className="invoice_value">
                  {currencySymbol}
                  {tipAmount}
                </Text>
              </Col>
            </Row>
          )}

          <Row justify="space-between" style={{ marginBottom: "12px" }}>
            <Col span={8}>
              <Text className="invoice_text">
                Total Payable Amount:
              </Text>
            </Col>
            <Col>
              <Text className="invoice_value">
                {currencySymbol}
                {totalAmount}
              </Text>
            </Col>
          </Row>
        </div>

      </div>
      <Row justify={"space-between"} style={{ width: "100%" }} >
        <Col span={8} style={{ marginTop: "1rem" }}>
          <Button onClick={() => loadCart("add")} className="invoice_print">
            Edit Items
          </Button>
        </Col>
        <Col style={{ marginTop: "1rem" }}>
          <Button
            onClick={() => setConfirmModal(true)}
            className="invoice_dispute"
          >
            Cancel Invoice
          </Button>
        </Col>
        <Col span={8}>
          <Buttons
            btn1="Print"
            onClick1={() => {
              window.print();
            }}
            classname1="invoice_dispute"
            classname="invoice_print"
            disputeStyle={{
              backgroundColor: "white",
              color: "#ED0C10",
              border: "1px solid #ED0C10",
              padding: "10px 16px",
              borderRadius: "10px",
              height: "46px",
              fontSize: "16px",
            }
            }
            btn2={type === "payable" && "Raise a dispute"}
            onClick2={() => {
              setRaiseDispute(true);
            }}
            type="link"
            style={{ width: "100%" }}
            size="large"
          />
        </Col>
        <ConfirmationModal
          description="Are you sure you want to cancel this invoice ?"
          title="Cancel Invoice ?"
          onCancel={() => setConfirmModal(false)}
          onConfirm={() => cancelInvoice()}
          visible={confirmModal}
        />
      </Row>
      <ModalRaiseADispute
        visible={raiseDispute}
        className="transaction_raiseDisputeModal"
        width={400}
        button="Submit"
        input={<Input placeholder="title" />}
        description={
          <RichTextEditor
            getMarkdownValue={(value) => {
              // getMarkdownValue(value);
              console.log("getMarkdownValue", value);
            }}
            desc={desc}
          />
        }
        onCancel={() => setRaiseDispute(false)}
        onClick={() => setRaiseDispute(false)}
      />
    </>
  );
};

export default InvoiceView;
