import { Button, Col, Divider, Modal, Row, Card, Space } from "antd";
import React, { useEffect, useState } from "react";
import SlotBooking from "../SlotBooking/SlotBooking";
import edit_btn from "../../assets/images/edit_btn.svg";
import closeBtn from "../../assets/images/closeBtn.svg";
import { ScrollMenu } from "react-horizontal-scrolling-menu";

import moment from "moment";
import { isMobile } from "../../common/util";
import { Ro } from "react-flags-select";
import Icon from "@ant-design/icons/lib/components/AntdIcon";
import { DownOutlined, UpOutlined, EditOutlined } from "@ant-design/icons";
import AppointmentCard from "../AppointmentCard/AppointmentCard";
const closeIcon = <img src={closeBtn} alt="closeBtn" />;
function AllAppointment(props) {
  const [appointments, setAppointments] = useState(props.data || []);
  const [timeZone, setTimeZone] = useState("");
  const [expanded, setExpanded] = useState(true);
  useEffect(() => {
    console.log("props?.data", props);
    setAppointments(props?.data || []);
    getTimeZone();
  }, [props?.data]);
  const getTimeZone = async () => {
    let timeZoneValue = await localStorage.getItem("timeZoneString");
    setTimeZone(timeZoneValue);
  };
  return (
    <div>
      {/* <Row className="catalogue_checkout-step3-heading" style={{ padding: "24px" }}>

        <Col span={10}>
          <h5>Service</h5>
        </Col>
        <Col span={12}>Slots</Col>
        <Col span={2}>Price</Col>
      </Row> */}
      {props?.data &&
        props?.data
          // .filter((row) => row?.status === "active" && row?.appointment)
          .filter((row) => row?.status === "active")
          .sort((a, b) => b.offerLimit - a.offerLimit)
          .map((item, key) => {
            const discount = `${
              item.discountType === "%"
                ? item.amount + "%"
                : item.currencySymbol + item.amount
            } Off`;
            return (
              <>
                {props.onSave ? (
                  <>
                  <AppointmentCard item={item} isBookingEnabled={item.isBookingEnabled}
                  editClick={props.editClick}
                  />
                  </>
                ) : (
                  <AppointmentCard
                    item={item}
                    isBookingEnabled={item.isBookingEnabled}
                    editClick={props.editClick}
                  />
                )}
              </>
            );
          })}

      {props.btn2Name && (
        <>
          <Divider className="customize_divider" style={{ marginTop: "0px" }} />
          <Row justify={"space-between"} style={{ padding: "24px" }}>
            <Col
              span={20}
              style={{
                alignSelf: "center",
                paddingBottom: isMobile() && "12px",
              }}
              xs={18}
              sm={20}
              md={14}
            ></Col>

            <Col>
              <Button type="primary" onClick={props.SaveOnClick}>
                {props.btn2Name}
              </Button>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

export default AllAppointment;
