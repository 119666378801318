import React, { useState, useEffect } from "react";
import { Upload, Row, Modal, Col, message } from "antd";
import ImageViewer from "react-simple-image-viewer";
import ImgCrop from "antd-img-crop";

const Uploader = (props) => {
  const {
    imgUrl,
    allowedTypes,
    callback,
    aspect,
    quality,
    reset,
    uploaderType,
    label,
    maxCount,
  } = props;

  const [fileList, setFileList] = useState([]);
  const [isImageUploaded, setIsImageUploaded] = useState(false);

  const isImage = allowedTypes?.includes("image") && allowedTypes.length === 1;

  useEffect(() => {
    if (reset) {
      setFileList([]);
      setIsImageUploaded(false);
    }
  }, [reset]);

  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [previewImage] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const onUpload = async (info) => {
    let fileList = [...info.fileList];
    if (fileList.length > 0) {
      let file = fileList[0];
      let src = file.url;
      if (!src) {
        src = await toBase64(file.originFileObj);
      }

      callback({
        name: file.name,
        img: src,
        type: file.type,
        file: file.originFileObj,
      });

      setFileList(fileList);
      setIsImageUploaded(true);
    }
  };

  const beforeUpload = (file) => {
    let typeAllowed = false;

    allowedTypes.forEach((type) => {
      if (file.type.indexOf(type) !== -1) {
        typeAllowed = true;
      }
    });

    if (!typeAllowed) {
      message.error(`${file.name} : file type is not allowed`);
    }
    let sizeAllowed = true;
    const isLt5M = file.size / 1024 / 1024 <= 5;
    if (!isLt5M) {
      message.error(`Max upload size allowed is 5MB`);
      sizeAllowed = false;
    }
    return typeAllowed && sizeAllowed ? true : Upload.LIST_IGNORE;
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  const onRemove = ({ fileList: newFileList }) => {
    callback({
      name: "",
      img: "",
      type: "",
      file: "",
    });
    setFileList([]);
  };

  const onPreview = async (file) => {
    let src = file.url;

    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);

        reader.onload = () => resolve(reader.result);
      });
    }

    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 2000);
  };

  return (
    <>
      <Row justify="start" style={{ width: "100%" }}>
        {imgUrl !== undefined &&
          imgUrl !== null &&
          imgUrl.indexOf("pdf") === -1 && (
            <Col span={6}>
              {isViewerOpen && (
                <ImageViewer
                  onClose={() => {
                    setIsViewerOpen(false);
                  }}
                  activeIndex={0}
                  src={[imgUrl]}
                />
              )}
            </Col>
          )}
        <Col>
          {isImage ? (
              <div style={{ marginLeft: "5rem" }}>
                <ImgCrop
                  minZoom={0.09}
                  rotate
                  aspect={aspect ? aspect : 1}
                  quality={quality ? quality : 1}
                  cropperProps={{
                    restrictPosition: false,
                  }}
                >
                  <Upload
                    customRequest={dummyRequest}
                    maxCount={maxCount || 1}
                    listType={uploaderType || "picture-card"}
                    fileList={fileList}
                    onChange={onUpload}
                    onPreview={onPreview}
                    beforeUpload={beforeUpload}
                    onRemove={onRemove}
                  >
                    {isImageUploaded ? "Replace" : label || "Upload"}
                  </Upload>
                </ImgCrop>
              </div>
          ) : (
              <div style={{ marginLeft: "5rem" }}>
                <Upload
                  customRequest={dummyRequest}
                  maxCount={1}
                  listType={uploaderType || "picture-card"}
                  fileList={fileList}
                  onChange={onUpload}
                  onPreview={onPreview}
                  beforeUpload={beforeUpload}
                >
                  {isImageUploaded ? "Replace" : label || "Upload"}
                </Upload>
              </div>
          )}
        </Col>
      </Row>
      <div>
        <Modal
          visible={previewVisible}
          title={"Preview"}
          footer={null}
          onCancel={handleCancel}
        >
          <div className="view_modal_contaier" alt="example" id="modelimg">
            <img className="view_modal_image" src={previewImage} alt="" />
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Uploader;
