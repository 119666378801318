/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Button, message, Card, Typography } from "antd";
import { GiCheckMark } from "react-icons/gi";
import { WarningOutlined } from "@ant-design/icons";
import { ImCross } from "react-icons/im";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Images from "../../assets/images/Images";
import UserActions from "../../actions";
import { Theme } from "../../common";
import Loader from "../../components/Loading/Loader";
import PulseLoader from "react-spinners/PulseLoader";
import ContainerWrapper from "../../components/ContainerWrapper";
import { isMobile } from "../../common/util";
import HelmetFyndr from "../../components/HelmetFyndr";
import getEnvVars from "../../environment";

const { Text } = Typography;
const web = getEnvVars();
const StripeConnect = (props) => {
  const {
    match: {
      params: { connect_status },
    },
  } = props;

  const {
    indv: {
      qrcode,
      indvid,
      onBoarded,
      payoutsEnabled,
      chargesEnabled,
      merchantId,
    },
    settings,
    settingsPlatform,
  } = useSelector(({ auth }) => auth);
  const { isMasquerade } = useSelector(({ admin }) => admin);

  // const {
  //   pmt_fee: { fyndr_offer_fee, fyndr_invoice_fee, stripe_fee },
  // } = settings;

  const {
    pmt_fee: { fyndr_offer_fee, fyndr_invoice_fee, stripe_fee },
  } = settingsPlatform;

  const [onboardUrl, setOnboardUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const getStripeDetailsInfo = async () => {
    setIsLoading(true);
    console.log("id", indvid);

    let resp = await UserActions.verifyStripeRegistration(indvid);
    if (resp.success) {
      setErrors(resp?.response);
    } else {
      console.log("error>>", resp.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      if (merchantId && onBoarded && (!chargesEnabled || !payoutsEnabled)) {
        onSuccessHandler();
      }
    })();
  }, []);
  useEffect(() => {
    getStripeDetailsInfo();
  }, [indvid]);

  useEffect(() => {
    if (connect_status === "success") onSuccessHandler();
    else if (connect_status !== undefined) {
      message.warning("Stripe connection didn't complete, please try again");
    }
  }, [connect_status]);

  const startConnect = async () => {
    setIsLoading(true);
    const resp = await UserActions.stripeConnect(qrcode);
    setOnboardUrl(null);
    if (resp.success) {
      setOnboardUrl(resp.url);
      window.location.href = `${resp.url}`;
    } else {
      message.error(resp.message ? resp.message : "");
    }
    setIsLoading(false);
  };

  if (!qrcode) {
    return null;
  }

  const onSuccessHandler = async () => {
    setIsLoading(true);
    const resp = await UserActions.stripeConnectComplete({ indvid });
    setOnboardUrl(null);
    if (resp.success) {
      // message.success("Stripe connection succeeded");
    } else {
      message.warning("Stripe connection didn't went through, please retry..");
    }
    setIsLoading(false);
  };
  return (
    <ContainerWrapper
      title={"Stripe Integration"}
      headerOption={<Button type="link">{isLoading && <Loader />}</Button>}
    >
      {
        <HelmetFyndr
          title="Payment"
          imgUrl={""}
          url={`${web}/merchant/stripe_connect`}
          descr="merchant stripe_connect"
        />
      }
      <Row justify="center">
        <img
          alt=""
          src={Images.StripeImage}
          style={{ height: isMobile() ? 130 : 150, marginBottom: 10 }}
        />
      </Row>
      <Row justify="center">
        <h5>
          Businesses can now sell products and collect invoice payments on
          Fyndr.
        </h5>
      </Row>
      <Row justify={"center"}>
        <h6>
          {" "}
          Fyndr uses PCI compliant Stripe payment gateway to enable payments.
        </h6>
      </Row>
      <br /> <br />
      <Row justify="center">
        <Col>
          <Card style={{ backgroundColor: Theme.lightblue }}>
            <h5>Fyndr Fee</h5>
            <br />
            <h5>Offers:</h5>
            <h6>{`${
              fyndr_offer_fee && fyndr_offer_fee?.val_percent
            }% of sales + Stripe Fee*`}</h6>
            <br />
            <h5>Invoices:</h5>
            <h6>{`$${fyndr_invoice_fee && fyndr_invoice_fee?.val_flat} or ${
              fyndr_invoice_fee && fyndr_invoice_fee?.val_percent
            }% whichever is more + Stripe Fee*`}</h6>
            <br />
            <Text>*Stripe Fee: </Text>
            <Text>{` ${stripe_fee && stripe_fee?.val_percent}% + $${
              stripe_fee && stripe_fee?.val_flat
            } for domestic cards. International cards attract additional fee.`}</Text>
          </Card>
        </Col>
      </Row>
      <br />
      <Row gutter={[20, 10]} justify="center">
        <Col sm={24} xs={24}>
          {errors?.missing_info?.errors.length > 0 && errors?.missing_info && (
            <Card
              className="stripeCard"
              extra={
                <WarningOutlined
                  style={{
                    color: "#d9534f	",
                    fontSize: "16px",
                    height: "3rem",
                  }}
                />
              }
              title={
                <h4 className="card-title" style={{ color: "#d9534f	" }}>
                  Errors With Stripe
                </h4>
              }
            >
              {errors?.missing_info?.errors.map((item, key) => (
                <Row>
                  <Col xs={4} sm={2}>
                    <ExclamationCircleOutlined style={{ color: "#d9534f" }} />
                  </Col>
                  <Col style={{ position: "relative" }} span={20}>
                    <p
                      style={{
                        maxWidth: "100%",
                        overflowWrap: "break-word",
                      }}
                    >
                      {item?.reason}
                    </p>
                    <p>
                      {item.requirement
                        ? item.requirement.replace(/\./g, " -> ")
                        : ""}
                    </p>
                  </Col>
                </Row>
              ))}
            </Card>
          )}

          {errors?.missing_info?.pendingVerification.length > 0 &&
            errors?.missing_info && (
              <Card
                className="stripeCard"
                extra={
                  <WarningOutlined
                    style={{
                      color: "#d9534f	",
                      fontSize: "16px",
                      height: "3rem",
                    }}
                  />
                }
                title={
                  <h4 className="card-title" style={{ color: "#d9534f	" }}>
                    Pending Verification
                  </h4>
                }
              >
                {errors?.missing_info?.pendingVerification.map((item, key) => (
                  <Row>
                    <Col xs={4} sm={2}>
                      <ExclamationCircleOutlined style={{ color: "#d9534f" }} />
                    </Col>
                    <Col style={{ position: "relative" }} span={20}>
                      <p
                        style={{
                          maxWidth: "100%",
                          overflowWrap: "break-word",
                        }}
                      >
                        {item.replace(/\./g, " -> ")}
                      </p>
                    </Col>
                  </Row>
                ))}
              </Card>
            )}
        </Col>
      </Row>
      <br />
      <Row justify="center">
        <Col>
          {console.log(
            "onboardUrl",
            onboardUrl,
            onBoarded,
            chargesEnabled,
            payoutsEnabled
          )}
          {!onboardUrl &&
            !isMasquerade &&
            !onBoarded &&
            !chargesEnabled &&
            !payoutsEnabled && (
              <div style={{ padding: "2rem" }}>
                {/* <Alert
                          type="warning"
                          message="Please ensure that your business phone no. is not empty before proceeding.."
                        /> */}

                <Row style={{ marginTop: "2rem" }} justify="center">
                  {
                    <Button
                      disabled={isLoading}
                      size="large"
                      type="primary"
                      onClick={startConnect}
                    >
                      {errors?.missing_info ? "Continue" : "Register"} with
                      Stripe {isLoading && <Loader />}
                    </Button>
                  }
                </Row>
              </div>
            )}

          {chargesEnabled === false && onBoarded && (
            <>
              <Row>
                <Col>
                  <h6 style={{ lineHeight: "17px" }}>
                    {" "}
                    <ImCross
                      style={{
                        color: Theme.red,
                        fontSize: isMobile() ? 12 : 20,
                        marginRight: 10,
                        marginTop: "2px",
                      }}
                    />
                    We still need some more information before accepting card
                    payments for your offers.
                  </h6>
                </Col>
              </Row>
              <Row>
                <Col
                  style={{ paddingLeft: isMobile() ? 0 : 30, marginBottom: 10 }}
                >
                  <h6>
                    Please review your {"  "}
                    <a
                      href="https://stripe.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Stripe Account
                    </a>
                    .
                  </h6>
                </Col>
              </Row>
            </>
          )}

          {chargesEnabled === true && (
            <div>
              <h5>
                <GiCheckMark style={{ color: Theme.green, fontSize: 24 }} /> You
                are all set to sell products on Fyndr platform.
              </h5>
            </div>
          )}
          {payoutsEnabled === false && onBoarded && (
            <>
              <Row>
                <Col>
                  <h6 style={{ lineHeight: "17px" }}>
                    <ImCross
                      style={{
                        color: Theme.red,
                        fontSize: isMobile() ? 12 : 20,
                        marginRight: 10,
                        marginTop: "2px",
                      }}
                    />
                    We still need some more information before sending payments
                    to your account.
                  </h6>
                </Col>
              </Row>
              <Row>
                <Col style={{ paddingLeft: isMobile() ? 0 : 30 }}>
                  <h6>
                    Please review your {"  "}
                    <a
                      href="https://stripe.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Stripe Account
                    </a>
                    .
                  </h6>
                </Col>
              </Row>
            </>
          )}
          {(payoutsEnabled === false || chargesEnabled === false) && (
            <Row style={{ marginTop: "2rem" }} justify="center">
              {
                <Col>
                  <div>
                    <Button
                      disabled={isLoading}
                      size="medium"
                      type="primary"
                      onClick={startConnect}
                    >
                      Continue with Stripe {isLoading && <Loader />}
                    </Button>
                    <Button onClick={() => getStripeDetailsInfo()} type="link">
                      Refresh
                    </Button>
                  </div>
                </Col>
              }
            </Row>
          )}
          {payoutsEnabled === true && (
            <div>
              <h5>
                <GiCheckMark style={{ color: Theme.green, fontSize: 24 }} /> You
                are all set to receive funds in your bank account.
              </h5>
            </div>
          )}
        </Col>
      </Row>
      <br /> <br />
      <br /> <br />
      <h6>
        Visit:
        <a href="https://stripe.com" target="_blank" rel="noopener noreferrer">
          {` Stripe Account`}
        </a>
        .
      </h6>
    </ContainerWrapper>
  );
};

export default StripeConnect;
