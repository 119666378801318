/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Row, Col, Divider, Modal, Space, Image } from "antd";
import getEnvVars from "../../../environment";
import { Link } from "react-router-dom";
import ContactUsComponent from "./ContactUs";
import "../../../sass/styles/fyndr/_footer.scss";
import DownloadFyndr from "../../../assets/images/DownloadFyndr.svg";
import TermsAndCondition from "../../../assets/images/Terms-and-conditions.svg";
import ContactUs from "../../../assets/images/ContactUs.svg";
import PrivacyPolicy from "../../../assets/images/PrivacyPolicy.svg";
import BusinessAgreement from "../../../assets/images/BusinessAgreement.svg";
import Faq from "../../../assets/images/FAQ-icon.svg";
import FaceBookIcon from "../../../assets/images/devicon_facebook.png";
import InstaIcon from "../../../assets/images/skill-icons_instagram.png";

const Footer = (props) => {
  const { web } = getEnvVars();
  const [visible, setVisible] = useState(false);

  return (
    <section>
      <footer className="footer-new" style={{marginTop:"1rem"}}>
        <Row align="middle" >
          <Col xs={3} xxs={3} sm={2} lg={2} xl={2} xxl={2} style={{display:"flex", justifyContent:"space-evenly"}}>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.facebook.com/profile.php?id=61551952386073"
            >
              <img src={FaceBookIcon} className="insta-facebook" />
            </a>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.instagram.com/fyndr.us/"
            >
              <img src={InstaIcon} className="insta-facebook" />
            </a>
          </Col>
          <Col
          xxs={21}
          xs={21}
          sm={22}
          lg={22}
          xxl={22}
           >
            <div className="outer-container-div">
              <div className="inner-icon-text-div">
                <img src={DownloadFyndr} className="footer-icons" />
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`${web}/app-download`}
                  className="footer-text"
                >
                  Download Fyndr
                </a>
              </div>
              <Divider
                style={{ background: "#D3D6E1", height: "20px" }}
                type="vertical"
              />

              <div className="inner-icon-text-div">
                <img src={ContactUs} className="footer-icons" />
                <a
                  onClick={() => {
                    setVisible(true);
                  }}
                  className="footer-text"
                >
                  Contact Us
                </a>
              </div>
              <Divider
                style={{ background: "#D3D6E1", height: "20px" }}
                type="vertical"
              />

              <div className="inner-icon-text-div">
                <img src={TermsAndCondition} className="footer-icons" />
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`${web}/legal/terms.html`}
                  className="footer-text"
                >
                  Terms of Use
                </a>
              </div>
              <Divider
                style={{ background: "#D3D6E1", height: "20px" }}
                type="vertical"
              />

              <div className="inner-icon-text-div">
                <img src={PrivacyPolicy} className="footer-icons" />
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`${web}/legal/privacy.html`}
                  className="footer-text"
                >
                  Privacy Policy
                </a>
              </div>
              <Divider
                style={{ background: "#D3D6E1", height: "20px" }}
                type="vertical"
              />
              <div className="inner-icon-text-div">
                <img src={BusinessAgreement} className="footer-icons" />
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`${web}/legal/agreement.html`}
                  className="footer-text"
                >
                  Business Agreement
                </a>
              </div>
              <Divider
                style={{ background: "#D3D6E1", height: "20px" }}
                type="vertical"
              />
              <div className="inner-icon-text-div">
                <img src={Faq} className="footer-icons" />
                <Link to="/aboutus#faq" className="footer-text">
                  FAQ
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </footer>
      <Modal
        onCancel={() => setVisible(false)}
        footer={() => null}
        title="Contact Us"
        visible={visible}
      >
        <ContactUsComponent onCancel={(value) => setVisible(value)} />
      </Modal>
    </section>
  );
};

export default Footer;
