import React, { useState } from "react";
import editIcon from "../../assets/images/editIcon.svg";
import CustomizedModal from "../CustomizedModal/CustomizedModal";
import "../../sass/styles/fyndr/_gift.scss";

const FormDetailsPage = (props) => {
  const { formData, onClick } = props;
  const [showModal, setShowModal] = useState(false);
  const message = formData?.message;

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="form-details-container" >
      <p
        style={{
          display: "inline-flex",
          justifyContent: "space-between",
          alignItems: "center",
          width:"100%"
        }}
      >
        <span style={{ fontWeight: 500 }}>Name :
        <span style={{fontWeight:300}}>{formData.name}</span></span>     
        <img src={editIcon} style={{ marginLeft: "auto" }} onClick={onClick} />
      </p>
      <p>
        <span style={{ fontWeight: 500 }}>Email :</span> {formData.email}
      </p>
      <p>
        <span style={{ fontWeight: 500 }}>Phone :</span>{" "}
        {formData.phoneNumber ? `${formData.countryCode} ${formData.phoneNumber}` : "N/A"}
      </p>
      <div>
        <p style={{ margin: "0px" }}>
          <span style={{ fontWeight: 500 }}>Message :</span>{" "}
          {message && message.length > 0 ? (message.length > 50 ? message.substring(0, 50) : message) : "N/A"}
          {message && message.length > 50 && (
            <span
              style={{
                color: "#223369",
                cursor: "pointer",
              }}
              onClick={openModal}
            >
              ...Read more
            </span>
          )}
        </p>

        <CustomizedModal
          title="Message"
          visible={showModal}
          onCancel={closeModal}
          modalWidth={400}
          className="read-more-modal"
          footer={null}
          content={<div className="read-more-body">{message}</div>}
          />
      </div>
    </div>
  );
};

export default FormDetailsPage;
