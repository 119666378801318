/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Row,
  Input,
  Checkbox,
  Divider,
  Layout,
  Radio,
  Slider,
  Modal,
  Card,
  Space,
  Button,
  Popconfirm,
  message,
} from "antd";

import { getClosingTime, getTiming12, isMobile, isTab } from "../../common/util";
import { Actions } from "../../common";
import { AnimateSharedLayout } from "framer-motion";

import {
  SearchOutlined,
  AppstoreOutlined,
  AppstoreFilled,
} from "@ant-design/icons";

import { Theme } from "../../common";
import UserActions from "../../actions";
import { useRecoilState } from "recoil";
import { offerPageState } from "../../recoil/Atom";
import GoogleMap from "../../components/GoogleMap";
import Images from "../../assets";
import googlelocations from "../../assets/images/googlelocation.svg";

import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import moment from "moment";
import { TopMenu, Logo, MarkerGoogle, Footer } from "../../components";
import { parseAddress } from "../../common/util";
import {
  FaClock,
  FaGlobe,
  FaHeart,
  FaMapMarkerAlt,
  FaPhoneAlt,
} from "react-icons/fa";
import { FiHeart } from "react-icons/fi";
import { object } from "yup";
import Loader from "../../components/Loading/Loader";

const LandingPage = (props) => {
  const dispatch = useDispatch();
  const { LOCOFFER, LOCQR, CART_STARTED, ROLE_FYNDR } = Actions;
  const {
    indv,
    indv: { indvid, qrid, entityType },
  } = useSelector(({ auth }) => auth);
  // const { cmpnSearchParams: { bizName, qlat, qlng }} = useSelector(({ core }) => core);
  const [offerPageSetting, setOfferPageSetting] =
    useRecoilState(offerPageState);
  console.log("offerPageSetting search>>>", offerPageSetting);
  const [viewLocationModal, setViewLocationModal] = useState(false);
  const [viewWorkingHourModal, setViewWorkingHourModal] = useState(false);
  const [isCategory, setIsCategory] = useState(props?.location?.state?.isCategory ? true : false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [subTypeDeals, setSubTypeDeals] = useState(
    offerPageSetting?.subTypeDeals || ["all"]
  );
  const { isMasquerade } = useSelector(({ admin }) => admin);
  const [miles, setMiles] = useState(50);
  const [categories, setCategories] = useState(
    offerPageSetting?.categories || props?.location?.state?.searchStr
  );
  const { bizLikes } = useSelector(({ cmpn }) => cmpn);
  const { locOffers } = useSelector(({ catalogue }) => catalogue);
  console.log("locOffers", locOffers);
  const [bottomSheet, setOpenBottomSheet] = useState(false);
  const [section] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [pgSize] = useState(100);
  const [searchStr, setSearchStr] = useState(
    props.location.state?.searchStr || offerPageSetting?.searchStr
  );
  const [itemlist, setItemList] = useState(props.location.state?.items);
  const [isfilterOn, setIsFilter] = useState(false);
  const [isOption] = useState(true);
  const [label, setLabel] = useState("Current Location");
  const [lat, setLat] = useState(
    offerPageSetting?.lat || indv.lat || 33.6629442
  );
  const [lng, setLng] = useState(
    offerPageSetting?.lng || indv.lng || -112.0182329
  );
  const [addressName, setAddressName] = useState(
    offerPageSetting?.addressName || parseAddress(indv?.address)
  );
  const [likedList, setLikedList] = useState([]);
  const [, setLocOffers] = useState({});
  const [, setLocQrs] = useState({});
  const [searchItems, fetchMore] = useSearch(false);
  const [categoryList, setCategoryList] = useState([]);
  const [page, setPage] = useState(1);


  const categoryListArr = categoryList.map((row) => {
    return { value: row.name, label: row.name };
  });

  function useSearch() {
    // const [page, setPage] = useState(0);
    // default this to true to kick the initial effect hook to
    // fetch the first page
    const [shouldFetch, setShouldFetch] = useState(false);
    const [items, setItems] = useState([]);
    // return this function for Flatlist to call onEndReached
    const fetchMore = useCallback((reStart) => {
      setShouldFetch(true);
      if (reStart === true) {
        setPage(1);
        setItems([]);
      }
    }, []);

    useEffect(() => {
      // prevent fetching for other state changes
      if (!shouldFetch) {
        return;
      }

      const fetch = async () => {
        setIsLoading(true);

        //this is just to set the message properly i.e. 'No listing found..'
        // dispatch({ type: types.BIZDIR_SEARCH, payload: "yes" });
        const { success, payload, last } = await UserActions.searchBizdir({
          searchStr,
          page,
          pgSize,
          location: {
            lat,
            lng,
          },
          distance: miles,
          isCategory: isCategory,
          indvId: indvid,
        });

        //this is just to set the message properly i.e. 'No listing found..'
        // dispatch({ type: types.BIZDIR_SEARCH, payload: "no" });
        // set the should fetch call to false to prevent fetching
        // on page number update
        if (success) {
          setItems((old) => [...old, ...payload]);
          //increment page for the next call
          setPage(page + 1);
          setIsLast(last);
          //build location list to search offers
          let locToSearch = [];
          let newQrs = {};
          let bizList = [];
          payload.forEach((row) => {
            if (!Object.keys(locOffers).includes(row.objid)) {
              locToSearch.push(row.objid);
              newQrs[row.objid + ""] = row.qrid;
              bizList.push(row.bizid);
            }
          });
          console.log("locToSearch>>>", locToSearch);

          UserActions.fetchBizinfo({
            bizId: [...new Set(bizList)],
          });

          if (locToSearch.length > 0) {
            const { success, resp } = await UserActions.fetchLocOffers(
              locToSearch.join(",")
            );
            if (success) {
              let newOffers = {};
              resp.forEach((row) => {
                newOffers[row.locid + ""] = {
                  count: row.count,
                  activeCampaignCount: row.activeCampaignCount,
                  cmpnQrCode: row.cmpnQrCode,
                  img: row.cmpnImg,
                };
              });

              setLocOffers((old) => ({ ...old, ...newOffers }));
              setLocQrs((old) => ({ ...old, ...newQrs }));
              // await setLocOffers({ ...locOffers, ...newOffers });
              // await setLocQrs({ ...locQrs, ...newQrs });

              dispatch({
                type: LOCOFFER,
                payload: newOffers,
              });
              dispatch({
                type: LOCQR,
                payload: newQrs,
              });
            }
          }
        }
        setIsLoading(false);

        setShouldFetch(false);
      };
      fetch();
    }, [shouldFetch]);

    return [items, fetchMore];
  }

  const scrollRef = useRef(null);
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const handleScroll = () => {
    // if (!scrollRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
    if (scrollHeight - scrollTop <= clientHeight + 20) {
      setIsAtBottom(true);
    } else {
      setIsAtBottom(false);
    }

  }
  useEffect(() => {
    const innerScrollRef = scrollRef.current;
    innerScrollRef.addEventListener("scroll", handleScroll);
    return () => {
      innerScrollRef.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isAtBottom && !isLast) {
      // setPage(page+1);
      fetchMore();
    }
  }, [isAtBottom]);

  useEffect(() => {
    getCampnList();
  }, []);

  const likeBiz = async (bizid) => {
    const resp = await UserActions.likeBiz({ bizid, indvid });
    if (resp.success) {
      const tmp = likedList;
      tmp.push(bizid);
      setLikedList(tmp);
      // setRefresh(!refresh);
    } else {
      message.error("Please login first");
    }
  };
  const fetchIpAddress = async () => {
    if (navigator.geolocation) {
      try {
        setIsLoading(true);
        await navigator.geolocation.getCurrentPosition(showPosition);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    } else {
      alert("geolocation is not supported");
    }
  };

  const onChangeType = (value) => {
    if (value.indexOf("all") === -1) {
      setSubTypeDeals(value);
    } else {
      if (value[value.length - 1] === "all") {
        setSubTypeDeals(["all"]);
      } else {
        let indexcheck = value.indexOf("all");
        value.splice(indexcheck, 1);
        setSubTypeDeals(value);
      }
    }
  };

  const onChangeCategories = (value) => {
    setSearchStr(value?.target?.value);
    setCategories(value?.target?.value);
    fetchMore(true);
  };
  let newobj = {};
  const result = [];
  const getCampnList = async () => {
    let response = await UserActions.fetchcmpnCategoriesList();
    for (let value of response) {
      newobj = { ...value };
      result.push(newobj);
    }
    const newArrayOfObj = result.map(({ 0: objid, 1: name, ...rest }) => ({
      objid,
      name,
      ...rest,
    }));
    setCategoryList(newArrayOfObj);
  };
  function showPosition(position) {
    setLat(position.coords.latitude);
    setLng(position.coords.longitude);

    setOfferPageSetting({
      ...offerPageSetting,
      lat: position.coords.latitude,
      lng: position.coords.longitude,
      addressName: addressName,
    });
  }

  const getCategories = async (locId) => {
    setIsLoading(true);
    const resp = await UserActions.getCatalogueByLoc({
      locId,
      buyerQrId: qrid,
    });
    setIsLoading(false);
    dispatch({ type: CART_STARTED });
    props.history.push(`/catalogue/store/${resp?.payload?.catalogue?.url}`, {
      catalogueDetail: resp.payload,
    });
  };

  const getCoordinates = async (value) => {
    let geoResult = await geocodeByAddress(value.value.description);
    let coordinates = await getLatLng(geoResult[0]);
    setLat(coordinates.lat);
    setLng(coordinates.lng);
    setLabel(value.label);
    setAddressName(value.label);

    setOfferPageSetting({
      ...offerPageSetting,
      lat: coordinates.lat,
      lng: coordinates.lng,
      addressName: value.label,
    });
  };

  useEffect(() => {
    (async () => {
      await setOfferPageSetting({
        ...offerPageSetting,
        subTypeDeals,
        searchStr,
      });
    })();
  }, [subTypeDeals, searchStr]);

  useEffect(() => {
    fetchMore(true);
  }, [miles, lng]);

  useEffect(() => {
    setItemList(searchItems);
  }, [searchItems]);
  //new implementaion
  //components
  const ItemCard = ({ item, key }) => {
    const [itemClicked, setItemClicked] = useState(false);
    const { website, catalogueId, phone, workingHours, businessWorkingHours, objid, bizid, lat } =
      item;

    let liked = item.liked;
    const hasOffers = locOffers.hasOwnProperty(objid);

    function checkBusinessHours() {
      const today = new Date();
      const currentDay = today.toLocaleString('en-US', { weekday: 'long' });
      const currentTime = today.toTimeString().slice(0, 5);
      if (!businessWorkingHours || Object.keys(businessWorkingHours).length === 0) {
        return null;
      }
      const workingHours = businessWorkingHours[currentDay];
      
      if (workingHours && Array.isArray(workingHours) && workingHours.length > 0) {
    
        if (isWithinWorkingHours(currentTime, workingHours)) {
          const closingTime = getClosingTime(currentTime, workingHours);
          return `Open until ${closingTime}`;
        } else {
          return "Closed";
        }
      } else {
        return null;
      }
    }
    
    function isWithinWorkingHours(currentTime, workingHours) {
      if (!workingHours || !Array.isArray(workingHours)) {
        return false;
      }
      for (const timeRange of workingHours) {
        if (timeRange) {
          const [start, end] = timeRange.split('-');    
          if (start && end && currentTime >= start && currentTime <= end) {
            return true;
          }
        }
      }
          return false;
    }
    
    function getClosingTime(currentTime, workingHours) {    
      if (!workingHours || !Array.isArray(workingHours)) {
        return null;
      }
    
      for (const timeRange of workingHours) {
        if (timeRange) {
          const [startTime, endTime] = timeRange.split('-');
          if (startTime && endTime && currentTime >= startTime && currentTime <= endTime) {
            const [hours, minutes] = endTime.split(':');
            const formattedHours = (hours % 12) || 12;
            const period = hours >= 12 ? 'PM' : 'AM';
            return `${formattedHours}:${minutes} ${period}`;
          }
        }
      }
      return null;
    }
    
    const closureInfo = Object.keys(businessWorkingHours).length > 0 ? checkBusinessHours() : getClosingTime(workingHours) 
    
    const onImgClick = () => {
      if (locOffers[objid]?.count > 0) {
        // props.history.push("/offers", {
        //   locQRId: item.qrid,
        // });
        if (locOffers[objid]?.activeCampaignCount > 1) {
          props.history.push("/offers", {
            locQRId: item.qrid,
          })
        } else {
          props.history.push(
            `/offer-details/${item?.bizName?.replace(/[.\W]+/g, "-")}/${locOffers[objid]?.cmpnQrCode
            }`,
            {
              item,
              liked,
            }
          )
        }
      } else {
        getCategories(item.objid);
      }
    };

    return hasOffers !== false || catalogueId !== null ? (
      <div>
        <Card
          key={key}
          bordered
          onMouseOver={() => setItemClicked(true)}
          onMouseLeave={() => setItemClicked(false)}
        >
          <Row align="top" justify="space-between">
            <Col xs={24} sm={24} md={9} lg={10} xl={10} xxl={9} >
              <img
                className="searchBIZ_img"
                // style={{resizeMode:'contain'}}
                src={
                  item.catImg
                    ? item.catImg
                    : item.mainLogo
                      ? item.mainLogo
                      : locOffers[objid]?.img
                        ? locOffers[objid]?.img
                        : Images.Images.PlaceHolder
                }
                onClick={() => onImgClick()}
                style={{ cursor: "pointer" }}
                alt="biz "
              />
            </Col>
            <Col md={1}></Col>
            <Col align="left" xs={24} sm={24} md={14} xxl={13} lg={13}>
              <Row align="middle">
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <Row justify="space-between">
                    <Col span={22}>
                      <h5 className="searchBIZ_title">{item?.bizName}</h5>
                    </Col>
                    <Col span={2}>
                      {bizLikes[bizid] > 0 && (
                        <Row>
                          <FaHeart
                            className="searchBIZ_iconheart"
                            style={{
                              color: Theme.blue,
                            }}
                          />
                          {/* <h6>{bizLikes[bizid]}</h6> */}
                        </Row>
                      )}
                    </Col>
                  </Row>

                  <h6 className="searchBIZ_add">{`${item.distance ? item.distance.toFixed(1) : "0"
                    } miles, ${parseAddress(item)}`}</h6>
                  <Row align="middle" justify="space-between">
                    <Col span={14}>
                      {closureInfo ? (
                        <h6 className="searchBIZ_distance">{closureInfo}</h6>
                      ) : (
                        // <div style={{ marginBottom: "1rem" }} />
                        <div />
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>

              {!isMobile() ? (
                <Row style={{ width: "100%" }} >
                  <Col xs={24} sm={3} md={4} lg={3} xl={3} xxl={3}>
                    {phone !== null && (
                      <Button
                        className="offer_list_contactList"
                        onClick={() => window.open(`tel:${phone}`)}
                        type="text"
                        icon={
                          <FaPhoneAlt
                            className="searchBIZ_Faicons"
                            style={{
                              color: Theme.blue,
                            }}
                          />
                        }
                        style={{
                          color: Theme.blue,
                          display: "flex",
                          alignItems: "center",
                          padding: "4px 15px 4px 0",
                        }}
                      >
                        Call
                      </Button>
                    )}
                  </Col>
                  {website !== undefined && website !== null && (
                    <Col xs={24} sm={4} md={5} lg={4} xl={4} xxl={4}>
                      <Popconfirm
                        overlay
                        placement="top"
                        title={
                          <div style={{ width: "24rem" }}>
                            You are visiting the link {website} outside Fyndr
                            domain that is not verified or secured by Fyndr, do
                            you want to continue ?
                          </div>
                        }
                        onConfirm={() => {
                          if (!website.includes("https://www.fyndr.us/")) {
                            window.open(`https://${website}`);
                          } else {
                            window.open(`${website}`);
                          }
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button
                          className="offer_list_contactList"
                          type="text"
                          icon={
                            <FaGlobe
                              className="searchBIZ_Faicons"
                              style={{
                                color: Theme.blue,
                              }}
                            />
                          }
                          style={{
                            color: Theme.blue,
                          }}
                        >
                          Website
                        </Button>
                      </Popconfirm>
                    </Col>
                  )}
                  {lat !== null && (
                    <Col xs={24} sm={4} md={4} lg={3} xl={4} xxl={4}>
                      <Button
                        className="offer_list_contactList"
                        onClick={() => {
                          setViewLocationModal(true);
                          setSelectedItem(item);
                        }}
                        type="text"
                        icon={
                          <FaMapMarkerAlt
                            className="searchBIZ_Faicons"
                            style={{
                              color: Theme.blue,
                            }}
                          />
                        }
                        style={{
                          color: Theme.blue,
                        }}
                      >
                        Location
                      </Button>
                    </Col>
                  )}

                  {Object.keys(businessWorkingHours)?.length > 0 && Object.keys(businessWorkingHours) !== null
                    ?
                    (
                      <Col xs={24} sm={4} md={9} lg={5} xl={5} xxl={4}>
                        <Button
                          className="offer_list_contactList"
                          onClick={() => {
                            setSelectedItem(item);
                            setViewWorkingHourModal(true);
                          }}
                          type="text"
                          icon={
                            <FaClock
                              className="searchBIZ_Faicons"
                              style={{
                                color: Theme.blue,
                              }}
                            />
                          }
                          style={{
                            color: Theme.blue,
                          }}
                        >
                          Working Hours
                        </Button>
                      </Col>
                    )
                    :
                    workingHours !== null && workingHours.length > 0 && (
                      <Col xs={24} sm={4} md={9} lg={5} xl={5} xxl={4}>
                        <Button
                          className="offer_list_contactList"
                          onClick={() => {
                            setSelectedItem(item);
                            setViewWorkingHourModal(true);
                          }}
                          type="text"
                          icon={
                            <FaClock
                              className="searchBIZ_Faicons"
                              style={{
                                color: Theme.blue,
                              }}
                            />
                          }
                          style={{
                            color: Theme.blue,
                          }}
                        >
                          Working Hours
                        </Button>
                      </Col>
                    )}
                  <Col
                    xs={24}
                    sm={4}
                    md={2}
                    lg={4}
                    xl={2}
                    xxl={4}
                    className="likeIcon_Mobile"
                  >
                    {liked === "yes" ||
                      likedList.filter((val) => val === bizid).length > 0 ? (
                      <Button
                        className="offer_list_contactList"
                        style={{
                          border: "none",
                          color: Theme.blue,
                        }}
                        onClick={() => likeBiz(bizid)}
                      >
                        <FaHeart
                          className="heart_length"
                          style={{
                            color: Theme.blue,
                          }}
                        />
                        {bizLikes[bizid]}
                      </Button>
                    ) : (
                      <Button
                        className="offer_list_contactList"
                        style={{
                          color: Theme.blue,
                          border: "none",
                        }}
                        onClick={() => likeBiz(bizid)}
                      >
                        <FiHeart
                          className="heart_nolength"
                          style={{
                            color: Theme.blue,
                          }}
                        />
                        {bizLikes[bizid]}
                      </Button>
                    )}
                  </Col>
                </Row>) : (

                <Row style={{ width: "100%", paddingBottom: "10px" }} justify={"center"}>
                  <Col xs={4} sm={3} md={4} lg={3} xl={3} xxl={3}>
                    {phone !== null && (
                      <Button
                        className="offer_list_contactList"
                        onClick={() => window.open(`tel:${phone}`)}
                        type="text"
                        icon={
                          <FaPhoneAlt
                            className="searchBIZ_Faicons"
                            style={{
                              color: Theme.blue,
                            }}
                          />
                        }
                        style={{
                          color: Theme.blue,
                          display: "flex",
                          alignItems: "center",
                          padding: "4px 15px 4px 0",
                        }}
                      >

                      </Button>
                    )}
                  </Col>
                  {website !== undefined && website !== null && (
                    <Col xs={4} sm={4} md={5} lg={4} xl={4} xxl={4}>
                      <Popconfirm
                        overlay
                        placement="top"
                        title={
                          <div style={{ width: "24rem" }}>
                            You are visiting the link {website} outside Fyndr
                            domain that is not verified or secured by Fyndr, do
                            you want to continue ?
                          </div>
                        }
                        onConfirm={() => {
                          if (!website.includes("https://www.fyndr.us/")) {
                            window.open(`https://${website}`);
                          } else {
                            window.open(`${website}`);
                          }
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button
                          className="offer_list_contactList"
                          type="text"
                          icon={
                            <FaGlobe
                              className="searchBIZ_Faicons"
                              style={{
                                color: Theme.blue,
                              }}
                            />
                          }
                          style={{
                            color: Theme.blue,
                          }}
                        >

                        </Button>
                      </Popconfirm>
                    </Col>
                  )}
                  {lat !== null && (
                    <Col xs={4} sm={4} md={4} lg={3} xl={4} xxl={4}>
                      <Button
                        className="offer_list_contactList"
                        onClick={() => {
                          setViewLocationModal(true);
                          setSelectedItem(item);
                        }}
                        type="text"
                        icon={
                          <FaMapMarkerAlt
                            className="searchBIZ_Faicons"
                            style={{
                              color: Theme.blue,
                            }}
                          />
                        }
                        style={{
                          color: Theme.blue,
                        }}
                      >

                      </Button>
                    </Col>
                  )}

                  {Object.keys(businessWorkingHours)?.length > 0 && Object.keys(businessWorkingHours) !== null
                    ?
                    (
                      <Col xs={4} sm={4} md={9} lg={5} xl={5} xxl={4}>
                        <Button
                          className="offer_list_contactList"
                          onClick={() => {
                            setSelectedItem(item);
                            setViewWorkingHourModal(true);
                          }}
                          type="text"
                          icon={
                            <FaClock
                              className="searchBIZ_Faicons"
                              style={{
                                color: Theme.blue,
                              }}
                            />
                          }
                          style={{
                            color: Theme.blue,
                          }}
                        >

                        </Button>
                      </Col>
                    )
                    :
                    workingHours !== null && workingHours.length > 0 && (
                      <Col xs={4} sm={4} md={9} lg={5} xl={5} xxl={4}>
                        <Button
                          className="offer_list_contactList"
                          onClick={() => {
                            setSelectedItem(item);
                            setViewWorkingHourModal(true);
                          }}
                          type="text"
                          icon={
                            <FaClock
                              className="searchBIZ_Faicons"
                              style={{
                                color: Theme.blue,
                              }}
                            />
                          }
                          style={{
                            color: Theme.blue,
                          }}
                        >

                        </Button>
                      </Col>
                    )}
                  <Col
                    xs={4}
                    sm={4}
                    md={2}
                    lg={4}
                    xl={2}
                    xxl={4}
                    className="likeIcon_Mobile"
                  >
                    {liked === "yes" ||
                      likedList.filter((val) => val === bizid).length > 0 ? (
                      <Button
                        className="offer_list_contactList"
                        style={{
                          border: "none",
                          color: Theme.blue,
                        }}
                        onClick={() => likeBiz(bizid)}
                      >
                        <FaHeart
                          className="heart_length"
                          style={{
                            color: Theme.blue,
                          }}
                        />
                        {bizLikes[bizid]}
                      </Button>
                    ) : (
                      <Button
                        className="offer_list_contactList"
                        style={{
                          color: Theme.blue,
                          border: "none",
                        }}
                        onClick={() => likeBiz(bizid)}
                      >
                        <FiHeart
                          className="heart_nolength"
                          style={{
                            color: Theme.blue,
                          }}
                        />
                        {bizLikes[bizid]}
                      </Button>
                    )}
                  </Col>
                </Row>)}

              {/* <Row style={{ width: "100%" }}>
                <Col xs={24} sm={3} md={4} lg={3} xl={3} xxl={3}>
                  {phone !== null && (
                    <Button
                      className="offer_list_contactList"
                      onClick={() => window.open(`tel:${phone}`)}
                      type="text"
                      icon={
                        <FaPhoneAlt
                          className="searchBIZ_Faicons"
                          style={{
                            color: Theme.blue,
                          }}
                        />
                      }
                      style={{
                        color: Theme.blue,
                        display: "flex",
                        alignItems: "center",
                        padding: "4px 15px 4px 0",
                      }}
                    >
                      Call
                    </Button>
                  )}
                </Col>
                {website !== undefined && website !== null && (
                  <Col xs={24} sm={4} md={5} lg={4} xl={4} xxl={4}>
                    <Popconfirm
                      overlay
                      placement="top"
                      title={
                        <div style={{ width: "24rem" }}>
                          You are visiting the link {website} outside Fyndr
                          domain that is not verified or secured by Fyndr, do
                          you want to continue ?
                        </div>
                      }
                      onConfirm={() => {
                        if (!website.includes("https://www.fyndr.us/")) {
                          window.open(`https://${website}`);
                        } else {
                          window.open(`${website}`);
                        }
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        className="offer_list_contactList"
                        type="text"
                        icon={
                          <FaGlobe
                            className="searchBIZ_Faicons"
                            style={{
                              color: Theme.blue,
                            }}
                          />
                        }
                        style={{
                          color: Theme.blue,
                        }}
                      >
                        Website
                      </Button>
                    </Popconfirm>
                  </Col>
                )}
                {lat !== null && (
                  <Col xs={24} sm={4} md={4} lg={3} xl={4} xxl={4}>
                    <Button
                      className="offer_list_contactList"
                      onClick={() => {
                        setViewLocationModal(true);
                        setSelectedItem(item);
                      }}
                      type="text"
                      icon={
                        <FaMapMarkerAlt
                          className="searchBIZ_Faicons"
                          style={{
                            color: Theme.blue,
                          }}
                        />
                      }
                      style={{
                        color: Theme.blue,
                      }}
                    >
                      Location
                    </Button>
                  </Col>
                )}

                {Object.keys(businessWorkingHours)?.length > 0 && Object.keys(businessWorkingHours) !== null
                  ?
                  (
                    <Col xs={24} sm={4} md={9} lg={5} xl={5} xxl={4}>
                      <Button
                        className="offer_list_contactList"
                        onClick={() => {
                          setSelectedItem(item);
                          setViewWorkingHourModal(true);
                        }}
                        type="text"
                        icon={
                          <FaClock
                            className="searchBIZ_Faicons"
                            style={{
                              color: Theme.blue,
                            }}
                          />
                        }
                        style={{
                          color: Theme.blue,
                        }}
                      >
                        Working Hours
                      </Button>
                    </Col>
                  )
                  :
                  workingHours !== null && workingHours.length > 0 && (
                    <Col xs={24} sm={4} md={9} lg={5} xl={5} xxl={4}>
                      <Button
                        className="offer_list_contactList"
                        onClick={() => {
                          setSelectedItem(item);
                          setViewWorkingHourModal(true);
                        }}
                        type="text"
                        icon={
                          <FaClock
                            className="searchBIZ_Faicons"
                            style={{
                              color: Theme.blue,
                            }}
                          />
                        }
                        style={{
                          color: Theme.blue,
                        }}
                      >
                        Working Hours
                      </Button>
                    </Col>
                  )}
                <Col
                  xs={24}
                  sm={4}
                  md={2}
                  lg={4}
                  xl={2}
                  xxl={4}
                  className="likeIcon_Mobile"
                >
                  {liked === "yes" ||
                    likedList.filter((val) => val === bizid).length > 0 ? (
                    <Button
                      className="offer_list_contactList"
                      style={{
                        border: "none",
                        color: Theme.blue,
                      }}
                      onClick={() => likeBiz(bizid)}
                    >
                      <FaHeart
                        className="heart_length"
                        style={{
                          color: Theme.blue,
                        }}
                      />
                      {bizLikes[bizid]}
                    </Button>
                  ) : (
                    <Button
                      className="offer_list_contactList"
                      style={{
                        color: Theme.blue,
                        border: "none",
                      }}
                      onClick={() => likeBiz(bizid)}
                    >
                      <FiHeart
                        className="heart_nolength"
                        style={{
                          color: Theme.blue,
                        }}
                      />
                      {bizLikes[bizid]}
                    </Button>
                  )}
                </Col>
              </Row> */}
              {/* shows buttons of viewstore and active offers  */}

              <Row
                className="bizHome_buttons"
                align={isMobile() ? "middle" : "middle"}
                justify={isMobile() ? "center" : ""}
              >
                <Col xs={24} md={14} lg={12} className="bizHome_activebtn">
                  {/* {hasOffers && ( */}
                  <Button
                    disabled={hasOffers === false}
                    style={{
                      width: isMobile() ? "100%" : "98%",
                      // marginRight:'5rem',
                      color: hasOffers && "#3F9AF7",
                      borderRadius: "0.4rem",
                      borderColor: hasOffers && "#3F9AF7",
                      padding: "0px"
                    }}
                    onClick={() => {
                      console.log("campaigns total>>", locOffers[objid]?.activeCampaignCount)
                      if (locOffers[objid]?.activeCampaignCount > 1) {
                        props.history.push("/offers", {
                          locQRId: item.qrid,
                        })
                      } else {
                        props.history.push(
                          `/offer-details/${item?.bizName?.replace(/[.\W]+/g, "-")}/${locOffers[objid]?.cmpnQrCode
                          }`,
                          {
                            item,
                            liked,
                            locQRId: item.qrid,
                          }
                        )
                      }

                    }
                    }
                  // icon={
                  //   <FiTag
                  //     style={{
                  //       fontSize: "1.5rem",
                  //       // color: Theme.blue,
                  //       marginRight: ".5rem",
                  //     }}
                  //   />
                  // }
                  // type="primary"
                  >
                    {console.log("locOffers", locOffers[objid])}
                    {locOffers[objid]?.count}
                    {locOffers[objid]?.count > 1 ? " Active Offers & Events" : " Active Offer & Event"}
                  </Button>
                  {/* )} */}
                </Col>
                <Col xs={24} md={10} lg={12}>
                  {/* {catalogueId !== null && ( */}
                  <Button
                    disabled={catalogueId == null}
                    style={{
                      width: isMobile() ? "100%" : "98%",
                      color: catalogueId && "#3F9AF7",
                      borderRadius: "0.4rem",
                      borderColor: catalogueId && "#3F9AF7",
                    }}
                    onClick={() => getCategories(item.objid)}
                  // icon={
                  //   <MdBusiness
                  //     style={{
                  //       fontSize: "1.5rem",
                  //       // color: Theme.blue,
                  //       marginRight: ".5rem",
                  //     }}
                  //   />
                  // }
                  // type="primary"
                  >
                    {" "}
                    View Store
                  </Button>
                  {/* )} */}
                </Col>
              </Row>
            </Col>
          </Row>
        </Card >
      </div >
    ) : null;
  };
  const renderNoDataFound = () => {
    return (
      <div className="bizHome_render-nodata">
        <h5>No offers found ...</h5>
      </div>
    );
  };
  return (
    <div className="home">
      {/* <HelmetFyndr /> */}
      <div className={isMasquerade ? "bg-danger-fyndr" : "bg-info"}>
        {/* <div className="bizHome_header"> */}
        <div>
          {(section > 50 || !isMobile() && !isTab()) && (
            <div
              style={{
                float: isMobile() ? "none" : "left",
                paddingLeft: "5vh",
              }}
            >
              <Logo />
            </div>
          )}
          <div>
            {isOption && (
              <Row style={{ padding: "0 1rem" }} align="middle">
                <Col offset={isMobile() ? 0 : 1} xs={20} md={16} lg={11}>
                  <Row gutter={10}>
                    <Col xs={4} sm={4} md={0}>
                      <Logo collapsed={true} />
                    </Col>
                    <Col
                      span={10}
                      xs={14}
                      sm={12}
                      md={10}
                      xl={12}
                      lg={12}
                      xxl={10}
                      align={"middle"}
                    >
                      <Input
                        className="bizHome_search-store"
                        value={searchStr}
                        onChange={async (event) => {
                          setIsCategory(false);
                          await setSearchStr(event.target.value);


                        }}
                        onPressEnter={() => {
                          setCategories("");
                          fetchMore(true);
                        }}
                        style={{
                          height: "46px",
                        }}
                        prefix={<SearchOutlined className="prefix" />}
                        placeholder="Search Offers, Events & Businesses"
                      />
                    </Col>
                    <Col
                      span={8}
                      xs={0}
                      sm={0}
                      md={10}
                      xl={12}
                      lg={12}
                      xxl={14}
                    >
                      <GooglePlacesAutocomplete
                        minLength={4}
                        selectProps={{
                          onChange: (value, e) => {
                            if (
                              value.value === "myLocation" ||
                              value.label == "Current Location"
                            ) {
                              fetchIpAddress();
                            } else {
                              getCoordinates(value);
                            }
                          },

                          defaultOptions: [
                            {
                              label: "Current Location",
                              value: `${addressName}`,
                            },
                          ],
                          placeholder: `${addressName
                            ? isMobile()
                              ? addressName.replace(/(.{18})..+/, "$1…")
                              : addressName.replace(/(.{30})..+/, "$1…")
                            : "Location"
                            }`,
                          styles: {
                            dropdownIndicator: (base) => ({
                              ...base,
                              backgroundImage: `url(${googlelocations})`,
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center",
                              backgroundSize: "contain",
                              cursor: "pointer",
                              width: "24px",
                              height: "46px",
                              margin: "0px 12px",
                              transition: "transform 0.5s ease-in-out",
                            }),
                          },
                          className: "bizHomeDrop_input",
                        }}
                      />
                    </Col>
                    <Col
                      align="center"
                      xs={3}
                      md={0}
                      style={{ alignSelf: "center" }}
                    >
                      <i
                        onClick={() => {
                          setOpenBottomSheet(true);
                        }}
                        className="fa fa-map-marker"
                        aria-hidden="true"
                        style={{ fontSize: "1.5rem", color: "#fff" }}
                      ></i>
                    </Col>
                    <Col xs={3} md={0} style={{ alignSelf: "center" }}>
                      {isfilterOn ? (
                        <AppstoreFilled
                          onClick={() => {
                            setIsFilter(!isfilterOn);
                          }}
                          className="bizHome_appstore-icon"
                        />
                      ) : (
                        <AppstoreOutlined
                          onClick={() => {
                            setIsFilter(!isfilterOn);
                          }}
                          className="bizHome_appstore-icon"
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={0}
                  md={0}
                  lg={0}
                  xl={entityType === ROLE_FYNDR && !indvid ? 4 : 8}
                  xxl={8}
                  offset={entityType === ROLE_FYNDR && !indvid ? 6 : 3}
                >
                  <TopMenu menuUp={false} {...props} />
                </Col>
                <Col
                  xs={3}
                  md={1}
                  lg={entityType !== ROLE_FYNDR && indvid ? 10 : 10}
                  xl={0}
                  xxl={0}
                  offset={
                    entityType !== ROLE_FYNDR && indvid && isMobile() ? 1 : 0
                  }
                >
                  <TopMenu {...props} />
                </Col>
              </Row>
            )}
          </div>
        </div>
      </div>
      <div className="section__main">
        <div
          style={{ textAlign: "center", minWidth: "100%", paddingTop: "60px" }}
          className={isfilterOn ? `filter__color` : ""}
        >
          {/* after opening any one type of store the column given where map and types of deals is present */}
          <Row justify="center" className="bizHome_selection-row" gutter={[20]}>
            <Col
              span={12}
              xs={isfilterOn ? 24 : 0}
              md={6}
              className="bizHome_selection_col"
              align={"left"}
            >
              <Row style={{ marginBottom: "2rem" }}>
                <Col className="bizHome_map" xs={0} md={24}>
                  <h5>Search Deals On Map </h5>

                  <div className="map_view_new">
                    <GoogleMap
                      zoom={miles > 50 ? 8 : 11}
                      defaultCenter={{
                        lat: lat,
                        lng: lng,
                      }}
                    >
                      {console.log("itemlist", itemlist)}
                      {Array.isArray(itemlist) &&
                        itemlist.map((item, key) => {
                          return (
                            <MarkerGoogle
                              history={props.history}
                              myKey={key}
                              item={item}
                            />
                          );
                        })}
                    </GoogleMap>
                  </div>
                </Col>
              </Row>

              <div className="bizHome_range">
                <h5> {`Range (${miles} miles)`} </h5>
                <div style={{ paddingTop: "1.8rem" }}>
                  <Slider
                    railStyle={{ backgroundColor: "#AAA", height: ".2rem" }}
                    trackStyle={{
                      backgroundColor: Theme.blue,
                      height: ".2rem",
                    }}
                    defaultValue={miles}
                    min={20}
                    max={100}
                    marks={{
                      20: {
                        style: {
                          paddingTop: "10px",
                          paddingLeft: "50px",
                          whiteSpace: "nowrap",
                        },
                        label: "20 Miles",
                      },
                      100: {
                        style: {
                          paddingTop: "10px",
                          paddingRight: "50px",
                          whiteSpace: "nowrap",
                        },
                        label: "100 Miles",
                      },
                    }}
                    onAfterChange={(value) => {
                      setMiles(value);
                    }}
                  />
                </div>
              </div>
              {/* <h5>Type of deals</h5> */}
              <Checkbox.Group
                style={{ width: "100%" }}
                // defaultValue={subTypeDeals}
                value={subTypeDeals}
                onChange={onChangeType}
              ></Checkbox.Group>

              <Divider />
              <h5>Category</h5>
              <Radio.Group
                style={{ width: "100%" }}
                value={categories}
                defaultValue={categories}
                onChange={onChangeCategories}
              >
                {categoryListArr?.map((item, key) => (
                  <Row style={{ marginTop: "1rem" }} key={key}>
                    <Radio value={item.value}> {item.label}</Radio>
                  </Row>
                ))}
              </Radio.Group>
              <Divider />
            </Col>

            <Col
              ref={scrollRef}
              align="center"
              className="hidden-scroll"
              style={{
                marginTop: "0",
                // maxHeight: !isMobile() && "200vh",
                // overflow: "auto",
                padding: isMobile() ? "1rem 3rem" : "1rem 2rem",
                background: "#F5F5F5",
              }}
              span={12}
              xs={!isfilterOn ? 24 : 0}
              md={18}
            // onScroll={(e) => {
            //   setSection(e.target.scrollTop);
            // }}
            >
              {console.log("itemlist", itemlist)}{" "}
              {itemlist?.length > 0 ? (
                <>
                  <AnimateSharedLayout>
                    {itemlist?.map((item, key) => {
                      return <ItemCard item={item} key={key} />;
                    })}
                  </AnimateSharedLayout>
                  {isLoading == true && <Loader />}
                </>
              ) : (
                renderNoDataFound()
              )}
            </Col>
          </Row>
        </div>
      </div>

      <Modal
        visible={bottomSheet}
        onOk={() => setOpenBottomSheet(false)}
        onCancel={() => {
          setOpenBottomSheet(false);
        }}
        closable={false}
        centered
      >
        <div className="bizHome_location-modal">
          <h2 style={{ fontWeight: "600" }}>Please select the location</h2>
          <GooglePlacesAutocomplete
            selectProps={{
              onChange: (value) => {
                if (
                  value.value === "myLocation" ||
                  value.label == "Current Location"
                ) {
                  fetchIpAddress();
                } else {
                  getCoordinates(value);
                }
              },
              styles: {
                input: (provided) => ({
                  ...provided,
                  borderColor: Theme.blue,
                }),
              },
              defaultOptions: [
                {
                  label: "Current Location",
                  value: "myLocation",
                },
              ],
              placeholder: "Location",
            }}
          />
        </div>
      </Modal>

      <Modal
        onOk={() => setViewWorkingHourModal(false)}
        onCancel={() => setViewWorkingHourModal(false)}
        width={350}
        zIndex={1500}
        visible={viewWorkingHourModal}
        title="Working Hours"
      >
        {selectedItem && Object.keys(selectedItem?.businessWorkingHours).length > 0
          ?
          Object.entries(selectedItem?.businessWorkingHours).sort((a, b) => {
            const daysOrder = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
            return daysOrder.indexOf(a[0]) - daysOrder.indexOf(b[0]);
          }).map((row) => {
            const day = row[0];
            const time = row[1]

            const newSlots = time.map((item) => {
              const startMoment = moment(item.split("-")[0], 'HH:mm');
              const endMoment = moment(item.split("-")[1], 'HH:mm');
              const formattedStartTime = startMoment.format('hh:mm A');
              const formattedEndTime = endMoment.format('hh:mm A');
              return `${formattedStartTime} to ${formattedEndTime}`
            })
            return (
              <div key={day} style={{ marginBottom: "1rem" }}>
                <h5 style={{ width: 150 }}>{day}</h5>
                <div>
                  {Array.isArray(newSlots) &&
                    newSlots.map((val, i) => {
                      return (
                        <h6 key={i} style={{ marginBottom: 5 }}>
                          {val}
                        </h6>
                      );
                    })}
                </div>
                <Divider />
              </div>
            );
          })

          :
          selectedItem?.workingHours && selectedItem?.workingHours.trim() !== "" &&
          selectedItem?.workingHours?.split(";").map((row) => {
            const { day, tm } = getTiming12(row);
            return (
              <div key={day} style={{ marginBottom: "1rem" }}>
                <h5 style={{ width: 150 }}>{day}</h5>
                <div>
                  {Array.isArray(tm) &&
                    tm.map((val) => {
                      return (
                        <h6 key={val} style={{ marginBottom: 5 }}>
                          {val}
                        </h6>
                      );
                    })}
                </div>
                <Divider />
              </div>
            );
          })}
      </Modal>



      <Modal
        onOk={() => setViewLocationModal(false)}
        onCancel={() => setViewLocationModal(false)}
        width={"33%"}
        visible={viewLocationModal}
        title="Location"
      >
        <div className="map_view">
          <GoogleMap
            zoom={11}
            defaultCenter={{
              lat: selectedItem?.lat,
              lng: selectedItem?.lng,
            }}
          >
            <MarkerGoogle
              history={props.history}
              myKey={""}
              item={selectedItem}
            />
          </GoogleMap>
          <h6
            style={{ marginTop: "1rem" }}
          >{`${selectedItem?.addressLine1}, ${selectedItem?.city}, ${selectedItem?.postalCode}`}</h6>
        </div>
      </Modal>
      <Footer />
    </div>
  );
};
export default LandingPage;

