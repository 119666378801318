import React, { useState } from "react";
import { Card, Typography } from "antd";
import moment from "moment";
const { Text } = Typography;
export default function AllReviewCard(props) {
  const [expandedComments, setExpandedComments] = useState([]);
  const [expandedReplies, setExpandedReplies] = useState([]);

  const toggleExpandComment = (index) => {
    setExpandedComments((prevExpandedComments) => {
      const newExpandedComments = [...prevExpandedComments];
      newExpandedComments[index] = !newExpandedComments[index];
      return newExpandedComments;
    });
  };

  const toggleExpandReply = (index) => {
    setExpandedReplies((prevExpandedReplies) => {
      const newExpandedReplies = [...prevExpandedReplies];
      newExpandedReplies[index] = !newExpandedReplies[index];
      return newExpandedReplies;
    });
  };

  const LongText = (text, createdDt, index, isReply) => {
    const MAX_LENGTH = 57;
    const isLongText = text?.length > MAX_LENGTH;
    const displayText = isLongText
      ? `${text.substring(0, MAX_LENGTH)}...`
      : text;

    const isExpanded = isReply
      ? expandedReplies[index]
      : expandedComments[index];

    const toggleExpand = isReply ? toggleExpandReply : toggleExpandComment;

    return (
      <div>
        <span
          style={{
            color: "#AAAAAA",
            fontSize: "14px",
            fontWeight: "400",
            fontFamily: "Roboto",
            fontStyle: "normal",
          }}
        >
          {moment.utc(createdDt).local().format("YYYY-MM-DD HH:mm A")}
        </span>
        <br />
        <Text>
          {isExpanded ? text : displayText}
          {isLongText && (
            <Text
              style={{ color: "#1890ff", cursor: "pointer" }}
              onClick={() => toggleExpand(index)}
            >
              {isExpanded ? " Read Less" : "Read More"}
            </Text>
          )}
        </Text>
      </div>
    );
  };
  return (
    <Card
      className={props.className}
      title={props.title}
      bordered={props.bordered}
      style={props.style}
    >
      <span>{props.span}</span>
      <div className={props.overflowClassName}>
        {props?.reviewComment?.length === 0 ? (
          <div
            style={{
              backgroundColor: "#FFF",
              width: "100%",
              height: "208px",
              borderRadius: "10px",
              overflow: "hidden",
            }}
          >
            <Text
              style={{
                color: "#CCC",
                fontSize: "16px",
                display: "flex",
                justifyContent: "center",
                marginTop: "95px",
                lineHeight: "normal",
                overflow: "hidden",
              }}
            >
              {props.title !== "Report History" && "No Reply added"}
            </Text>
          </div>
        ) : (
          props.reviewComment?.map((data, index) => {
            return (
              <div className={props.textClassNameForPopUp}>
                <Text
                  style={{
                    color: "#4D4D4D",
                    fontSize: "16px",
                    lineHeight: "normal",
                  }}
                >
                  {props.title === "Report History"
                    ? LongText(
                        data?.reportedComment,
                        data?.createdDt,
                        index,
                        true
                      )
                    : LongText(data?.reply, data?.createdDt, index, true)}
                </Text>
              </div>
            );
          })
        )}
      </div>
    </Card>
  );
}
