/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Space,
  Select,
  Input,
  Button,
  DatePicker,
  message,
  Switch,
  Divider,
  Popover,
} from "antd";

import UserAction from "../../actions";
import moment from "moment";
import ModifierRow from "./CatalogueModifier";
import { ConfirmationModal } from "../../components";
import "../../sass/styles/fyndr/_catalog-table.scss";
import { Theme } from "../../common";
const { Option } = Select;
const PriceRow = ({
  options,
  callback,
  key,
  item,
  bizid,
  price,
  slno,
  status,
  availableFrom,
  categoryId,
  catalogueId,
  catalogueItemId,
  editPress,
  hideEdit,
  modifiers,
  ModiferOptions,
  currencySymbol,
  callbackModifier,
  deletePress,
  itemClickedCallback,
  isOpen,
}) => {
  const [payload, setPayload] = useState({
    objid: item?.objid,
    price: price,
    slno: slno,
    status: status,
    availableFrom: moment(availableFrom),
  });
  const [valuechanged, setValueChanged] = useState(false);
  const [showModifier, setShowModifier] = useState(isOpen);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    ModiferOptions.sort((a, b) => a.modName.localeCompare(b.modName));
  }, [ModiferOptions]);
  useEffect(() => {
    if (slno != payload.slno) {
      setValueChanged(true);
    }
  }, [slno]);
  const handleChange = (value) => {
    setPayload({ ...payload, objid: value });
  };
  const handleSave = async () => {
    if (payload.objid === undefined) {
      message.error("Please select an item");
      return;
    }
    if (payload.price === undefined) {
      message.error("Price can not be empty");
      return;
    }

    let dispatchPayload = {
      bizid,
      catalogueId,
      categoryId,
      itemId: payload.objid,
      price: payload.price,
      slno: payload.slno,
      status: payload.status,
      availableFrom: payload.availableFrom,
      objid: catalogueItemId ? catalogueItemId : null,
    };

    let resp = await UserAction.addCatalogueItem(dispatchPayload);
    resp.success ? message.success(resp.message) : message.error(resp.message);
  };
  const onChange = (value) => {
    if (value) {
      setPayload({ ...payload, status: "active" });
    } else {
      setPayload({ ...payload, status: "inactive" });
    }
    if (item) {
      setValueChanged(true);
    }
  };
  const onChangeDate = (value) => {
    setPayload({ ...payload, availableFrom: value ? moment(value) : moment() });
    if (item) {
      setValueChanged(true);
    }
  };
  const handlePriceChange = ({ target: { value } }) => {
    setPayload({ ...payload, price: value });
    if (item) {
      setValueChanged(true);
    }
  };
  const handleOrderChange = ({ target: { value } }) => {
    setPayload({ ...payload, slno: Number(value) });
    if (item) {
      setValueChanged(true);
    }
  };
  const handleDeleteItem = async (item) => {
    let dispatchPayload = {
      bizid,
      objid: item.objid,
      catalogueItemId: catalogueId,
      modifierId: item.modifier.objid,
      price: item.price,
    };

    let resp = await UserAction.deleteCatalogueModifier(dispatchPayload);
    resp.success ? message.success(resp.message) : message.error(resp.message);
    callback();
  };
  const CONTENT = (
    <div>
      <ol>
        <li>
          <p>
            <span style={{ fontWeight: "bold" }}>Addon: </span>Multiple
            modifiers of type "addon" can be added while ordering the item.
          </p>
        </li>
        <li>
          <p>
            <span style={{ fontWeight: "bold" }}>Whole: </span>Only one modifier
            of type "whole" can be selected while making the order.
          </p>
        </li>
      </ol>
    </div>
  );
  return (
    <div style={{ marginTop: "2rem" }} key={catalogueId}>
      <Row gutter={[20, 20]} style={{ width: "100%" }}>
        <Col
          xs={1}
          sm={1}
          md={1}
          lg={1}
          xl={1}
          xxl={1}
          style={{ display: "flex", alignItems: "center" }}
        >
          {item !== undefined ? (
            <i
              onClick={() => {
                setShowModifier(!showModifier);
                itemClickedCallback(payload.objid);
              }}
              style={{ cursor: "pointer", color: Theme.blue }}
              class={`fa fa-chevron-circle-${showModifier ? "up" : "down"}`}
            ></i>
          ) : (
            <i
              style={{ cursor: "pointer", color: Theme.greyLight }}
              class={`fa fa-chevron-circle-down`}
            ></i>
          )}
        </Col>
        <Col xs={1} sm={2} md={1} lg={2} xl={2} xxl={2}>
          <Input
            disabled
            bordered={false}
            style={{ color: "#000" }}
            onChange={(e) => handleOrderChange(e)}
            value={payload?.slno}
          />
        </Col>
        <Col xs={5} sm={5} md={5} lg={5} xl={5} xxl={5}>
          <Select
            disabled={item ? true : false}
            defaultValue={payload?.objid}
            style={{ width: "100%" }}
            onChange={handleChange}
          >
            {options &&
              options.map((item, key) => {
                return (
                  <Option key={key} value={item.objid}>
                    {item.name}
                  </Option>
                );
              })}
          </Select>
        </Col>
        <Col xs={3} sm={3} md={5} lg={3} xl={3} xxl={3}>
          <Input
            onChange={(e) => handlePriceChange(e)}
            value={payload?.price}
            prefix={
              <span style={{ color: Theme.greyLight }}>{currencySymbol}</span>
            }
          />
        </Col>

        <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
          <DatePicker
            value={
              payload.availableFrom !== undefined ? payload.availableFrom : ""
            }
            onChange={onChangeDate}
            allowClear={true}
          />
        </Col>
        <Col xs={1} sm={2} md={4} lg={2} xl={2} xxl={2}>
          <Switch
            defaultChecked={
              payload.status
                ? payload.status === "inactive"
                  ? false
                  : true
                : true
            }
            onChange={onChange}
          />
        </Col>
        <Col xs={3} sm={3} md={2} lg={3} xl={3} xxl={3}>
          {item && (
            <Input
              bordered={false}
              style={{ color: "#000" }}
              value={item?.unit.charAt(0).toUpperCase() + item?.unit.slice(1)}
              disabled
              className="unitInput"
            />
            // <p>{item?.unit.charAt(0).toUpperCase() + item?.unit.slice(1)}</p>
          )}
        </Col>
        <Col
          xs={4}
          sm={3}
          md={4}
          lg={4}
          xl={4}
          xxl={4}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Space>
            {!hideEdit && (
              <i
                style={{
                  fontSize: "1.5rem",
                  color: Theme.blue,
                  cursor: "pointer",
                  paddingRight: "1vw",
                }}
                onClick={editPress}
                class="fas fa-edit"
              />
            )}
            {!hideEdit && (
              <i
                style={{
                  fontSize: "1.5rem",
                  color: Theme.red,
                  cursor: "pointer",
                }}
                onClick={() => setVisible(true)}
                class="fas fa-trash-alt"
              ></i>
            )}
            {(item === undefined || valuechanged) && (
              <Button
                type="primary"
                onClick={() => {
                  handleSave();
                }}
                className="catalogue_btn_save_addmore"
              >
                {valuechanged ? "Update" : "Save and add more"}
              </Button>
            )}
          </Space>
        </Col>
      </Row>
      {showModifier && (
        <Row align="middle" justify="center" style={{ margin: "1rem" }}>
          <Col style={{ background: "#f8f8f8", padding: "1rem" }} span={22}>
            <Row
              align="middle"
              justify="space-between"
              style={{ width: "100%" }}
            >
              <Col span={8}>
                <h6>Modifier</h6>
              </Col>
              <Col span={5}>
                <h6>Price</h6>
              </Col>
              <Col span={5}>
                <Row>
                  <Space>
                    <h6>Type</h6>
                    <Popover content={CONTENT} title="Modifier Type">
                      <i
                        style={{ color: Theme.blue }}
                        className="fa fa-info-circle"
                        aria-hidden="true"
                      />
                    </Popover>
                  </Space>
                </Row>
              </Col>
              <Col span={5}>
                <h6>Actions</h6>
              </Col>
            </Row>
            {Array.isArray(modifiers) &&
              modifiers.map((item, key) => {
                return (
                  <ModifierRow
                    callback={() => {}}
                    options={ModiferOptions}
                    item={item}
                    currencySymbol={currencySymbol}
                    bizid={bizid}
                    catalogueId={catalogueItemId}
                    editPress={() => {
                      callbackModifier(item);
                    }}
                    deletePress={() => handleDeleteItem(item)}
                  />
                );
              })}
            <Divider />
            <ModifierRow
              callback={() => {}}
              options={ModiferOptions}
              hideEdit
              currencySymbol={currencySymbol}
              catalogueItemId={catalogueItemId}
              bizid={bizid}
            />
          </Col>
        </Row>
      )}
      <ConfirmationModal
        title={`Delete`}
        visible={visible}
        description={"Are you sure, you want to delete it ?"}
        onConfirm={() => {
          try {
            deletePress();
          } catch (error) {
            console.log(error);
          } finally {
            setVisible(false);
          }
        }}
        onCancel={() => setVisible(false)}
      />
    </div>
  );
};

export default PriceRow;
