const Theme = {
  blue: "#3F9AF7",
  yellow: "#FFE606",
  darkblue: "#2764CD",
  lightblue: "#F2F6FF",
  pink: "#FF879C",
  lightGreen: "#1DCFC2",
  greyGreen: "#edf7f6",
  grey: "#c9c9c9",
  greyLight: "#bfbfbf",
  greydark: "#65717c",
  orange: "#FB9357",
  paleWhite: "#fbfbfb",
  darkBlue: "#0B4E92",
  blackD9: "#000000D9",
  blue3B: "#3B5999",
  white: "#fff",
  success: "#ADFAB0",
  green: "#2DCE89",
  red: "#ff471a",
  overlay: "rgba(210, 210, 210, 0.3)",
  header: "#3F9AF7",
};
export default Theme;