import { Actions } from "../common";
const { PREVIEW_ITEM_UPDATE, LOG_OUT } = Actions;
const INITIAL_STATE = {
  preview: null,
};

const PreviewReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PREVIEW_ITEM_UPDATE: {
      return {
        ...state,
        preview: action.payload,
      };
    }
    case LOG_OUT: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default PreviewReducer;
