import React from "react";

export default function Video({ videoUri, width, height, aspectRatio }) {
  return (
    <video
      controls
      width={width}
      height={height}
      style={{ aspectRatio: aspectRatio ? aspectRatio : "2:1" }}
      controlsList="nodownload"
      disablePictureInPicture
    >
       <source src={videoUri} type="video/mp4" />
    </video>
  );
}
