import { bindActionCreators } from "redux";
import { store } from "../store";
import { userActions } from "./user_actions";
import { coreActions } from "./core_actions";
import { campaignActions } from "./campaign_actions";
import { finActions } from "./fin_actions";
import { CatalogueActions } from "./catalogue_actions";
import {adminActions} from "./admin_actions";

export const debug = true;
export const actions = {
  ...userActions,
  ...coreActions,
  ...campaignActions,
  ...finActions,
  ...CatalogueActions,
  ...adminActions
};

export default bindActionCreators(actions, store.dispatch);
