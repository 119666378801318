import { Col, Row } from "antd";
import React from "react";
import AboutUsLeft from "../AboutUsNew/about-us-left";
import AboutUsRight from "../AboutUsNew/about-us-right";
import Photo1 from "../../assets/images/AboutUs/Manage-multiple-stores.png";
import Photo2 from '../../assets/images/AboutUs/appointments2.png'
import Photo3 from "../../assets/images/AboutUs/Customer-Interaction.png";
import Photo4 from "../../assets/images/AboutUs/Qrcode.png";
import Photo5 from "../../assets/images/AboutUs/Campaigns.png";

export default function ForFyndr() {
  const dataArray = [
    {
      title: "Appointments",
      content: `
      By utilizing Fyndr, you will be able to manage all of your appointments in one place with our innovative booking feature. No more missed opportunities or scheduling headaches. Fyndr empowers you to stay organized and in control of your business schedule like never before. 
            `,
      image: Photo2,
    },
    {
      title: "Manage Multiple Locations",
      content: `
      With Fyndr's powerful business management tools, overseeing multiple locations of your business is a breeze. Tailor campaigns to specific locations to target your audience effectively, boosting engagement and driving sales where it matters most. Fyndr makes it seamless to manage appointments and ensure smooth operations and customer service. 
            `,
      image: Photo1,
    },
    {
      title: "Get Your QR code",
      content: `
      With Fyndr’s innovative QR code feature, your business is front and center, capturing the attention of curious customers. Imagine your personalized QR code popping up everywhere – on billboards, street signs, social media ads – you name it. Wherever it's seen, all it takes is a quick scan for potential customers to be whisked away to your business's page, ready to explore what you have to offer.  
              `,
      image: Photo4,
    },
    {
      title: "Campaigns",
      content: `
      Imagine you've got multiple locations, each with its own unique vibe and customer base. With Fyndr, you can create different campaigns for each location, so you can tailor your promotions to match the preferences of each audience. Whether you're offering a special deal, hosting an event, or launching a new product, you can easily promote it to the right people at the right place, maximizing your impact to drive more sales.  
              `,
      image: Photo5,
    },
  ];
  return (
    <Row>
      <Col>
        {dataArray.map((item, index) => {
          const isEvenIndex = index % 2 === 0;

          if (isEvenIndex) {
            return (
              <AboutUsLeft
                key={index}
                title={item.title}
                content={item.content}
                image={item.image}
                index={index}
                totalItems={dataArray.length}
              />
            );
          } else {
            return (
              <AboutUsRight
                key={index}
                title={item.title}
                content={item.content}
                image={item.image}
                buttonText={item.buttonText}
                index={index}
                totalItems={dataArray.length}
              />
            );
          }
        })}
      </Col>
    </Row>
  );
}
