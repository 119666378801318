import React from "react";
import DropdownComponent from "../Dashboard/DropdownComponent";
import { Row, Col, Table, Breadcrumb, Divider, DatePicker } from "antd";
import ContainerWrapper from "../../components/ContainerWrapper";
import HelmetFyndr from "../HelmetFyndr";
import getEnvVars from "../../environment";
const { RangePicker } = DatePicker;
const web = getEnvVars();
export default function Dispute(props) {
  const status = [
    { value: "INITIATED", label: "Initiated" },
    {
      value: "SETTLED_WITH_CUSTOMER_PAYMENT",
      label: "Settled With Customer Payment",
    },
    { value: "SETTLED_WITHOUT_PAYMENT", label: "Settled Without Payment" },
    { value: "CANCELED", label: "Canceled" },
    { value: "DISPUTED", label: "Disputed" },
  ];

  return (
    <ContainerWrapper title={"Dispute"}>
      {
        <HelmetFyndr
          title="Dispute"
          imgUrl={""}
          url={`${web}/dispute`}
          descr="dispute"
        />
      }
      <div style={{ marginBottom: "2rem" }}>
        <Row justify="space-between" gutter={[10, 8]}>
          <Col style={{ width: "70%" }}>
            <Row gutter={[8, 6]}>
              <Col style={{ width: "30%" }} xs={24} sm={24} md={22} lg={8}>
                <DropdownComponent
                  placeholder="Status"
                  style={{ minWidth: "100%" }}
                  options={status}
                  onChange={props.onChange}
                  mode="multiple"
                />
              </Col>
              <Col style={{ width: "40%" }} xs={24} sm={22} md={22} lg={8}>
                {props.DropdownComponent}
              </Col>
            </Row>
          </Col>
          <Col>
            <RangePicker
              size="middle"
              style={{ height: "36px" }}
              onChange={props.handleOnChange}
              allowClear={true}
            />
          </Col>
        </Row>
      </div>
      <div>
        <Table
          className="admin-table"
          bordered
          pagination={props.pagination}
          columns={props.columns}
          dataSource={props.dataSource}
          size="medium"
          onChange={props.handleTableChange}
        />
      </div>
    </ContainerWrapper>
  );
}
