import { createStore, applyMiddleware, compose } from "redux";
import ReduxThunk from "redux-thunk";
import { createLogger } from "redux-logger";
import reducers from "../reducers";
import { composeWithDevTools } from "remote-redux-devtools";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "authType",
  storage: storage,

  whitelist: ["auth", "indv","catalogue", "preview",'admin'], // which reducer want to store

};
const pReducer = persistReducer(persistConfig, reducers);

function configureStore(initialState, reduxLogger, reducerP) {
  const middleWares = [ReduxThunk];
  const addRemoteDevTools = reduxLogger;
  const logger = createLogger({
    predicate: (getState, action) => true,
    collapsed: true,
    diff: true,
    colors: {
      title: () => "#4259f4",
      prevState: () => "#ff9090",
      action: () => "#03a9f4",
      nextState: () => "#4caf50",
      error: () => "#f20404",
    },
  });
  if (reduxLogger) {
    middleWares.push(logger);
  }
  const enhancer = addRemoteDevTools
    ? // const enhancer =
      composeWithDevTools(applyMiddleware(...middleWares))
    : compose(applyMiddleware(...middleWares));
  const Store = createStore(
    reducerP ? reducerP : reducers,
    initialState,
    enhancer
  );
  return Store;
}
let useLogger = process.env.NODE_ENV === "development" ? true : false;
const store = configureStore({}, useLogger, pReducer);
const persistor = persistStore(store);
export { persistor, store };
