import React, { useEffect, useState } from "react";
import ContactLessPayment from "../../assets/images/AboutUs/ConactLessPayment.png";
import OnlineStore from "../../assets/images/AboutUs/Onlinestore.jpg";
import CampaignPromotions from "../../assets/images/AboutUs/CampaignPromotions.jpg";
import ManageAppointments from "../../assets/images/AboutUs/ManageAppointments.jpg";
import { Col, Row, Typography } from "antd";
import { isTab } from "../../common/util";
import HelmetFyndr from "../HelmetFyndr";
import getEnvVars from "../../environment";
const Text = Typography;
const web = getEnvVars();
const AboutUsPhoto = () => {
  useEffect(() => {
    handleGalleryHover(0);
  }, []);
  const imges = [
    {
      img: ContactLessPayment,
      title: "Contactless Payment",
      description:
        "Seamlessly manage payments, invoices, and orders, all in one place",
    },
    {
      img: OnlineStore,
      title: "Online Storefront ",
      description:
        "Create stunning online storefronts.",
    },
    {
      img: CampaignPromotions,
      title: " Promote Your Business ",
      description:
        "Promote your business deals and expand your reach",
    },
    {
      img: ManageAppointments,
      title: "Manage Appointments",
      description: "Oversee all your appointments in one place",
    },
  ];
  const [expandedIndex, setExpandedIndex] = useState(0);
  const handleGalleryHover = (index) => {
    if (!isMobile()) {
      setExpandedIndex(index);
    }
  };
  const handleMouseOver = (index) => {
    if (!isMobile() && index !== 0) {
      setExpandedIndex(index);
    }
  };

  const handleMouseOut = (index) => {
    if (!isMobile() && index !== 0) {
      setExpandedIndex(0);
    }
  };

  const isMobile = () => {
    return window.innerWidth <= 480;
  };

  const isLageScreen = () => {
    return window.innerWidth >= 1920;
  };
  const renderGalleryItem = (index, imageUrl, label1, label2) => {
    const isExpanded = index === expandedIndex && !isMobile();
    const isTabView = isTab();

    let width = "200px";
    let height = "400px";
    let expandedWidth = "450px";
    let expandedHeight = "400px";
    if (isTabView) {
      width = "30px";
      height = "300px";
    }
    if (isLageScreen()) {
      height = "550px";
      width = "300px";
      expandedHeight = "550px";
      expandedWidth = "550px";
    }
    const galleryStyle = {
      width: isExpanded ? expandedWidth : width,
      height: isExpanded ? expandedHeight : height,
      transition: "150ms ease-in-out",
    };

    const h3Style = {
      transform: isExpanded ? "rotate(0deg)" : "rotate(-90deg)",
      left: isTabView ? (isExpanded ? "2%" : "20%") : isExpanded ? "3%" : "25%",
      bottom: isExpanded ? "15%" : "0%",
      fontWeight:"700",
      fontSize:"25px",
      whiteSpace: "nowrap",
      position: "absolute",
      textAlign: "left",
      borderRadius: "6px",
      padding: isExpanded ? "0px" : "5px",
      transition: "transform 500ms ease-in-out",
    };
    const spanStyle1 ={
      background: isExpanded ?"transparent" : "rgba(37, 124, 219, 0.5)",
      borderRadius: "6px",
      padding:"5px"
    }
    const spanStyle2 ={
      backgroundColor: isExpanded ? "transparent" : "rgba(0, 0, 0, 0.1)",
      borderRadius: "6px",
      padding:"5px"
    }

    const textAboutUsStyle = {
      opacity: isExpanded ? 1 : 0,
      marginTop: isExpanded ? "342px" : "0px",
      marginLeft: isExpanded ? "13px" : "0px",
      color: "#ffffff",
    };
    const imageData = imges[index];
    console.log("imageData", imageData);
    console.log(isExpanded);

    if (index === 0 && isExpanded) {

      textAboutUsStyle.opacity = 1;
      textAboutUsStyle.marginTop = "342px";
      textAboutUsStyle.marginLeft = "11px";
    }
    return (
      <div
        key={index}
        className="gallery_"
        style={{
          ...galleryStyle,
          backgroundImage: `url(${imageUrl})`,
        }}
        onMouseOver={() => handleMouseOver(index)}
        onMouseOut={() => handleMouseOut(index)}
      >
         {<HelmetFyndr
            title="About Us"
            // imgUrl={imgLink}
            url={`${web}/aboutus`}
            descr="About Us"
          />}
        <div className="mobileAboutUs_photoCrousal"></div>
        <div className="transparent-box">
          <h3 style={h3Style}><span style={spanStyle1}>{label1} </span><span style={spanStyle2}> {label2}</span></h3>
        </div>
        <Text className="textAboutUs" style={textAboutUsStyle}>
          {imageData.description}
        </Text>
      </div>
    );
  };
  return (
    <>
      {!isMobile() ? (
        <div className="about">
          <div className="another">
            {renderGalleryItem(
              0,
              ContactLessPayment,
              "Contactless",
              "Payment"
            )}
            {renderGalleryItem(1, OnlineStore, "Online", "Storefront")}
            {renderGalleryItem(
              2,
              CampaignPromotions,
              "Promote",
              "Your Business"
            )}
            {renderGalleryItem(
              3,
              ManageAppointments,
              "Manage",
              "Appointments"
            )}
          </div>
        </div>
      ) : (
        <Row gutter={[10, 10]} style={{ padding: "14px" }}>
          {imges.map((data, index) => (
            <Col
              xs={24}
              className="photoCarousal"
              key={index}
              style={{
                backgroundImage: `url(${data.img})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            >
              {" "}
              <div className="mobileAboutUs_photoCrousal"></div>
              <div className="photoText">
                <div>
                  <Text
                    style={{
                      fontSize: "16px",
                      color: "#fff",
                      paddingBottom: "5px",
                    }}
                  >
                    {data.title}
                  </Text>{" "}
                  <Text style={{ color: "#fff" }}>{data.description}</Text>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      )}
    </>
  );
};
export default AboutUsPhoto;