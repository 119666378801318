import APPCONFIG from '../constants/appConfig';
import { Actions } from "../common";
const { TOGGLE_OFFCANVAS_NAV, CHANGE_SIDENAV_WIDTH, TOGGLE_OFFCANVAS_MOBILE_NAV, TOGGLE_COLLAPSED_NAV, TOGGLE_CREATE_CAMPAIGN ,NAV_TAB} = Actions;

const initialSettings = APPCONFIG.settings;

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case TOGGLE_OFFCANVAS_NAV:
      return {
        ...state,
        offCanvasNav: action.isOffCanvasNav
      };
    case CHANGE_SIDENAV_WIDTH:
      return {
        ...state,
        sidenavWidth: action.sidenavWidth
      };
    case TOGGLE_OFFCANVAS_MOBILE_NAV:
      return {
        ...state,
        offCanvasMobileNav: action.isOffCanvasMobileNav
      };
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        collapsedNav: action.isCollapsedNav
      };
    case TOGGLE_CREATE_CAMPAIGN:
      return {
        ...state,
        collapsedCreateCampaign: action.value
      };
    case NAV_TAB:
      return {
        ...state,
        activeTab: action.value
      };
    default:
      return state;
  }
}

export default settings;
