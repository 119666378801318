import React from 'react'
import { Button, Row, Col, Modal } from "antd"
export default function ModalPaymentMethod(props) {
    return (
        <div>
            <Modal
                title={props.title || "Payment Method"}
                centered
                width={400}
                visible={props.visible}
                className={props.className}
                footer={null}
                onCancel={props.onCancel}
            >
                <Row justify="center">
                    <h6>{props.text}</h6>
                </Row>
                <br />
                <Row justify="space-around" gutter={[10, 24]}>
                    <Col>
                        <Button type="primary" onClick={props.btnClick}>{props.btn1}</Button>
                    </Col>
                    <Col>
                        <Button type="primary" onClick={props.onClick}>{props.btn2}</Button>
                    </Col>
                </Row>
            </Modal>
        </div>
    )
}
