import React from "react";
import {
    Table,
    Button,
    Input,
    Row,
    Col,
    Space,
    DatePicker,
    message,
} from "antd";
import actions from "../../actions";
import DropdownComponent from "../../components/Dashboard/DropdownComponent";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import { useSelector, useDispatch } from "react-redux";
import { MINIMUM_SEARCH_LIMIT } from "../../common/config";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Actions } from "../../common";
import UserActions from "../../actions";
import InvoiceView from "../Invoice/View";
import ModalRaiseADispute from "../../components/ModalRaiseADispute/ModalRaiseADispute";
import ModalPaymentMethod from "../../components/ModalPaymentMethod/ModalPaymentMethod";
const { TextArea } = Input;
const { RangePicker } = DatePicker;
export default function Orders(props) {
    const {
        indv: { userTimeZone },
      } = useSelector(({ auth }) => auth);
    const [invoiceId, setInvoiceId] = useState();
    const [color, setColor] = useState("");
    const [bgColor, setBgColor] = useState("");
    const [data, setData] = useState([]);
    const [biz, setBiz] = useState({});
    const [visible, setModalVisible] = useState(false);
    const [invoice, setInvoiceDetails] = useState(false);
    const [raiseDispute, setRaiseDispute] = useState(false);
    const [orderStartDt, setOrderStartDate] = useState("");
    const [orderEndDt, setOrderEndDate] = useState("");
    const [paymentStatus, setPaymentStatus] = useState([]);
    const [loading, setLoading] = useState(false);
    const [deliveryStatus, setDeliveryStatus] = useState([]);
    const [deliveryDropdown, setDeliveryDropdown] = useState("");
    const [invoicedTo, setInvoicedTo] = useState("");
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [selectedUser, setSelectedUser] = useState("");
    const [paymentMode, setPaymentMode] = useState("");
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [isLoading, setIsLoading] = useState(false);
    const {
        indv: { bizid },
    } = useSelector(({ auth }) => auth);
    const dispatch = useDispatch();
    let history = useHistory();
    const OrderDeliveryDetails = async (invoicedTo) => {
        setLoading(true);
        const pgStart = invoicedTo.pgStart || pagination.current;
        let response = await actions.orderDelivery({
            pgStart,
            pgSize: pagination.pageSize,
            paymentStatus: paymentStatus,
            deliveryStatus: deliveryStatus,
            invoicedTo: invoicedTo,
            orderStartDt: orderStartDt,
            orderEndDt: orderEndDt,
            BusinessId:bizid,
        });
        setPagination({
            ...pagination,
            total: response?.count,
        });
        if (response?.success === true) {
            setLoading(false);
            setData(response?.orderDetails);
        }
    };
    const UpdateStatusFunction = async (value, record, paymentMode) => {
        let payload = {
            status: value,
            paymentMethod: paymentMode
        }
        let resp = await actions.updatePaymentStatus(payload, record.invoiceId || invoiceId);
        if (resp.success === true) {
            OrderDeliveryDetails(invoicedTo)
            message.success(resp.msg);
        } else {
            message.error(resp.msg);
        } setModalVisible(false)
    };
    const updateDeliveryStatusFunction = async (value, invoiceId) => {
        let payload = {
            deliveryStatus: value
        }
        let resp = await actions.updateDeliveryStatus(payload, invoiceId);
        if (resp.success === true) {
            OrderDeliveryDetails(invoicedTo)
            message.success(resp.message);

        } else {
            message.error(resp.message)
        }
    }
    const handleSearch = (value) => {
        setInvoicedTo(value);
        if (value.length >= MINIMUM_SEARCH_LIMIT) {
            OrderDeliveryDetails(value);
        } else if (value.length == 0) {
            OrderDeliveryDetails("");
        }
    };
    const handleTableChange = (pagination) => {
        setPagination({
            ...pagination,
        });
    };
    const getInvoiceDetailsApiCall = async (item, goToCatalog = false) => {
        let payload = {
            invoiceId: item.invoiceId,
            bizid: bizid,
        };
        const { success, resp } = await UserActions.getInvoiceDetails(payload);
        if (success === true) {
            setBiz(resp);
            let inv = resp;

            inv.objid = item.invoiceId;
            inv.fulfiled = null;
            console.log("selected invoice",inv);
            setSelectedInvoice(inv);
            setSelectedStatus(resp.status);
            setSelectedType("receivable");
            if (goToCatalog) {
                loadCart("add", inv);
            } else {
                setInvoiceDetails(true);
            }
        }
    };
    useEffect(() => {
        OrderDeliveryDetails(invoicedTo);
    }, [
        paymentStatus,
        deliveryStatus,
        orderEndDt,
        pagination.current,
        pagination.total,
        pagination.pageSize,
    ]);
    const getColor = (status) => {
        switch (status) {
            case "paid":
                return "#E2FFE5";
            case "canceled":
                return "#FFD6D6";
            case "pending":
                return "#EAEAEA";
            default:
                return null;
        }
    };
    const getTextColor = (PaymentStatus) => {
        switch (PaymentStatus) {
            case "paid":
                return "green";
            case "canceled":
                return "red";
            case "pending":
                return "grey";
            default:
                return null;
        }
    };
    const getDeliveryColor = (status) => {
        switch (status) {
            case "READY_TO_PICK":
                return "#E2FFE5";
            case "NA":
                return "#EAEAEA";
            case "FULFILLED":
                return "#E2FFE5";
            case "PROCESSING":
                return "#f3f3ba";
            default:
                return null;
        }
    };
    const getColorText = (status) => {
        switch (status) {
            case "READY_TO_PICK":
                return "green";
            case "NA":
                return "grey";
            case "FULFILLED":
                return "green";
            case "PROCESSING":
                return "#837943";
            default:
                return null;
        }
    };
    const statusStatus = [
        { value: "paid", label: "Paid" },
        { value: "pending", label: "Pending" },
        { value: "canceled", label: "Canceled" },
    ];
    const paymentStatusDropdown = [
        { value: "canceled", label: "Cancel" },
        { value: "paid", label: "Mark As Paid" },
    ];
    const deliveryType = [
        { value: "PROCESSING", label: "Processing" },
        { value: "READY_TO_PICK", label: "Ready To Pick" },
        { value: "FULFILLED", label: "Fulfilled" },
    ];
    const handleOnChange = (e, record) => {
        if (e === "paid") {
            setInvoiceId(record.invoiceId);
            setModalVisible(true);
        }
    };

    const { CART_LOADED } = Actions;
    const tableColumn = [
        {
            title: "Invoice Id ",
            dataIndex: "invoiceId",
            key: "invoiceId",
            width: 60,
        },
        {
            title: "Invoice To ",
            dataIndex: "invoicedTo",
            key: "invoicedTo",
            width: 120,
        },
        {
            title: "Type",
            dataIndex: "deliveryType",
            key: "deliveryType",
            width: 160,
            render: (data, key) => {
                return <p>{data ? data.charAt(0).toUpperCase() + data.slice(1) : "-"}</p>;
            },
        },
        {
            title: "Order Prep Time",
            dataIndex: "orderDeliveryTime",
            key: "orderTime",
            width: 230,
            render: (data) => {
                return data?.slice(0, 12) + " " + data?.charAt(13).toUpperCase() + data?.charAt(14).toUpperCase()
            },
        },
        {
            title: "Location",
            dataIndex: "location",
            key: "location",
            width: 150,
            render: (data, key) => {
                return <p>{data ? data : "-"}</p>;
            },
        },
        {
            title: "Delivery Type",
            dataIndex: "deliveryStatus",
            key: "Promotional",
            width: 200,
            render: (data, record) => (
                <DropdownComponent
                    bordered={false}
                    value={data}
                    options={deliveryType}
                    onChange={(value) => {
                        setColor(getColorText(value));
                        setBgColor(getDeliveryColor(value));
                        setDeliveryDropdown(value);
                        updateDeliveryStatusFunction(value, record.invoiceId)
                    }}
                    style={{
                        width: "100%",
                        borderRadius: "1rem",
                        color: getColorText(data),
                        backgroundColor: getDeliveryColor(data),
                    }}
                />
            ),
        },
        {
            title: "Ordered Time",
            dataIndex: "orderTime",
            key: "orderDeliveryTime",
            width: 180,
            render: (data) => {
                // return moment(data).format(" YYYY-MM-DD ,h:mm A");
                return moment.tz(data, userTimeZone).format("YYYY-MM-DD, h:mm A")
            },
        },
        {
            title: "Payment",
            dataIndex: "paymentStatus",
            key: "paymentStatus",
            width: 220,
            render: (item, record) => (
                <>
                    {item === "pending"  ? (
                        <DropdownComponent
                        value={item.charAt(0).toUpperCase() + item.slice(1)}
                        bordered={false}
                        options={paymentStatusDropdown}
                        onChange={(value) => {
                            setSelectedUser(value)
                            handleOnChange(value, record);
                            if (value === "canceled") {
                                UpdateStatusFunction(value, record)
                            }
                        }}
                        style={{
                            width: "100%",
                            borderRadius: "1rem",
                            color: getTextColor(item),
                            backgroundColor: getColor(item),
                        }}
                    />
                    
                    ) :  (
                        <DropdownComponent
                            disabled={true}
                            value={item.charAt(0).toUpperCase() + item.slice(1)}
                            bordered={false}
                            newclassnmae={item === "canceled"?"dropdownRed":"dropdown"} 
                            style={{
                                width: "100%",
                                borderRadius: "1rem",
                                color: getTextColor(item),
                                backgroundColor: getColor(item),
                            }}
                        />
                    )}
                </>
            ),
        },
        {
            title: "Dispute Status",
            dataIndex: "disputeStatus",
            key: "disputeStatus",
            width: 220, 
            render: (data, key) => {
                return <p>{data ? data.charAt(0) + data.slice(1).toLowerCase() : "-"}</p>;
            }
        },
        {
            title: "Actions",
            key: "action",
            render: (_, record) => {
                return (
                    <div>
                        <Space size="middle">
                            <Button
                                type="link"
                                onClick={() => {
                                    setSelectedUser(record);
                                    getInvoiceDetailsApiCall(record);
                                }}
                            >
                                Print
                            </Button>
                            {record.paymentStatus !== "paid" && (
                                <Button
                                    onClick={() => getInvoiceDetailsApiCall(record, true)}
                                    type="link"
                                >
                                    Edit
                                </Button>
                            )}
                        </Space>
                    </div>
                );
            },
        },
    ];

    const loadCart = async (mode, selectedInvoice) => {
        const payload = {};
        selectedInvoice.invoiceDetails.items.forEach((row) => {
            payload[row.catalogue_item_id] = row.details;
        });
        const details = selectedInvoice.invoiceDetails;

        const catalogueLocation = {
            locId: details.locId,
            locName: details.locName,
            parentLocation: details.parentLocation,
            biz: {
                bizid: details.parentLocation.bizid,
                bizName: details.business_name,
                country: details.business_country,
                merchantId: selectedInvoice.merchantId,
            },
        };

        const catalogue = {
            currency: selectedInvoice.currency,
            currencySymbol: selectedInvoice.currencySymbol,
            name: details.catalogueName,
            objid: details.catalogueId,
        };

        dispatch({
            type: CART_LOADED,
            payload: { cart: payload, catalogueLocation, catalogue, selectedInvoice },
        });
        if (mode === "edit") {
            //    setShowCart(true);
        } else {
            setIsLoading(true);
            const resp = await UserActions.getCatalogueByLoc({
                locId: details.locId,
            });
            setIsLoading(false);
            if (!resp.success) {
                message.error("Error retrieving catalogue");
            } else {
                //  onCart(details.locId);

                history.push(`/catalogue/store/${resp?.payload?.catalogue?.url}`, {
                    catalogueDetail: resp.payload,
                    fromBizAccount: true,
                });
            }
        }
    };
    return (
        <div>
            <div style={{ marginBottom: "2rem" }}>
                <Row gutter={[8, 10]}>
                    <Col xs={12} sm={4} md={4}>
                        <Button className="order-btn">
                            Add new order
                            <PlusOutlined
                                style={{ display: "inline-block", verticalAlign: "baseline" }}
                            />
                        </Button>
                    </Col>
                    <Col style={{ width: "25%" }} xs={24} md={6}>
                        <Input
                            prefix={<SearchOutlined className="prefix" />}
                            placeholder="Search"
                            onPressEnter={(e) => {
                                setPagination({
                                    ...pagination,
                                    current: 1,
                                });
                                handleSearch(e.target.value);
                            }}
                        />
                    </Col>
                    <Col style={{ width: "10%" }} xs={12} md={4} sm={6}>
                        <DropdownComponent
                            placeholder="Status"
                            style={{ minWidth: "100%" }}
                            options={statusStatus}
                            mode="multiple"
                            onChange={(e) => {
                                setPagination({
                                    ...pagination,
                                    current: 1,
                                });
                                setPaymentStatus(e);
                            }}
                        />
                    </Col>
                    <Col style={{ width: "15%" }} xs={12} md={4}>
                        <DropdownComponent
                            placeholder="Delivery Type"
                            style={{ minWidth: "100%" }}
                            options={deliveryType}
                            mode="multiple"
                            onChange={(e) => {
                                setPagination({
                                    ...pagination,
                                    current: 1,
                                });
                                setDeliveryStatus(e);
                            }}
                        />
                    </Col>
                    <Col xs={24} md={6}>
                        <RangePicker
                            size="middle"
                            onChange={(e) => {
                                if (e === null) {
                                    setOrderStartDate("");
                                    setOrderEndDate("");
                                } else {
                                    setPagination({
                                        ...pagination,
                                        current: 1,
                                    });
                                    setOrderStartDate(moment(e[0]._d).format("YYYY-MM-DD"));
                                    setOrderEndDate(moment(e[1]._d).format("YYYY-MM-DD"));
                                }
                            }}
                            allowClear={true}
                        />
                    </Col>
                </Row>
            </div>
            <PulseLoader color={"#3F9AF7"} loading={loading} size={10} />
            <div>
                <Table
                    bordered
                    className="orders-table"
                    pagination={pagination}
                    columns={tableColumn}
                    dataSource={data}
                    size="medium"
                    onChange={handleTableChange}
                />
            </div>
            <ModalPaymentMethod
                visible={visible}
                className="admin-modal"
                onCancel={() => setModalVisible(false)}
                text={<h6>InvoiceId:{invoiceId}</h6>}
                btn1="Cash"
                btnClick={() => {
                    setPaymentMode("cash");
                    UpdateStatusFunction("paid", invoiceId, "cash")

                }}
                btn2="Other POS"
                onClick={(val) => {
                    setPaymentMode("other POS");
                    UpdateStatusFunction("paid", invoiceId, "other POS")
                }}
            />


            <ModalRaiseADispute
                title="Invoice Details"
                width={450}
                visible={invoice}
                onCancel={() => setInvoiceDetails(false)}
                input={<InvoiceView
                    inv={selectedInvoice}
                    type={selectedType}
                    callback={() => setInvoiceDetails(false)}
                />}
            />
            <ModalRaiseADispute
                centered
                width={400}
                visible={raiseDispute}
                footer={null}
                button="submit"
                input={<Input placeholder='title' />}
                description={<TextArea />}
                onCancel={() => setRaiseDispute(false)}
                onClick={() => setRaiseDispute(false)} />
        </div>
    );
}
