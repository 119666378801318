/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { Row, Col, Button, Card, Typography } from "antd";
import { GiCheckMark } from "react-icons/gi";
import { WarningOutlined } from "@ant-design/icons";
import { ImCross } from "react-icons/im";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Images from "../../assets/images/Images";
import { Theme } from "../../common";
import { isMobile } from "../../common/util";

const { Text } = Typography;

const StripeUI = (props) => {
  const {
    errors,
    onResetClick
  } = props;
  const {
    indv: {
      qrcode,
    },
    settingsPlatform
  } = useSelector(({ auth }) => auth);

  const {
    pmt_fee: { fyndr_offer_fee, fyndr_invoice_fee, stripe_fee },
  } = settingsPlatform;

  if (!qrcode) {
    return null;
  }
  return (
    <div style={{ padding: "24px" }}>
      <Row justify="center">
        <img
          alt=""
          src={Images.StripeImage}
          style={{ height: isMobile() ? 130 : 150, marginBottom: 10 }}
        />
      </Row>
      <Row justify="center" style={{ textAlign: "center" }}>
        <h5>
          Businesses can now sell products and collect invoice payments
          on Fyndr.
        </h5>
      </Row>
      <Row justify={"center"}>
        <h6>
          Fyndr uses PCI compliant Stripe payment gateway to enable
          payments.
        </h6>
      </Row>
      <br /> <br />
      <Row justify="center">
        <Col>
          <Card style={{ backgroundColor: Theme.lightblue }}>
            <h5>Fyndr Fee</h5>
            <br />
            <h5>Offers:</h5>
            <h6>{`${fyndr_offer_fee && fyndr_offer_fee?.val_percent
              }% of sales + Stripe Fee*`}</h6>
            <br />
            <h5>Invoices:</h5>
            <h6>{`$${fyndr_invoice_fee && fyndr_invoice_fee?.val_flat
              } or ${fyndr_invoice_fee && fyndr_invoice_fee?.val_percent
              }% whichever is more + Stripe Fee*`}</h6>
            <br />
            <Text>*Stripe Fee: </Text>
            <Text>{` ${stripe_fee && stripe_fee?.val_percent}% + $${stripe_fee && stripe_fee?.val_flat
              } for domestic cards. International cards attract additional fee.`}</Text>
          </Card>
        </Col>
      </Row>
      <br />
      <Row gutter={[20, 10]} justify="center">
        <Col sm={24} xs={24}>
          {errors?.missing_info?.errors.length > 0 &&
            errors?.missing_info && (
              <Card
                className="stripeCard"
                extra={
                  <WarningOutlined
                    style={{
                      color: "#d9534f	",
                      fontSize: "16px",
                      height: "3rem",
                    }}
                  />
                }
                title={
                  <h4
                    className="card-title"
                    style={{ color: "#d9534f	" }}
                  >
                    Errors With Stripe
                  </h4>
                }
              >
                {errors?.missing_info?.errors.map((item, key) => (
                  <Row>
                    <Col xs={4} sm={2}>
                      <ExclamationCircleOutlined
                        style={{ color: "#d9534f" }}
                      />
                    </Col>
                    <Col style={{ position: "relative" }} span={20}>
                      <p
                        style={{
                          maxWidth: "100%",
                          overflowWrap: "break-word",
                        }}
                      >
                        {item?.reason}
                      </p>
                      <p>
                        {item.requirement
                          ? item.requirement.replace(/\./g, " -> ")
                          : ""}
                      </p>
                    </Col>
                  </Row>
                ))}
              </Card>
            )}

          {errors?.missing_info?.pendingVerification.length > 0 &&
            errors?.missing_info && (
              <Card
                className="stripeCard"
                extra={
                  <WarningOutlined
                    style={{
                      color: "#d9534f	",
                      fontSize: "16px",
                      height: "3rem",
                    }}
                  />
                }
                title={
                  <h4
                    className="card-title"
                    style={{ color: "#d9534f	" }}
                  >
                    Pending Verification
                  </h4>
                }
              >
                {errors?.missing_info?.pendingVerification.map(
                  (item, key) => (
                    <Row>
                      <Col xs={4} sm={2}>
                        <ExclamationCircleOutlined
                          style={{ color: "#d9534f" }}
                        />
                      </Col>
                      <Col style={{ position: "relative" }} span={20}>
                        <p
                          style={{
                            maxWidth: "100%",
                            overflowWrap: "break-word",
                          }}
                        >
                          {item.replace(/\./g, " -> ")}
                        </p>
                      </Col>
                    </Row>
                  )
                )}
              </Card>
            )}
        </Col>
      </Row>
      <br />
      <Row justify="center">
        <Col>
          {errors?.chargesEnabled === false && errors?.detailsSubmitted && (
            <>
              <Row>
                <Col span={2}>
                  <ImCross
                    style={{
                      color: Theme.red,
                      fontSize: isMobile() ? 12 : 20,
                      marginRight: 10,
                      marginTop: "2px",
                    }}
                  />
                </Col>
                <Col span={22}>
                  <h6 style={{ lineHeight: "17px" }}> We still need some more information before accepting
                    card payments for your offers.
                  </h6>
                </Col>
              </Row>
              <Row>
                <Col style={{ paddingLeft: isMobile() ? 0 : 38, marginBottom: 10 }}>
                  <h6>
                    Please review your {"  "}
                    <a
                      href="https://stripe.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Stripe Account
                    </a>
                    .
                  </h6>
                </Col>
              </Row>
            </>
          )}

          {errors?.chargesEnabled === true && (
            <div>
              <h5>
                <GiCheckMark
                  style={{ color: Theme.green, fontSize: 24 }}
                />{" "}
                You are all set to sell products on Fyndr platform.
              </h5>
            </div>
          )}
          {errors?.payoutsEnabled === false && errors?.detailsSubmitted && (
            <>
              <Row>
                <Col span={2} >
                    <ImCross
                      style={{
                        color: Theme.red,
                        fontSize: isMobile() ? 12 : 20,
                        marginRight: 10,
                        marginTop: "2px",
                      }}
                    />
                </Col>
                <Col span={22}>
                  <h6 style={{ lineHeight: "17px" }}>
                    We still need some more information before sending
                    payments to your account.
                  </h6>
                </Col>
              </Row>
              <Row>
                <Col style={{ paddingLeft: isMobile() ? 0 : 38 }}>
                  <h6>
                    Please review your {"  "}
                    <a
                      href="https://stripe.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Stripe Account
                    </a>
                    .
                  </h6>
                </Col>
              </Row>
            </>
          )}

          {errors?.payoutsEnabled === true && (
            <div>
              <h5>
                <GiCheckMark
                  style={{ color: Theme.green, fontSize: 24 }}
                />{" "}
                You are all set to receive funds in your bank account.
              </h5>
            </div>
          )}
          <br />
          {errors ?
            <Row justify={"center"}>
              <Button type="primary" onClick={onResetClick}>Reset Stripe</Button>
            </Row>
            :
            <h4> This business is not stripe integrated</h4>
          }
        </Col>
      </Row>
      <br /> <br />
      <br /> <br />
      <h6>
        Visit:
        <a
          href="https://stripe.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          {` Stripe Account`}
        </a>
        .
      </h6>
      {!errors?.chargesEnabled &&
        <Row justify={"start"}>
          <h6> *Please ensure that user has completed their Stripe registration.</h6>
        </Row>
      }
    </div>
  );
};

export default StripeUI;
