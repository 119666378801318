import React, { useState, useEffect } from "react";
import { Input, Row, Col, Radio, Button, message, Card, Typography } from "antd";
import UserActions from "../../actions";
import { QRCode } from "react-qrcode-logo";
import blueFyndr from "../../assets/images/blueFyndr.png"
import { useSelector } from "react-redux";
import {
  status_list,
  getFormattedDtNew,
} from "../../common/util";
import moment from "moment";

const { Text } = Typography;
const Voucher = (props) => {
  const {
    indv: { userTimeZone },
  } = useSelector(({ auth }) => auth);
  const { indv: { indvid, merchantId, fname, lname } } = useSelector(({ auth }) => auth);
  const {
    callback,
    type,
    offer,
    offer: {
      objid,
      offerTitle,
      buyerName,
      validTill,
      remarks,
      invoiceId,
      redeemptionStatus,
      voucherCode,
      retailPrice,
      offerPrice,
      currentValue,
      currencySymbol,
      index,
      appointments,
      appointment,
      redemptionTime
    },
  } = props;

  const [curVal, setCurVal] = useState(currentValue);
  const [status, setStatus] = useState(redeemptionStatus);
  const [remark, setRemark] = useState(null);
  const [redeemedAmt, setRedeemedAmt] = useState(null);
  const [redeemedAmtError, setRedeemedAmtError] = useState(null);
  const [isLoading,] = useState(false)


  useEffect(() => {
    setCurVal(currentValue);
    setStatus(redeemptionStatus);
    setRemark(null);
    setRedeemedAmt(null);
    setRedeemedAmtError(null);
  }, [currentValue, redeemptionStatus]);

  const handleChgAmt = (val) => {
    setRedeemedAmtError(null);
    setRedeemedAmt(val);
    const balance = (Number(currentValue) - val).toFixed(2);
    setCurVal(balance);
    if (balance < 0) {
      setRedeemedAmtError("Unused value can't be negative");
    }
  };
  const voucherUpdate = async () => {
    if (redeemedAmtError) {
      return false;
    }

    let lat, lng;
    // const coords = await captureLocation(); 
    // if(coords) {
    //     lat = coords.latitude;
    //     lng = coords.longitude;
    // }
    let payload;

    if (type === 'receivable') {
      payload = {
        voucherId: objid,
        merchantId,
        status: curVal <= 0 ? 'redeemed' : status,
        customRemarks: remark,
        invoiceId,
        updatedBy: `${fname} ${lname}`,
        lat: lat ? lat : null,
        lng: lng ? lng : null,
        buyerId: indvid,
        redeemedValue: redeemedAmt,
        currentValue: status === 'redeemed' ? 0 : curVal
      }
    } else {
      payload = {
        voucherId: objid,
        status: 'redeemed',
        invoiceId,
        updatedBy: `${fname} ${lname}`,
        lat: lat ? lat : null,
        lng: lng ? lng : null,
        buyerId: indvid,
        redeemedValue: curVal,
        currentValue: 0
      }
    }

    const resp = await UserActions.voucherUpdate(payload);

    if (resp.success) {
      message.success("Updated Successfully");
      setRemark(null);
      setRedeemedAmt(null);
      if (callback) {
        callback();
      }
    } else {
      message.error(resp.message)
    }
  }
  return (
    <>
      <Card style={{ width: '100%', marginBottom: "1rem" }} className="bizCard" >
        <Row align="middle" justify="center" >
          <Col align="center">
            <Row>
              <QRCode
                value={`${voucherCode}`}
                size={300}
                logoWidth={80}
                ecLevel="H"
                quietZone={10}
                fgColor={status === 'redeemed' ? '#ccc' : '#000'}
                logoImage={blueFyndr}
              />
            </Row>
          </Col>
        </Row>
        <Row justify="center" align={"middle"}>
          <Text className="invoiced_to">{voucherCode}</Text>
        </Row>
      </Card>
      {currentValue > 0 && redeemptionStatus !== "redeemed" && (
        <Row align="middle" justify={"space-between"} style={{ width: "100%", marginBottom: "1rem" }} className="bizCard" >
          <Col span={8}>
            <Text className='invoice_text'>Unused Value</Text>
          </Col>
          <Col >
            <Text className='invoice_value'>{`${currencySymbol}${curVal}`}</Text>
          </Col>
        </Row>
      )}
      <Row style={{ width: "100%" }}>
        <Col span={24}>
          {remarks &&
            remarks.map((row, index) => {
              const {
                message,
                time,
                redeemedValue,
                updatedBy,

              } = row;
              return (
                <>
                  <Col className="bizCard" style={{ marginBottom: "1rem" }}>
                    <Col span={6} >
                      <Text className="invoice_value">{getFormattedDtNew(time, userTimeZone)}</Text>
                    </Col>
                    {redemptionTime &&
                      <Row justify={"space-between"} >
                        <Col span={12}>
                          <Text className="invoice_text">Redemption Time:</Text>
                        </Col>
                        <Text className="invoice_value">
                          {moment.tz(`1970-01-01 ${redemptionTime}`, 'YYYY-MM-DD HH:mm', 'UTC').tz(userTimeZone).format('HH:mm A')}
                        </Text>
                      </Row>
                    }

                    {redeemedValue !== null && (
                      <Row justify={"space-between"} >
                        <Col span={12}>
                          <Text className="invoice_text">Redeemed Value:</Text>
                        </Col>
                        <Text className="invoice_value">
                          {currencySymbol}{redeemedValue}
                        </Text>
                      </Row>
                    )}
                    {console.log("message", message)}
                    {message !== null && message !== "" && (
                      <Row justify={"space-between"} >
                        <Col span={12}>
                          <Text className="invoice_text">Message:</Text>
                        </Col>
                        <Text className="invoice_value">
                          {message}
                        </Text>
                      </Row>
                    )}
                    {updatedBy !== null && (
                      <Row justify={"space-between"} >
                        <Col span={12}>
                          <Text className="invoice_text">Updated by:</Text>
                        </Col>
                        <Text className="invoice_value">
                          {updatedBy}
                        </Text>
                      </Row>
                    )}
                  </Col>
                </>
              );
            })}

          {type === "receivable" && redeemptionStatus !== "redeemed" && !isLoading && (
            <>
              <Row className="bizCard">
                <Col span={24}>
                  <Radio.Group value={status} onChange={(e) => setStatus(e.target.value)}>
                    {status_list.map((row, index) => {
                      return (
                        <Row >
                          <Radio value={row.value} className='invoice_text'>{row.display}</Radio>
                        </Row>
                      );
                    })}
                  </Radio.Group>
                  {status === "partially-redeemed" && (
                    <>
                      <Row style={{ marginTop: "1.3rem" }}>
                        <Col span={24}>
                          <Input
                            placeholder="Amount redeemed"
                            onChange={(e) => {
                              handleChgAmt(e.target.value);
                            }}
                            value={redeemedAmt}
                          />
                          <Text type="danger" className='big'>{redeemedAmtError && redeemedAmtError}</Text>
                        </Col>
                      </Row>

                      <Row style={{ marginTop: "1.3rem" }}>
                        <Col span={24}>
                          <Input
                            placeholder="Remarks"
                            onChange={(e) => {
                              setRemark(e.target.value);
                            }}
                            value={remark}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
      {type === "receivable" && redeemptionStatus !== "redeemed" && !isLoading &&
        <Row align="middle" justify={"center"} style={{ marginTop: "1rem", width: "100%" }}>
          <Col span={24}>
            <Button type="primary" onClick={() => voucherUpdate()} className="invoice_btn">Save</Button>
          </Col>
        </Row>
      }
      {
        type !== 'receivable' && redeemptionStatus !== 'redeemed' &&
        <Row align="middle" justify="center" style={{ width: "100%" }}>
          <Col span={24}>
            <Button type="primary" onClick={() => voucherUpdate()} className="invoice_btn">Mark as redeemed</Button>
          </Col>
        </Row>
      }
    </>
  );
};

export default Voucher;
