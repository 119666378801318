import React from 'react'
import {
  Row,
  Col,
} from "antd";
import ReviewStars from "../ReviewStars/ReviewStars";


export default function OverAllReview(props) {
  return (
    <Col >
      {props.reviewsOverview && (
        <Row>
          <Col>
            <ReviewStars
              onStarsClick={props.onStarsClick}
              disabled={props.disabled}
              rating={props.rating}
            />
          </Col>
          <Col>
            <div className="ratingAvg">
              {props.rating}
              {props.text}
            </div>
          </Col>
          <Col>
            <div
              onClick={props.onClick}
              className="totalReviews"
            >
              {props.totalRatings}
            </div>
          </Col>
        </Row>
      )}
    </Col>
  )
}

