/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { QRCode } from "react-qrcode-logo";
import uploadImage from "../../assets/images/uploadImage.svg";
import cancelImg from "../../assets/images/cancel-img.svg";
import CustomizedModal from "../../components/CustomizedModal/CustomizedModal";
import AllReviewPopUp from "../../components/AllReviewPopUP/AllReviewPopUp";
import Placeholder from "../../assets/images/placeholder-image.jpg";
import UserActions from "../../actions";
import actions from "../../actions";
import {
  Row,
  Col,
  Divider,
  Typography,
  Button,
  Modal,
  Card,
  message,
  Radio,
  Space
} from "antd";
import ImageViewer from "react-simple-image-viewer";
import { BizCard } from "../../components";
import { debug } from "../../actions";
import {
  getFormatedDateTime,
  getFormattedDt,
  capitalize,
  getTotal,
  parseAddress,
  getDisplayStatus,
  getFormattedDateTimeNew,
  getFormattedDtNew,
} from "../../common/util";
import blueFyndr from "../../assets/images/blueFyndr.png"
import VoucherUpdate from "../Voucher";
import Sliders from "../../assets/images/sliders.svg";
import Loader from "../../components/Loading/Loader";
import { DownloadOutlined } from "@ant-design/icons";
import ReviewComments from "../../components/ReviewComments/ReviewComments";
import Axios from "axios";
import Buttons from "../../components/Buttons";
import { isMobile } from "../../common/util";
import { useHistory } from "react-router-dom";
import ReviewStars from "../../components/ReviewStars/ReviewStars";
import ModalRaiseADispute from "../../components/ModalRaiseADispute/ModalRaiseADispute";
import ModalPaymentMethod from "../../components/ModalPaymentMethod/ModalPaymentMethod";
import DropdownComponent from "../../components/Dashboard/DropdownComponent";
import TextArea from "antd/lib/input/TextArea";
import OverAllReview from "../../components/OverAllReview/OverAllReview";
import moment from "moment";
import InvoiceFooter from "../../components/InvoiceFooter.jsx";
import {
  ArrowLeftOutlined,
} from "@ant-design/icons";

const { Text } = Typography;

const InvoiceView = (props) => {
  const {
    indv: { userTimeZone },
  } = useSelector(({ auth }) => auth);
  const { inv, type, callback } = props;
  const [vouchers, setVouchers] = useState(null);
  const [replyData, setReplyData] = useState({});
  const [reportComment, setReportComment] = useState("");
  const [reportModalVisble, setReportModalVisible] = useState(false);
  const [reportedCommentVisible, setReportedCommentVisible] = useState(false);
  const [reportData, setReportData] = useState({});
  const [seeAllComments, setSeeAllComments] = useState(false);
  const [titles, setTitles] = useState("");
  const [paraVisible, setParaVisible] = useState(false);
  const [selectedPlatformUser, setSelectedPlatformUser] = useState({});
  const [replyModalVisible, setReplyModalVisible] = useState(false);
  const [reviewsOverview, setReviewsOverview] = useState(null);
  const [userComment, setUserComment] = useState(false);
  const [screen, setScreen] = useState(null);
  const [biz, setBiz] = useState(null);
  const [last4, setLast4] = useState();
  const [businessId, setBusinessId] = useState("");
  const [raiseDispute, setRaiseDispute] = useState(false);
  const [dispute, setDispute] = useState([]);
  const [selectDispute, setSelectedDispute] = useState("");
  const [brand, setBrand] = useState("");
  const [billingAddress, setBillingAddress] = useState({});
  const [billingEmail, setBillingEmail] = useState("");
  const [gifteeDetails, setGifteeDetails] = useState(null);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState(null);
  const [visible, setModalVisible] = useState(false);
  const [text, setText] = useState("");
  const [disputeStatus, setDisputeStatus] = useState("");
  const [paymentMode, setPaymentMode] = useState("");
  const [comments, setComments] = useState(null);
  const [fyndrCash, setFyndrCash] = useState(0);
  const [appointments, setAppointments] = useState([]);
  const [promoImage, setPromoImage] = useState("");
  const [purchaseLoc,setPurchaseLoc]=useState({});
  const history = useHistory();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });
  const [showQrVisible, setShowQrVisible] = useState(false)
  const [invoiceId, setInvoiceId] = useState()
  const [msgVisible, setMsgVisible] = useState(false)
  const [sortByData, setSortByData] = useState({
    orderBy: "RATING",
    orderType: "DESC",
  });

  useEffect(() => {
    if (businessId) {
      getReviewList(businessId);
      getReviewOverViews(businessId);
    }
  }, [sortByData, pagination.current, pagination.total, pagination.pageSize]);

  const getReviewList = async (cmpn) => {
    let pgStart = pagination.current;
    let pgSize = pagination.pageSize;
    let res = await UserActions.fetchReviewsratings(
      cmpn,
      sortByData,
      pgStart,
      pgSize
    );
    setPagination({
      ...pagination,
      total: res?.cmpn?.count,
    });
    setComments(res.cmpn);
  };

  const getReviewOverViews = async (cmpn) => {
    let res = await UserActions.fetchReviewsOverview(cmpn);
    setReviewsOverview(res.cmpn);
    setBusinessId(res.cmpn.bizId);
  };

  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination,
    });
  };

  const columns = [
    {
      title: "Datatype",

      render: (data, key) => {
        return (
          <Col span={24}>
            <ReviewComments
              reportModalVisibleClick={() => {
                if (data?.reportedComment === null) {
                  setReplyData(data);
                  setReportComment("");
                  setReportModalVisible(true);
                } else {
                  setReportModalVisible(false);
                  setReportedCommentVisible(true);
                  setReportData(data.reportedComment);
                }
              }}
              openParagraph={(data) => {
                setSeeAllComments(false);
                setTitles("Comment");
                setParaVisible(true);
                setSelectedPlatformUser(data);
              }}
              indv={indv?.bizid}
              businessId={businessId}
              setModalVisible={() => {
                setReplyData(data);
                setReplyModalVisible(true);
              }}
              data={data}
            />
          </Col>
        );
      },
    },
  ];
  const { receivables, payables, voucherUpdated } = useSelector(
    ({ fin }) => fin
  );
  const [invoice] = useState(
    type === "receivable" ? receivables[inv?.objid] : payables[inv?.objid]
  );
  const {
    indv: { indvid, bizid },
    indv,
  } = useSelector(({ auth }) => auth);
  const {
    objid,
    baseAmount,
    taxAmount,
    tipAmount,
    invoiceDt,
    currencySymbol,
    status,
    fulfiled,
    invoiceDetails,
    invoiceDetails: {
      title,
      offers,
      cmpn_title,
      cust_message,
      server_name,
      invoice_nbr,
      img_url,
      deliveryTime,
      deliveryType,
      locName,
      items,
    },
    buyerFname,
    buyerLname,
    channel,
  } = inv;
  let myAppointment = invoiceDetails?.items?.map(
    (item) => item?.details?.mitem
  );
  let objData = myAppointment?.map((is) => is?.appointment);
  let appointmentData = objData?.flat();

  const totalAmount = getTotal(inv);
  const fulfilInvoice = async ({ target: { value: type } }) => {
    inv.fulfiled = type;
    inv.bizid = bizid;

    setIsLoading(true);
    const resp = await UserActions.saveInvoice(inv, "rcpt");
    setIsLoading(true);

    if (resp.success) {
      message.success("Invoice Fulfilled");
      callback();
    } else {
      message.error(resp.msg);
    }
  };

  let reviwdata = [
    {
      star: 5,
      count: 2000,
    },
    {
      star: 4,
      count: 1500,
    },
    {
      star: 3,
      count: 100,
    },
    {
      star: 2,
      count: 50,
    },
    {
      star: 1,
      count: 20,
    },
  ];
  const fetchInvoiceDetails = async () => {
    let payload = {
      invoiceId: objid,
    };

    if (type === "receivable") payload.bizid = bizid;
    else payload.buyerId = indvid;

    setIsLoading(true);
    const { success, resp } = await UserActions.getInvoiceDetails(payload);
    console.log("getInvoiceDetails", resp);
    setIsLoading(false);
    if (success) {
      setBiz(resp.biz);
      setFyndrCash(resp.fyndrCash);
      setVouchers(resp.offers);
      setAppointments(resp?.invoiceDetails?.offers);
      setDisputeStatus(resp?.disputeStatus);
      setBusinessId(resp.biz.bizid);
      getReviewList(resp.biz.bizid);
      getReviewOverViews(resp.biz.bizid);
      setBillingAddress(resp?.billingAddress);
      setBillingEmail(resp?.buyerEmail);
      setGifteeDetails(resp?.gifteeDetails);
      setInvoiceId(resp?.invoiceId);
      setPromoImage(resp?.fyndrLogo);
      setPurchaseLoc(resp?.businessLocationAddress);

      if (resp.payments) {
        setBrand(resp?.payments?.cardBrand);
        setLast4(resp?.payments?.cardLast4);
      }
    }
  };

  useEffect(() => {
    (async () => {
      await fetchInvoiceDetails();
    })();
  }, [voucherUpdated, inv]);

  const download = async (e, uri, title) => {
    e.stopPropagation();
    let fileDownload = require("js-file-download");
    const res = await Axios.get(uri, {
      responseType: "blob",
    });
    fileDownload(res.data, `${title}.pdf`);
  };
  let obj1 = {};
  const result = [];
  const disputeReasons = async () => {
    let response = await UserActions.getDisputeReasons();
    for (let value of response?.payload) {
      obj1 = { ...value };
      result.push(obj1);
    }
    const newArrayOfObj = result.map(({ 0: value, 1: label, ...rest }) => ({
      value,
      label,
      ...rest,
    }));
    if (response?.success === true) {
      setDispute(newArrayOfObj);
    } else {
      debug && console.log("what response for dispute", response?.payload);
    }
  };

  const handleCancelClick = (index) => {
    let selectedImagesArr = [...selectedImages];
    selectedImagesArr[index] = null;
    setSelectedImages(selectedImagesArr);
  };

  const handleButtonClick = (index) => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.onchange = (event) => handleFileChange(event, index);
    fileInput.click();
  };

  const handleOnReviewSubmit = () => {
    if (rating === 0) {
      message.error("Minimum rating should be 1");
    } else if (commentForReview === "") {
      message.error("Please add the comment to proceed");
    } else {
      AddReviewComments();
    }
  };

  const AddReviewComments = async () => {
    setIsLoading(true);
    let newImgs = selectedImages
      .filter(
        (item) =>
          item !== undefined && item !== null && Object.keys(item).length !== 0
      )
      .map((item) => {
        return {
          imageType: item?.imageType,
          image: item?.image,
        };
      });
    let response = await actions.addReview({
      businessId: businessId,
      userId: indvid,
      text: commentForReview,
      rating: rating,
      images: newImgs,
    });
    if (response?.success === true) {
      setIsLoading(false);
      message.success(response?.msg);
      setUserComment(false);
      setRating(0);
      getReviewList(businessId);
      getReviewOverViews(businessId);
      setCommentForReview("");
      setSelectedImages(Array(6).fill(null));
    } else {
      setIsLoading(false);
      message.error(response?.msg);
      setUserComment(false);
    }
  };
  function sumAppointments(items) {
    let sum = 0;
    items?.forEach((item) => {
      if (item?.details?.mitem?.appointment?.length > 0 || item?.appointment?.length > 0) {
        sum += item?.details?.mitem?.appointment?.length || item?.appointment?.length;
      }
    });

    return sum;
  }

  function sumQuantities(items) {
    let sum = 0;
    items?.forEach((item) => {
      sum += item?.details?.qty || item?.qty;
    });

    return sum;
  }

  const handleFileChange = (event, index) => {
    const file = event.target.files[0];
    const maxFileSize = 5 * 1024 * 1024;
    if (!/^image\//.test(file.type)) {
      message.error(`Please upload image type files only.`);
    } else if (file.type === "image/svg+xml") {
      message.error("SVG files are not allowed.");
    } else if (file.size <= maxFileSize) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const URL = reader.result.replace(/^data:image\/[a-z]+;base64,/, "");
        const imageType = file.type.replace(/^image\//, "");
        const updatedSelectedImages = selectedImages.map((image, i) => {
          if (i === index) {
            if (imageType === "svg+xml") {
              return {
                url: { Placeholder },
                imageType: "jpg",
                image: "",
              };
            } else {
              return {
                url: reader.result,
                imageType: imageType,
                image: URL,
              };
            }
          }
          return image;
        });
        setSelectedImages(updatedSelectedImages);
      };
    } else {
      message.error("The image file size should be less than or equal to 5MB.");
    }
  };

  const [commentForReview, setCommentForReview] = useState("");
  const [selectedImages, setSelectedImages] = useState(Array(6).fill(null));
  const [rating, setRating] = useState(0);

  const submitDispute = async () => {
    let payload = {
      disputeReasonId: parseInt(selectDispute),
      description: text,
    };
    let invoiceId = objid;
    try {
      let response = await UserActions.raiseDispute(payload, invoiceId);
      if (response.success) {
        message.success(response?.msg);
        setRaiseDispute(false);
        fetchInvoiceDetails();
      } else {
        message.error(response?.msg);
        setRaiseDispute(false);
      }
    } catch (error) {
      console.log("what is error >>>>>", error);
    }
  };
  const UpdateStatusFunction = async (value, record, paymentMode) => {
    let payload = {
      status: value,
      paymentMethod: paymentMode,
    };
    let invoiceId = objid;
    let resp = await actions.updatePaymentStatus(
      payload,
      record.invoiceId || invoiceId
    );
    if (resp.success === true) {
      message.success(resp.msg);
    } else {
      message.error(resp.msg);
    }
    setModalVisible(false);
  };
  const handleOnSubmit = () => {
    if (selectDispute === "") {
      message.error("Please select any one options from the dropdown below");
    } else if (text === "") {
      message.error("Description can't be empty");
    } else {
      submitDispute();
    }
  };
  let date1 = new Date(invoiceDt);
  let date2 = new Date();

  let Difference_In_Time = date2.getTime() - date1.getTime();
  let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  const startDate = invoiceDetails?.featured_start_date && new Date(invoiceDetails?.featured_start_date);
  const formattedDate = startDate?.toISOString()?.slice(2, 10);

  const endDate = invoiceDetails?.featured_end_date && new Date(invoiceDetails?.featured_end_date);
  const formattedendDate = endDate?.toISOString()?.slice(2, 10);
  const diffInMilliseconds = endDate - startDate;
  const millisecondsInMonth = 1000 * 60 * 60 * 24 * 30.436875; // Average milliseconds in a month

  const diffInMonths = Math.round(diffInMilliseconds / millisecondsInMonth);

  const renderListItems = () => {
    return (
      <>
        <Row style={{ marginBottom: "1rem" }}>
          <Row style={{ marginBottom: "8px" }}>
            <Col span={24}>
              <Text className="invoiced_to">Item Details:</Text>
            </Col>
          </Row>
          <Col style={{ width: "100%" }} span={24}>
            {inv.invoiceDetails.items.map((item, key) => {
              let myAppointment = invoiceDetails?.items?.map(
                (item) => item?.details?.mitem
              );
              let objData = myAppointment.map((is) => is?.appointment);
              let appointments = item?.details?.mitem?.appointment || [];

              let scheduledQty = appointments.length;
              let remainingQty = item.details.qty - scheduledQty;
              return (
                <div key={key}>
                  <Row
                    className="bizCard"
                    style={{ width: "100%" }}
                  >
                    <Col style={{ width: "100%" }}>

                      <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                        <Col span={10}>
                          <Text className="invoice_text">
                            {item.details.mitem.name}
                            {item.details.wholeDetails.modName
                              ? `(${item.details.wholeDetails.modName})`
                              : ""}{`${" "}(X ${Number(item.details.qty).toFixed(2)})`}
                          </Text>
                        </Col>

                        <Text className="invoice_value">{`${item.details.currencySymbol}${Number(
                          item.details.total
                        ).toFixed(2)}`}</Text>
                      </Row>

                      {appointments?.filter((data) => data.objId === item.objid).map((appoint, index) => {
                        if (appoint !== undefined) {
                          let date = Object.keys(appoint);
                          let dataObj = Object.values(appoint);
                          return (
                            <div key={index}>
                              <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                                <Col span={12}>
                                  <Text className="invoice_text">Appointment:</Text>
                                </Col>

                                <Text className="invoice_value">{getFormattedDtNew(date, userTimeZone)}</Text>
                              </Row>
                              <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                                <Col span={12}>
                                  <Text className="invoice_text">Start time:</Text>
                                </Col>
                                <Text className="invoice_value">
                                  {`${moment(
                                    dataObj[0]?.startTime?.slice(0, 5),
                                    "HH:mm"
                                  ).format("hh:mm A")}`}
                                </Text>
                              </Row>
                              <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                                <Col span={10}>
                                  <Text className="invoice_text">End time:</Text>
                                </Col>

                                <Text className="invoice_value">
                                  {`${moment(
                                    dataObj[0]?.endTime?.slice(0, 5),
                                    "HH:mm"
                                  ).format("hh:mm A")}`}

                                </Text>
                              </Row>
                            </div>
                          );
                        }
                      })
                      }
                      {remainingQty > 0 && (
                        <Row align="middle" justify={"space-between"}>
                          <Col>
                            <Text className="invoiced_to">Scheduled Later</Text>
                          </Col>
                          <Col>
                            <Text className="invoiced_to">
                              {remainingQty}
                            </Text>
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                  <div
                    style={{
                      marginTop: ".5rem",
                    }}
                  >
                    {item.details.addon.length > 0 &&
                      item.details.addonDetails.map((addOnItem) => {
                        return (
                          <Row className="addonDisplay" justify="space-between">
                            <Col span={8}>
                              <Text className="invoice_text">{addOnItem.modName}</Text>
                            </Col>
                            <Col span={6}>
                              <Text>
                                +{currencySymbol}
                                {addOnItem.price.toFixed(2)}
                              </Text>
                            </Col>
                            <Col span={10}></Col>
                          </Row>
                        );
                      })}
                    {item?.details?.instruction && (
                      <Text style={{ color: "#8c8b8d" }} className="invoice_text">
                        {item?.details?.instruction}
                      </Text>
                    )}
                  </div>
                </div>
              );
            })}
          </Col>
        </Row>

      </>
    );
  };

  const getChannelName = (channel) => {
    switch (channel) {
      case 'offers':
      case 'offer_appointment':
        return 'Offer';
      case 'events':
        return 'Event';
      case 'interaction':
        return 'Interaction';
      case 'catalog':
      case 'catalog_appointment':
        return 'Catalog';
      case 'cmpn_promo':
      case 'promo':
        return 'Campaign';
      default:
        return 'Unknown';
    }
  };

  const getchannelBought = (channel) => {
    switch (channel) {
      case 'offers':
      case 'offer_appointment':
        return 'Offers';
      case 'events':
        return 'Events';
      case 'interaction':
        return 'Interaction';
      case 'catalog':
      case 'catalog_appointment':
        return 'Catalogs';
      case 'cmpn_promo':
      case 'promo':
        return 'Campaign';
      default:
        return 'Unknown';
    }
  };


  const getStatusColor = (status) => {
    switch (status) {
      case 'unused':
        return '#32871E';
      case 'redeemed':
        return '#FFC700';
      case 'partially-redeemed':
        return "#257CDB"
      default:
        return 'Unknown';
    }
  };
  return (
    <>
      {biz !== null && (
        <div style={{ marginBottom: "1rem" }}>
          <BizCard
            businessLogo={channel !== "cmpn_promo" ? biz.mainLogo : promoImage}
            businessName={biz.bizName}
            businessWebsite={biz.website}
            businessAddonUrl={biz.addonUrl}
            businessAddress={parseAddress(biz)}
            businessPhone={biz.addonUrl}
            channel={channel}
            invoiceID={invoiceId}
            gifteeDetails={gifteeDetails}
            reviewRatings={
              <OverAllReview
                disabled={true}
                reviewsOverview={reviewsOverview}
                rating={reviewsOverview?.overallRating}
                text=" out of 5"
                totalRatings={`(${reviewsOverview?.totalRatings} Reviews)`}
                onClick={() => {
                  setSeeAllComments(true);
                }}
              />
            }
          />
        </div>
      )}
      <div >
        <Row className="bizCard" style={{ marginBottom: "1rem" }}>
          <Col style={{ width: "100%" }}>
            <Row justify={"space-between"} style={{ color: "#257CDB", marginBottom: "8px" }}>
              <Col span={8}>
                <Text className="invoiced_to">Invoiced to:</Text>
              </Col>
              <Col>
                <Text className="invoiced_to">
                  {buyerFname} {buyerLname}
                </Text>
              </Col>
            </Row>

            {(channel === "offers" || channel === "offer_appointment") &&
              <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                <Col span={10}>
                  <Text className="invoice_text">Purchased as gift :</Text>
                </Col>
                <Text className="invoice_value">
                  {gifteeDetails !== null ? "Yes" : "No"}
                </Text>
              </Row>
            }
            {channel === "catalog" && (
              <>

                <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                  <Col span={8}>
                    <Text className="invoice_text">Delivered:</Text>
                  </Col>
                  <Col>
                    <Text className="invoice_value">
                      {fulfiled ? capitalize(fulfiled) : "NA"}
                    </Text>
                  </Col>
                </Row>
                <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                  <Col span={8}>
                    <Text className="invoice_text">Delivery Time:</Text>
                  </Col>
                  <Col>
                    <Text className="invoice_value">
                      {capitalize(deliveryTime)}
                    </Text>
                  </Col>
                </Row>
              </>
            )}
            {(channel === "cmpn_promo" || channel === "promo") && (
              <>
                <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                  <Col span={8}>
                    <Text className="invoice_text">Campaign Name:</Text>
                  </Col>
                  <Col>
                    <Text className="invoice_value">{invoiceDetails?.title.split(':')[1].trim()}</Text>
                  </Col>
                </Row>
                {invoiceDetails?.promo_channels.includes("featured") &&
                  <>
                    <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                      <Col span={8}>
                        <Text className="invoice_text">Featured:</Text>
                      </Col>
                      <Col>
                        <Text className="invoice_value">Yes</Text>
                      </Col>
                    </Row>
                    <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                      <Col span={8}>
                        <Text className="invoice_text">Featured Duration:</Text>
                      </Col>
                      <Col>
                        <Text className="invoice_value">{`${invoiceDetails?.duration} ${invoiceDetails?.duration === 1 ? "Month" : "Months"}`}</Text>
                      </Col>
                    </Row>

                    <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                      <Col span={8}>
                        <Text className="invoice_text">Featured Start Date:</Text>
                      </Col>
                      <Col>
                        <Text className="invoice_value">{moment.tz(startDate, userTimeZone).format("MMM DD, YYYY")} </Text>
                      </Col>
                    </Row>
                    <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                      <Col span={8}>
                        <Text className="invoice_text">Featured End Date:</Text>
                      </Col>
                      <Col>
                        <Text className="invoice_value">{moment.tz(endDate, userTimeZone).format("MMM DD, YYYY")}</Text>
                      </Col>
                    </Row>
                  </>
                }
                {(invoiceDetails?.promo_channels.includes("mobile_push") || invoiceDetails?.promo_channels.includes("email")) &&
                  <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                    <Col span={8}>
                      <Text className="invoice_text">Promotion type:</Text>
                    </Col>
                    <Col>
                      <Text className="invoice_value">
                        {invoiceDetails?.promo_channels.split(",").filter(channel => channel !== 'in_app' && channel !== 'featured')
                          .map(channel => channel === 'mobile_push' ? 'Phone' : capitalize(channel)).join(', ')}</Text>
                    </Col>
                  </Row>
                }

                <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                  <Col span={8}>
                    <Text className="invoice_text">Promotion Date:</Text>
                  </Col>
                  <Col>
                    <Text className="invoice_value">{moment.tz(invoiceDt, userTimeZone).format("MMM DD, YYYY")}</Text>
                  </Col>
                </Row>
                <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                  <Col span={8}>
                    <Text className="invoice_text">Promotion Time:</Text>
                  </Col>
                  <Col>
                    <Text className="invoice_value">{moment.tz(invoiceDt, userTimeZone).format("hh:mm A")}</Text>
                  </Col>
                </Row>

              </>
            )}
            <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
              <Col span={8}>
                <Text className="invoice_text">Payment Status:</Text>
              </Col>
              <Col align="end" span={status === "pending" && 8}>
                <Text className="invoice_value">{capitalize(status)}</Text>
              </Col>
              {status === "pending" && (
                <Col align="end" span={8}>
                  <Button
                    onClick={() => setModalVisible(true)}
                    size="small"
                    type="primary"
                  >
                    Mark as Paid
                  </Button>
                </Col>
              )}
            </Row>
            {(channel !== "cmpn_promo" || channel !== "promo") &&
              <>
                <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                  <Col span={8}>
                    <Text className="invoice_text">Time:</Text>
                  </Col>
                  <Col>
                    <Text className="invoice_value">{moment.tz(invoiceDt, userTimeZone).format("MMM DD, YYYY, hh:mm A")}</Text>
                  </Col>
                </Row>
              </>
            }

            {channel === "interaction" && (
              <div>
                <Row>
                  <Col span={8}>
                    <Text className="big">Invoice #:</Text>
                  </Col>
                  <Col>
                    <Text className="invoice_value">{invoice_nbr}</Text>
                  </Col>
                </Row>
                {cust_message !== null && (
                  <Row>
                    <Col span={8}>
                      <Text className="big">Message:</Text>
                    </Col>
                    <Col>
                      <Text>{cust_message}</Text>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col span={8}>
                    <Text className="big">Associate Name:</Text>
                  </Col>
                  <Col>
                    <Text className="invoice_value">{server_name}</Text>
                  </Col>
                </Row>
              </div>
            )}
            {status === "paid" && brand !== undefined && brand !== "" &&
              <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                <Col span={10}>
                  <Text className="invoice_text">Payment Method:</Text>
                </Col>
                <Text className="invoice_value">
                  {capitalize(brand)}
                </Text>
              </Row>
            }
            {billingEmail !== "" && (
              <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                <Col span={10}>
                  <Text className="invoice_text">Billing Email:</Text>
                </Col>

                <Text className="invoice_value">{billingEmail}</Text>
              </Row>
            )}
            {Object.keys(billingAddress).length > 0 && (
              <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                <Col span={8}>
                  <Text className="invoice_text">Billing Address:</Text>
                </Col>
                <Text className="invoice_value">{parseAddress(billingAddress)}</Text>
              </Row>
            )}

            {purchaseLoc &&  Object.keys(purchaseLoc).length > 0 && 
               <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
               <Col span={8}>
                 <Text className="invoice_text">Location:</Text>
               </Col>
               <Text className="invoice_value">{parseAddress(purchaseLoc)}</Text>
             </Row>
            }

            {type === "payable" && disputeStatus !== null && (
              <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                <Col span={12}>
                  <Text className="invoice_text">Dispute Status:</Text>
                </Col>
                <Col>
                  <Text type="danger" className="invoice_value">
                    {disputeStatus.charAt(0) +
                      disputeStatus.slice(1).toLowerCase()}
                  </Text>
                </Col>
              </Row>
            )}
            {channel === "catalog" && (
              <Button className="invoice_print" onClick={() => setShowQrVisible(true)}>
                Show QR Code
              </Button>
            )}
          </Col>
          <Col>
            {img_url !== null &&
              img_url !== undefined &&
              img_url.indexOf(".pdf") === -1 && (
                <div>
                  <img
                    alt="invoice"
                    onClick={() => setIsViewerOpen(true)}
                    className="transaction_invoice-img"
                    src={img_url}
                  />
                  {isViewerOpen && (
                    <ImageViewer
                      onClose={() => {
                        setIsViewerOpen(false);
                      }}
                      activeIndex={0}
                      src={[img_url]}
                    />
                  )}
                </div>
              )}
            {img_url !== null &&
              img_url !== undefined &&
              img_url.indexOf(".pdf") !== -1 && (
                <Button
                  type="default"
                  icon={<DownloadOutlined />}
                  size="large"
                  onClick={(e) => download(e, img_url, title)}
                >
                  Download Invoice
                </Button>
              )}
          </Col>
        </Row>

        {(inv.channel === "catalog" || inv.channel === "catalog_appointment") && renderListItems()}

        {gifteeDetails !== null &&
          <Card className="bizCard" style={{ padding: "0px", marginBottom: "1rem" }}>
            {!msgVisible ?
              <>
                <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                  <Col span={10}>
                    <Text className="invoiced_to">Receiver's Details</Text>
                  </Col>
                </Row>
                <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                  <Col span={10}>
                    <Text className="invoice_text">Receiver's Name:</Text>
                  </Col>
                  <Text className="invoice_value">{gifteeDetails?.name}</Text>
                </Row>
                <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                  <Col span={10}>
                    <Text className="invoice_text">Receiver's Email:</Text>
                  </Col>

                  <Text className="invoice_value">{gifteeDetails?.email}</Text>
                </Row>
                <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                  <Col span={10}>
                    <Text className="invoice_text">Receiver's Number:</Text>
                  </Col>
                  <Text className="invoice_value">{`${gifteeDetails?.phoneNumber !== null && gifteeDetails?.phoneNumber !== "" ? gifteeDetails?.countryCode + gifteeDetails?.phoneNumber : "N/A"}`}</Text>
                </Row>
                {gifteeDetails?.message !== null &&
                  <Row justify={"center"} style={{ paddingBottom: "12px" }}>
                    <Col span={10}>
                      <Button className="invoice_print" onClick={() => setMsgVisible(true)} >
                        View Message
                      </Button>
                    </Col>
                  </Row>
                }
              </>
              :
              <>
                <Button
                  type="link"
                  className='featuredSubs_pay-btn'
                  onClick={() => { setMsgVisible(false) }}
                >
                  <ArrowLeftOutlined />
                  Message
                </Button>
                <Row style={{ marginBottom: "8px" }}>
                  <Text className="invoice_value">
                    {gifteeDetails?.message}
                  </Text>
                </Row>
              </>
            }
          </Card>
        }

        {offers &&
          offers.map((row) => {
            const { offer_id, retail_price, offer_price, qty_total, row_total, row_tax } = row;
            const vhrs =
              channel === 'events' ? offers.filter((offer) => offer.offer_id === offer_id) : vouchers && vouchers.filter((voucher) => voucher.offerId === offer_id);

            return vhrs && vhrs.map((vhr, index) => {
              let res;
              if (appointments?.length > 0) {
                res = appointments.filter((item) => item.offer_id === vhr.offerId);
                vhr.appointments = res[0]?.appointment;
                vhr.index = index;
                vhr.qty = channel !== "events" && res[0]?.qty
              }
              vhr.currencySymbol = currencySymbol;
              const { redeemptionStatus, validTill } = vhr;

              return (
                <Card key={`${offer_id}-${index}`} style={{ marginBottom: "1rem", width: "100%" }} className="invoice_offerCard">
                  <Row justify={"space-between"} style={{ marginBottom: "12px" }}>
                    <Text className="invoiced_to">{capitalize(getChannelName(channel))}</Text>
                  </Row>
                  <Col style={{ width: "100%" }}>
                    <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                      <Col span={8}>
                        <Text className="invoice_text">{capitalize(getChannelName(channel))} Name:</Text>
                      </Col>
                      <Col>
                        <Text className="invoice_value">{row?.title}</Text>
                      </Col>
                    </Row>
                    {vhr?.appointments && vhr?.appointments[index] &&
                      <div key={vhr.index}>
                        <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                          <Col span={8}>
                            <Text className="invoice_text">Appointment:</Text>
                          </Col>
                          <Col>
                            <Text className="invoice_value">{moment((new Date(Object.keys(vhr?.appointments[index])))).format("MMM DD, YYYY")}</Text>
                          </Col>
                        </Row>
                        <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                          <Col span={8}>
                            <Text className="invoice_text">Start Time:</Text>
                          </Col>
                          <Col>
                            <Text className="invoice_value">{`${moment(Object.values(vhr?.appointments[index])[0]?.startTime.slice(0, 5), "HH:mm").format('hh:mm A')}`}</Text>
                          </Col>
                        </Row>
                        <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                          <Col span={8}>
                            <Text className="invoice_text">End Time:</Text>
                          </Col>
                          <Col>
                            <Text className="invoice_value">{`${moment(Object.values(vhr?.appointments[index])[0]?.endTime.slice(0, 5), "HH:mm").format('hh:mm A')}`}</Text>
                          </Col>
                        </Row>
                      </div>
                    }
                    <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                      <Col span={8}>
                        <Text className="invoice_text">Retail Price:</Text>
                      </Col>
                      <Col>
                        <Text className="invoice_value">
                          {currencySymbol}
                          {retail_price}
                        </Text>
                      </Col>
                    </Row>
                    <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                      <Col span={6}>
                        <Text className="invoice_text">Offer Price:</Text>
                      </Col>
                      <Text className="invoice_value">
                        {currencySymbol}
                        {offer_price}
                      </Text>
                    </Row>
                    <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                      <Col span={4}>
                        <Text className="invoice_text">Tax:</Text>
                      </Col>
                      <Text className="invoice_value">
                        {currencySymbol}
                        {row_tax}
                      </Text>
                    </Row>
                    {channel !== 'events' &&
                      <div key={vhr.objid} className="invoice_details">
                        <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                          <Col span={4}>
                            <Text className="invoice_text">Status:</Text>
                          </Col>
                          <Text className="invoice_value" style={{ color: getStatusColor(redeemptionStatus) }}>
                            {getDisplayStatus(redeemptionStatus)}
                          </Text>
                        </Row>
                        <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                          <Col span={6}>
                            <Text className="invoice_text">Valid Till:</Text>
                          </Col>
                          <Text type="danger" className="invoice_value" style={{ color: "#ED0C10" }}>
                            {getFormattedDtNew(validTill, userTimeZone)}
                          </Text>
                        </Row>
                        <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                          <Col span={6}>
                            <Text className="invoice_text">Voucher ID:</Text>
                          </Col>
                          <Col>
                            <Button
                              className="invoice_print"
                              onClick={() => {
                                setSelectedVoucher(vhr);
                                setScreen("edit");
                              }}
                            >
                              <Text className="invoice_value" style={{ color: "#257CDB" }}>
                                {vhr ? `VHR-${(vhr.objid + "").padStart(10, 0)}` : ""}
                              </Text>
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    }
                    {vhr.appointments && vhr.appointments.length > 0 && vhr.appointments[index] === undefined && (
                      <Row align="middle" justify={"space-between"} style={{ marginBottom: "8px" }}>
                        <Col>
                          <Text className="invoiced_to">Scheduled Later</Text>
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Card>
              )
            })
          }

          )}

        <Col className="bizCard" >
          {!endDate && sumQuantities(invoiceDetails?.items || invoiceDetails?.offers) !== 0 &&
            <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
              <Col span={12}>
                <Text className="invoice_text">
                  Total {capitalize(getchannelBought(channel))} Bought:
                </Text>
              </Col>
              <Col>
                <Text className="invoice_value">
                  {sumQuantities(invoiceDetails?.items || invoiceDetails?.offers)}
                </Text>
              </Col>
            </Row>}
          <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
            <Col span={8}>
              <Text className="invoice_text">
                Total Amount:
              </Text>
            </Col>
            <Col>
              <Text className="invoice_value">
                {currencySymbol}
                {baseAmount.toFixed(2)}
              </Text>
            </Col>
          </Row>

          <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
            <Col span={8}>
              <Text className="invoice_text">
                Total Tax:
              </Text>
            </Col>
            <Col>
              <Text className="invoice_value">
                {!isNaN(taxAmount) && taxAmount
                  ? `${currencySymbol}${taxAmount?.toFixed(2)}`
                  : "Included in Amount"}
              </Text>
            </Col>
          </Row>
          {fyndrCash > 0 &&
            <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
              <Col span={8}>
                <Text className="invoice_text">
                  Fyndr Cash:
                </Text>
              </Col>
              <Col>
                <Text className="invoice_value">
                  {fyndrCash ?
                    `-${currencySymbol}${fyndrCash}`
                    :
                    "N/A"
                  }
                </Text>
              </Col>
            </Row>
          }

          {tipAmount > 0 && (
            <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
              <Col span={8}>
                <Text className="invoice_text">
                  Tip:
                </Text>
              </Col>
              <Col>
                <Text className="invoice_value">
                  {currencySymbol}
                  {tipAmount}
                </Text>
              </Col>
            </Row>
          )}

          <Row justify={"space-between"}>
            <Col span={15}>
              <Text className="invoice_text">
                Total Payable Amount:
              </Text>
            </Col>
            <Col>
              <Text className="invoice_value">
                {currencySymbol}
                {(totalAmount - fyndrCash).toFixed(2)}
              </Text>
            </Col>
          </Row>

          {channel === "catalog" && indv.biz && type === "receivable" && (
            <Row style={{ marginTop: "1rem" }} justify={"space-between"}>
              <Col span={6}>
                <Text className="big">
                  Delivery
                </Text>
              </Col>
              <Col>
                <Radio.Group
                  optionType="button"
                  buttonStyle="solid"
                  onChange={fulfilInvoice}
                  value={fulfiled}
                >
                  <Radio.Button primary value={"yes"}>
                    Fulfilled
                  </Radio.Button>
                  <Radio.Button type="primary" value={"partial"}>
                    Partially Fulfilled
                  </Radio.Button>
                  {/* <Radio disabled={fulfiled !== null} value={null}>
                        Open
                      </Radio> */}
                </Radio.Group>
              </Col>
            </Row>
          )}
        </Col>
        <Buttons
          btn1="Print"
          onClick1={() => {
            window.print();
          }}
          classname1="invoice_dispute"
          classname="invoice_print"
          btn2={
            type === "payable" &&
            disputeStatus === null &&
            Math.round(Difference_In_Days) <= 30 &&
            "Raise a dispute"
          }
          disputeStyle={{
            backgroundColor: "white",
            color: "#ED0C10",
            border: "1px solid #ED0C10",
            padding: "10px 16px",
            borderRadius: "10px",
            height: "46px",
            fontSize: "16px",
          }
          }
          popvisible={
            Math.round(Difference_In_Days) > 30 || disputeStatus !== null
              ? true
              : false
          }
          onClick2={() => {
            disputeReasons();
            setText("");
            setSelectedDispute("");
            setRaiseDispute(true);
          }}
        />
      </div >
      <Modal
        title="Redeem QR"
        visible={screen !== null}
        footer={<InvoiceFooter />}
        onCancel={() => setScreen(null)}
        width={500}
        style={{ marginRight: "15px" }}
        centered
        className="modal_invoice"

      >
        <Row>
          {screen === "edit" && (
            <VoucherUpdate
              offer={selectedVoucher}
              type={type}
              callback={() => setScreen(null)}
            />
          )}
        </Row>
      </Modal>


      <Modal
        title="QR Code"
        visible={showQrVisible}
        onCancel={() => setShowQrVisible(null)}
        width={500}
        className="modal_invoice"
        style={{ marginRight: "15px" }}
        centered
        footer={<InvoiceFooter />}

      >
        <Row align={"middle"} style={{ marginBottom: "8px", width: "100%" }} className="bizCard" justify={"center"}>
          <Col  >
            <Row >
              <Col >
                <QRCode
                  value={`INV-${(invoice?.objid + "").padStart(10, 0)}`}
                  size={300}
                  logoWidth={80}
                  fgColor={fulfiled ? "#ccc" : "#000"}
                  logoImage={blueFyndr}
                />
              </Col>
            </Row>
            <Row justify="center" align={"middle"}>
              <Text className="invoiced_to">{`INV-${(inv?.objid + "").padStart(10, 0)}`}</Text>
            </Row>
          </Col>
        </Row>
      </Modal>
      <CustomizedModal
        visible={userComment}
        className="modelUploadImg_seeAllModal"
        title="Ratings & Reviews"
        onCancel={() => {
          setUserComment(false);
        }}
        modalWidth={!isMobile() ? "820px" : "350px"}
        btn2="Submit"
        classNameBtn1="btn1"
        isLoading={isLoading}
        btn2Disabled={isLoading}
        onBtn2Click={() => handleOnReviewSubmit()}
        btn2Type="primary"
        btn2Style={{ width: "147px", background: "#257CDB" }}
        content={
          <>
            <Row style={{ width: "100%" }}>
              <Col className="modelUploadImg_mainCol" span={24}>
                <Row className="modelUploadImg_starRow">
                  <Col style={{ paddingLeft: "26px" }}>
                    <div className="modelUploadImg_modelRateProduct">
                      Rate this product
                    </div>

                    <Row>
                      <Col>
                        <ReviewStars
                          rating={rating}
                          onChange={(value) => {
                            setRating(value);
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Divider className="divider-mobile" />
                <Row style={{ marginRight: "24px" }}>
                  <Col style={{ paddingLeft: "26px" }}>
                    <div className="modelUploadImg_modelShareExperience">
                      Share Your Experience
                    </div>
                    <div>
                      <TextArea
                        className="modelUploadImg_textArea"
                        onChange={(e) => {
                          setCommentForReview(e.target.value);
                        }}
                        value={commentForReview}
                      />
                    </div>
                  </Col>
                </Row>
                <div className=" modelUploadImg_modelAddImgtext">
                  Add Up to 6 Images
                </div>

                {!isMobile() ? (
                  <Row className="modelUploadImg_uploadImgRow">
                    {selectedImages.map((image, index) => (
                      <Col
                        lg={12}
                        md={12}
                        xl={8}
                        sm={12}
                        key={index}
                        align={"middle"}
                      >
                        {(!image || !image.url) && (
                          <Button
                            onClick={() => handleButtonClick(index)}
                            className="modelUploadImg_addImgBtn"
                            style={{ border: "none", padding: "0px" }}
                          >
                            <div className="modelUploadImg_uploadeImgBtn">
                              <div className="modelUploadImg_uploadeImgDiv">
                                <img
                                  src={uploadImage}
                                  className="modelUploadImg_uploadeImg"
                                />
                              </div>
                              <div className="modelUploadImg_btnText">
                                Add Image
                              </div>
                            </div>
                          </Button>
                        )}
                        {image && image.url && (
                          <div style={{ position: "relative", width: "196px" }}>
                            <img
                              src={image.url}
                              className="modelUploadImg_uploadedImgDisplay"
                              alt="Uploaded Image"
                            />
                            <Button
                              className="modelUploadImg_cancelButton"
                              onClick={() => handleCancelClick(index)}
                            >
                              <img
                                src={cancelImg}
                                alt="cancelImg"
                                className="modelUploadImg_cancelImgUploadBtn"
                              />
                            </Button>
                          </div>
                        )}
                      </Col>
                    ))}
                  </Row>
                ) : (
                  <div style={{ padding: "0 24px" }}>
                    <Row className="modelUploadImg_uploadImgRow-mobile">
                      <Col span={24}>
                        <div className=" modelUploadImg_modelAddImgtext-mobile">
                          Add Up to 6 Images
                        </div>
                        <Row
                          justify={"center"}
                          style={{ width: "100%", marginTop: "1.938rem" }}
                        >
                          {selectedImages?.map((image, index) => (
                            <Col>
                              {(!image || !image.url) && (
                                <Button
                                  onClick={(i) => handleButtonClick(i)}
                                  className="modelUploadImg_addImgBtnMob"
                                  style={{ border: "none", padding: "0px" }}
                                >
                                  <Col
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                    span={24}
                                  >
                                    <div className="modelUploadImg_uploadeImgBtn-mobile">
                                      <div className="modelUploadImg_uploadeImgDiv-mobile">
                                        <img
                                          src={uploadImage}
                                          className="modelUploadImg_uploadeImg-mobile"
                                        />
                                      </div>

                                      <div className="modelUploadImg_btnText-mobile">
                                        Add Image
                                      </div>
                                    </div>
                                  </Col>
                                </Button>
                              )}
                              {image && image.url && (
                                <div
                                  className="modelUploadImg_uploadedImgDivMob"
                                  style={{
                                    position: "relative",
                                    width: "130px",
                                    display: "flex",
                                    justifyContent: "center",
                                    paddingTop: "4px",
                                  }}
                                >
                                  <img
                                    src={image.url}
                                    className="modelUploadImg_uploadedImgDisplayMob"
                                    alt="Uploaded Image"
                                  />
                                  <Button
                                    className="modelUploadImg_cancelButtonMob"
                                    onClick={(index) =>
                                      handleCancelClick(index)
                                    }
                                  >
                                    <img src={cancelImg} alt="cancelImg" />
                                  </Button>
                                </div>
                              )}
                            </Col>
                          ))}
                        </Row>
                      </Col>
                    </Row>
                  </div>
                )}
              </Col>
            </Row>
          </>
        }
      />

      <ModalRaiseADispute
        visible={raiseDispute}
        className="transaction_raiseDisputeModal"
        width={400}
        button="submit"
        btnClassName="invoice_btn"
        input={
          <DropdownComponent
            style={{ minWidth: "100%" }}
            placeholder="Please select from the options "
            options={dispute}
            // value={selectDispute}
            onChange={(value) => setSelectedDispute(value)}
          />
        }
        description={
          <TextArea
            value={text}
            placeholder="Enter here...."
            style={{
              overflow: "hidden",
              height: 120,
            }}
            onChange={(e) => {
              setText(e.target.value);
            }}
          />
        }
        onCancel={() => setRaiseDispute(false)}
        onClick={() => handleOnSubmit()}
      />

      <ModalPaymentMethod
        visible={visible}
        className="admin-modal"
        onCancel={() => setModalVisible(false)}
        text={<h6>InvoiceId:{objid}</h6>}
        btn1="Cash"
        btnClick={() => {
          setPaymentMode("cash");
          UpdateStatusFunction("paid", objid, "cash");
        }}
        btn2="Other POS"
        onClick={(val) => {
          setPaymentMode("other POS");
          UpdateStatusFunction("paid", objid, "other POS");
        }}
      />
      <CustomizedModal
        visible={seeAllComments}
        footer={null}
        modalWidth="1169px"
        onCancel={() => {
          setSortByData({
            orderBy: "RATING",
            orderType: "DESC",
          });
          setSeeAllComments(false);
        }}
        title="All Reviews"
        className="allReviewComments"
        content={
          <>
            {!isMobile() ? (
              <AllReviewPopUp
                reviewsOverview={reviewsOverview}
                indv={indv}
                businessId={businessId}
                rating={reviewsOverview?.overallRating}
                ratingMap={reviewsOverview?.ratingMap}
                data={reviwdata}
                placeholder="Sort By"
                disabled={true}
                suffixIcon={<img src={Sliders} alt="Sliders" />}
                pagination={pagination}
                showHeader={false}
                columns={columns}
                dataSource={comments?.comments}
                handleTableChange={handleTableChange}
                starText={" Rate Us :"}
                defValue={JSON.stringify(sortByData)}
                onDropdownChange={(e) => {
                  setPagination({
                    ...pagination,
                    current: 1,
                  });
                  setSortByData(JSON.parse(e));
                }}
                options={[
                  {
                    value: JSON.stringify({
                      orderBy: "RATING",
                      orderType: "ASC",
                    }),
                    label: "Rating : Low To High",
                  },
                  {
                    value: JSON.stringify({
                      orderBy: "RATING",
                      orderType: "DESC",
                    }),
                    label: "Rating : High To Low",
                  },
                  {
                    value: JSON.stringify({
                      orderBy: "CREATED_DT",
                      orderType: "DESC",
                    }),
                    label: "Newest First",
                  },
                  {
                    value: JSON.stringify({
                      orderBy: "CREATED_DT",
                      orderType: "ASC",
                    }),
                    label: "Newest Last",
                  },
                ]}
                text="Write A Review :"
                onClick={() => {
                  setRating(0);
                  setCommentForReview("");
                  setSelectedImages(Array(6).fill(null));
                  if (!indvid) {
                    history.push("/login");
                  } else {
                    setSeeAllComments(false);
                    setUserComment(true);
                  }
                }}
              />
            ) : (
              <AllReviewPopUp
                reviewsOverview={reviewsOverview}
                indv={indv}
                businessId={businessId}
                rating={reviewsOverview?.overallRating}
                ratingMap={reviewsOverview?.ratingMap}
                reviwdata={reviwdata}
                placeholder="Sort By"
                disabled={true}
                suffixIcon={<img src={Sliders} alt="Sliders" />}
                defValue={JSON.stringify(sortByData)}
                shareText="Share Your Experience :"
                rateText="Rate the Store :"
                onDropdownChange={(e) => {
                  setPagination({
                    ...pagination,
                    current: 1,
                  });
                  setSortByData(JSON.parse(e));
                }}
                text="Write A Review :"
                shareClick={() => {
                  setRating(0);
                  setCommentForReview("");
                  setSelectedImages(Array(6).fill(null));
                  if (!indvid) {
                    history.push("/login");
                  } else {
                    setUserComment(true);
                  }
                }}
                options={[
                  {
                    value: JSON.stringify({
                      orderBy: "RATING",
                      orderType: "ASC",
                    }),
                    label: "Rating : Low To High",
                  },
                  {
                    value: JSON.stringify({
                      orderBy: "RATING",
                      orderType: "DESC",
                    }),
                    label: "Rating : High To Low",
                  },
                  {
                    value: JSON.stringify({
                      orderBy: "CREATED_DT",
                      orderType: "DESC",
                    }),
                    label: "Newest First",
                  },
                  {
                    value: JSON.stringify({
                      orderBy: "CREATED_DT",
                      orderType: "ASC",
                    }),
                    label: "Newest Last",
                  },
                ]}
              />
            )}
          </>
        }
      />
    </>
  );
};

export default InvoiceView;
