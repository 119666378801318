import { Button, Col, Image, Row } from "antd";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { motion } from "framer-motion";
import Images from "../../assets/images/Images";
import { parseAddress } from "../../common/util";
import getEnvVars from "../../environment";

const NearCategory = (props) => {
  const { web } = getEnvVars();

  const { popularOffers, setIsCategory, fetchMore, setSearchStr, imglistValue, title, catName, handleSeeAll, goToOffer, getPrice } =
    props;
  console.log("NearCategory", popularOffers);
  let data = popularOffers
    ?.filter((item) => item.cmpnType !== "events" && item.category.name === catName && !item.isFeatured && item.cmpnType !== "brochure")
  return (
    <div>
      {data?.length > 0 && (
        <>
          <Row
            style={{ margin: "1.5rem  0.2rem 1.5rem 0.2rem" }}
            align="middle"
            justify="space-between"
            gutter={[12]}
          >
            {data?.length > 0 && (
              <Col xs={12} md={9} sm={20} align="left">
                <span className="typo-row">{title}</span>
              </Col>
            )}
          </Row>
          <Row gutter={[24, 24]} style={{ padding: "0px 12px" }}>
            {data
              ?.slice(0, imglistValue)
              ?.map((item, index) => (
                <Col
                  key={index}
                  xs={24}
                  sm={12}
                  md={12}
                  lg={8}
                  xl={6}
                  xxl={6}
                  style={{ width: "100%"}}
                >
                  <a href={`${web}/offer-details/${item?.biz?.bizName?.replace(/[.\W]+/g, "-")}/${item?.qrCode}`} target="_blank" rel="noopener noreferrer">

                    <motion.div
                      whileHover={{ scale: 1.1 }}
                      transition={{ duration: 0.3 }}
                      onClick={() => goToOffer(item.biz.bizName, item.qrCode)}
                      className="bizHome_offers-list"
                    >
                      {/* added lazy loading for images */}
                      <LazyLoadImage
                        src={
                          item.images !== null
                            ? item?.images[0]?.img_url
                            : Images.PlaceHolder
                        }
                        alt="biz"
                        placeholderSrc={Images?.PlaceHolder}
                        className="bizHome_offers-img"
                      />
                      <h4  className="typo-card-header">{item?.biz?.bizName}</h4>

                      <h4  className="typo-card-subheader">{item?.title}</h4>
                      {item?.cmpnLocs[0] && (
                        <h4 className="typo-card-subheader">
                          {`${item?.cmpnLocs[0]?.distance
                            ? item?.cmpnLocs[0]?.distance.toFixed(1)
                            : "0"
                            } miles, ${parseAddress(item?.cmpnLocs[0])}`}
                        </h4>
                      )}

                      {getPrice(item?.cmpnOffers)}
                    </motion.div>
                  </a>
                </Col>
              ))}
          </Row>
          {/* <Row justify={"center"}>
            {data?.length >= 32 && (
              <Button
                className="bizHome_offerBtns"
                type="primary"
                // onClick={() => handleSeeAll(["offers"])}
                onClick={() => {
                  setIsCategory(true);
                  setSearchStr(catName);
                  console.log("item.label", catName);
                  fetchMore(true);
                }}

              >
                See all
              </Button>
            )}
          </Row> */}
        </>
      )}
    </div>
  );
};

export default NearCategory;
