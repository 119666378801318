import React, { useEffect, useState } from "react";
import AboutUsPhoto from "../../components/AboutUsPhoto/AboutUsPhoto";
import Faq from "../../components/Faq/Faq";
import AboutUsIndex from "../../components/AboutUsNew";
import AboutUsTabs from "../../components/AboutUsTabs/AboutUsTabs";
import Download from "../../components/Download/Download";
const Index = () => {
  return (
    <>
      <div className="wholler">
        <div className="whole">
          <AboutUsPhoto />
          <AboutUsIndex />

          <div className="app-container">
            <h1 className="fyndr-for">How to use Fyndr:</h1>
            <AboutUsTabs/>
            
          </div>
          
        </div>
        
      </div>      
    </>
  );
};

export default Index;