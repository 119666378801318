/* eslint-disable import/no-anonymous-default-export */
import { Actions } from "../common";
const {
  UPDT_CMPN,
  REMOVE_TYPE_SPECIFIC_DATA,
  UPDT_CMPN_IMG,
  UPSERT_OFFER,
  DELETE_OFFER,
  CMPN_UPSERTED,
  CMPNS_FETCHED,
  CMPN_INIT,
  CMPN_SEARCH,
  CMPN_LIKED,
  CMPN_SELECTED,
  CMPN_VIEW,
  FETCHED_BIZ_INFO,
  FETCHED_INDV_LIKES,
  LOG_OUT,
  UPDT_CMPN_VIDEO
} = Actions;

const cmpnStruct = {
  objid: null,
  bizid: null,
  cmpnType: null,
  categoryId: null,
  goal: null,
  startDt: null,
  endDt: null,
  title: null,
  descr: null,
  finePrint: null,
  brochureUri: null,
  offers: [],
  cmpnLocs: [],
  dc: [],
  cmpnImgs: [],
  campaignVideo: [],
  status: "active",
  isNew: true,
  biz: {},
};

const INITIAL_STATE = {
  cmpns: {},
  cmpn: cmpnStruct,
  cmpnView: cmpnStruct,
  offerSelected: null,
  cmpnsLiked: {},
  searching: "init",
  bizLikes: {},
  cmpnChanged: false,

};

export default function (state = INITIAL_STATE, action) {
  let offers;
  let cmpnList;

  switch (action.type) {
    case UPDT_CMPN:
      return { ...state, cmpn: { ...state.cmpn, ...action.payload } };
    case REMOVE_TYPE_SPECIFIC_DATA:
      return {
        ...state,
        cmpn: { ...state.cmpn, offers: [], brochureUri: null },
      };
    case UPDT_CMPN_IMG:
      const { index } = action.payload;
      let imgs = state.cmpn.cmpnImgs.filter((row) => row.index !== index);
      imgs.push(action.payload);
      imgs.sort((a, b) => a.index > b.index);

      return { ...state, cmpn: { ...state.cmpn, cmpnImgs: imgs } };

    case UPDT_CMPN_VIDEO:
      let videos = state.cmpn.campaignVideo.filter((row) => row.index !== action.payload.index);
      videos.push(action.payload);
      videos.sort((a, b) => a.index > b.index);
      return { ...state, cmpn: { ...state.cmpn, campaignVideo: videos } };
    case UPSERT_OFFER:
      offers = state.cmpn.offers.filter(
        (row) => row.objid !== action.payload.objid
      );
      offers.push(action.payload);
      offers.sort((a, b) => a.objid > b.objid);
      return { ...state, cmpn: { ...state.cmpn, offers } };
    case DELETE_OFFER:
      offers = state.cmpn.offers.filter((row) => row.objid !== action.payload);
      return { ...state, cmpn: { ...state.cmpn, offers } };
    case CMPN_UPSERTED:
      cmpnList = state.cmpns;
      cmpnList[action.payload.objid] = action.payload;
      return {
        ...state,
        cmpn: INITIAL_STATE.cmpn,
        cmpns: cmpnList,
        cmpnChanged: !state.cmpnChanged,
      };
    case CMPN_SELECTED:
      return { ...state, cmpn: action.payload };
    case CMPN_INIT:
      return { ...state, cmpn: INITIAL_STATE.cmpn };
    case CMPNS_FETCHED:
      return { ...state, cmpns: action.payload };
    case CMPN_VIEW:
      return { ...state, cmpnView: action.payload };
    case CMPN_LIKED:
      const { cmpnId, isDeleted } = action.payload;
      return {
        ...state,
        cmpnsLiked: {
          ...state.cmpnsLiked,
          [cmpnId]: { likedId: action.payload.objid, isDeleted },
        },
      };
    case FETCHED_INDV_LIKES:
      return {
        ...state,
        cmpnsLiked: { ...state.cmpnsLiked, ...action.payload },
      };
    case FETCHED_BIZ_INFO:
      return { ...state, bizLikes: { ...state.bizLikes, ...action.payload } };
    case CMPN_SEARCH:
      return { ...state, searching: action.payload };
    case LOG_OUT:{
      var myItem = localStorage.getItem('tourOpen');
      var myItem2 = localStorage.getItem('tourOpenDashboard')
      var navTour = localStorage.getItem('tourSideNav')
      var interactionsTour = localStorage.getItem('tourOpenInteractions');
      var indicatorTour = localStorage.getItem("tourOpenIndicators")

     localStorage.clear();
     localStorage.setItem('tourOpen',myItem);
     localStorage.setItem('tourOpenDashboard', myItem2);
     localStorage.setItem('tourSideNav', navTour)
     localStorage.setItem('tourOpenInteractions', interactionsTour)
     localStorage.setItem('tourOpenIndicators', indicatorTour)

      return {INITIAL_STATE} ;
    }
      
    default:
      return state;
  }
}
