import React from "react";
import image from "../../assets/images/AboutUs/download1.png";
import image2 from "../../assets/images/AboutUs/download2.png";
import downloadImg from "../../assets/images/downloadImg.png";
import download from "downloadjs";
import { Button, Col, Image, Row } from "antd";
import PlayStore from "../../assets/images/GooglePlayStoreButton.png";
import AppStore from "../../assets/images/AppStoreButton.png";
import BannerImage from "../../assets/images/BannerImageAboutUs.png";
import { isMobile } from "../../common/util";

const Download = () => {
  const handlePlayStoreClick = () => {
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.fyndr.us";
  };

  const handleAppStoreClick = () => {
    window.location.href = "https://apps.apple.com/in/app/fyndr/id1528140419";
  };
  return (
    <>
      <div className="banner-outer-div">
        <Row>
          <Col xs={2} lg={1}></Col>
          <Col xs={9} lg={9}>
            <div className="banner-inner-div">
              <Row>
                <Col span={24}>
                  <div className="banner-content-box">
                    <span className="font-banner-inner-box">
                      DOWNLOAD THE APP NOW
                    </span>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="play-store-buttons">
                    <div
                      style={{ height: "3rem", cursor: "pointer" }}
                      onClick={handlePlayStoreClick}
                    >
                      <img style={{ height: "3rem" }} src={PlayStore} />
                    </div>
                    <div
                      style={{ height: "3rem", cursor: "pointer", marginLeft: isMobile() ? "" : "2rem", marginTop: isMobile() ? "1rem" : "" }}
                      onClick={handleAppStoreClick}
                    >
                      <img style={{ height: "3rem" }} src={AppStore} />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={13} lg={14}>
            <Image
              preview={false}
              style={{ height: isMobile() ? "26rem" : "26rem" }}
              src={BannerImage}
              alt=""
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Download;
