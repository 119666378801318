import React from "react";
import { isMobile } from "../../common/util";

const RatingAverage = (props) => {
  const {rating}= props;
  return (
    <>
      {!isMobile() ? (
        <div classNameName="rating">
          <div className="rating-num">{rating}</div>
          <div className="rating-users">out of 5</div>
        </div>
      ) : (
        <div className="rating-mobile">
          <div className="rating-num-mobile">{rating}</div>
          <div className="rating-users-mobile">out of 5</div>
        </div>
      )}
    </>
  );
};

export default RatingAverage;

