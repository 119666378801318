import { Button, Input } from "antd";
import React from "react";
import SearchIcon from "../../assets/images/home-search-icon.svg";
import { isMobile } from "../../common/util";

const HomeSearch = (props) => {
    const {fetchMore , searchStr , setSearchStr, isVisible} = props
  return (
      <Input
        className="bizHome_search-input2"
        value={searchStr}
        style={{ width: "auto", height: "48px" , marginRight:isVisible ? "10px": ""}}
        onChange={async (event) => {
          setSearchStr(event.target.value);
        }}
        onPressEnter={() => fetchMore(true)}
        prefix={
          <img
            loading="lazy"
            src={SearchIcon}
            alt="search"
            style={{ marginRight: "0.75rem" }}
          />
        }
        suffix={
            !isMobile() && (
          <Button
            onClick={() => fetchMore(true)}
            type="primary"
            className="bizHome_search-btn2"
            style={{ background: "#fff", color: "#1891ff", border:"none", boxShadow:"none" }}
          >
            Search
          </Button>)
        }
        placeholder= {isMobile() ? "Search" : "Search Offers, Events & Businesses"}
      />
  );
};

export default HomeSearch;
