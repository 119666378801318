/* eslint-disable no-unused-vars */
import React, { useState } from "react";

import { Button, Row, Space, Col } from "antd";

import { Uploader } from "..";

const ImageUploader = (props) => {
  const { index, callback } = props;
  const [img, setImg] = useState();
  const [file, setFile] = useState(null);
  const [type, setType] = useState(null);
  const [isUp, setIsUp] = useState(false);
  const [fileType, setFileType] = useState("")

  const saveImage = async () => {
    if (img !== "") {
      const payload = {
        index,
        img: img.substring(img.indexOf("base64,") + 7),
        imgUri: img,

        type,
      };
      callback(payload, fileType);
      setIsUp(false);
    } else {
      setIsUp(false);
    }
  };

  return (
    <>
      <Row justify="center" style={{ width: "100%" }}>
        <Col>
          <Uploader
            allowedTypes={["image"]}
            callback={({ name, img, file, type }) => {
              setImg(img);
              setFile(file);
              setFileType(type);
              let index = type.lastIndexOf("/");
              let typeext = type.substr(index + 1);
              setType(typeext);
              setTimeout(() => {
                setIsUp(true);
              }, 2000);
            }}
            aspect={16 / 9}
          />
        </Col>
      </Row>
      <Row justify="center">
        <Space direction="horizontal">
          <Button
            size="large"
            type="primary"
            onClick={() => saveImage()}
            disabled={!isUp}
          >
            Save
          </Button>
          <Button
            size="large"
            type="warning"
            onClick={() => {
              callback();
              setIsUp(false);
            }}
          >
            Cancel
          </Button>
        </Space>
      </Row>
    </>
  );
};
export default ImageUploader;
