import React from "react";
import { Tabs, Row, Col, Button } from "antd";
import getEnvVars from "../../environment";

const { TabPane } = Tabs;
const { web } = getEnvVars();

function callback(key) {
  console.log(key);
}
const PreviewContainer = (props) => {
  return (
    <Tabs
      size="large"
      onChange={callback}
      centered
      type="card"
      tabBarExtraContent={{
        right: (
          <Button
            onClick={() => window.close()}
            style={{ marginTop: ".2rem" }}
            size="large"
            type="primary"
          >
            close
          </Button>
        ),
      }}
    >
      <TabPane style={{ height: "100vh" }} tab="Web View" key="1">
        <iframe
          width={"100%"}
          height={"100%"}
          src={`${web}/offer-details/preview`}
          title="Fyndr"
        ></iframe>
      </TabPane>
      <TabPane
        style={{ height: "100%", textAlign: "center", width: "100%" }}
        tab="Mobile View"
        key="2"
      >
        <Row justify="center">
          <Col style={{ height: "90vh", overflowY: "hidden" }} span={24}>
            <iframe
              frameBorder={0}
              style={{ overflowY: "hidden" }}
              width={"400 px"}
              height={"100%"}
              src={`${web}/offer-details/preview`}
              title="Fyndr"
            ></iframe>
          </Col>
        </Row>
      </TabPane>
    </Tabs>
  );
};
export default PreviewContainer;
