import { CommentOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";

const CommentButtons = (props) => {
  const { commnetOutLined, buttonText, buttonOnClick, disabled, style ,type} = props;
  return (
    <div className="reviewcomments_replyCommnetDiv">
      <Button
        style={style}
        type={type}
        size="large"
        className="reviewcomments_replyCommnetButton"
        onClick={buttonOnClick}
        disabled={disabled}
        {...props}
      >
        {buttonText} {commnetOutLined}
      </Button>
    </div>
  );
};

export default CommentButtons;
