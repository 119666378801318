/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import UserActions from "../../actions";
import { getFormatedDateTime, getFormattedDateTimeNew } from "../../common/util.js";
import { Row, Col, Slider, Typography } from "antd";
import { Theme } from "../../common";

const { Text } = Typography;

const Summary = (props) => {
  const { setFilters } = props;
  const {
    indv: { bizid },
  } = useSelector(({ auth }) => auth);

  const [data, setData] = useState(null);
  const [ctr, setCtr] = useState(1);

  const COLORS = {
    pending: "#2663CD",
    canceled: "#8898AA",
    paid: "#2DCE89",
  };

  useEffect(() => {
    doRefresh();
  }, [ctr]);

  const doRefresh = async () => {
    const { success, resp } = await UserActions.getInvoiceSummary({
      bizid,
      days: parseInt(ctr) * 30, //assuming 30 days in a month
    });
    if (success) {
      setData(resp);
    }
  };

  return (
    <>
      <Row
        align="middle"
        justify="space-between"
        className="transaction_by-month"
      >

        <Col xs={24} md={10} span={10}>

          <Text strong>
            Past {ctr} {ctr > 1 ? `Months` : "Month"}{" "}
          </Text>
          <Slider
            railStyle={{ backgroundColor: Theme.grey, height: ".2rem" }}
            onAfterChange={(value) => {
              setCtr(value);
            }}
            defaultValue={ctr}
            max={12}
            min={1}
            disabled={false}
            style={{ color: Theme.blue }}
          />
        </Col>
      </Row>
      {/* sales summary option on transaction page */}
      <Row className="transaction_info" align="middle" justify="start">
        <Col>
          {data?.length > 0 && (
            <>
              <h6>
                From {getFormatedDateTime(data[0]["startDt"])} (UTC) till date
              </h6>
            </>
          )}
        </Col>
      </Row>
      <Row align="middle">
        {data &&
          data
            .filter((row) => row.channel === "offers" && row.status === "paid")
            .map((row, index) => {
              return (
                <Col
                  onClick={() => {
                    setFilters({
                      channel: row.channel,
                      type: row.status,
                      months: ctr,
                    });
                  }}
                  xs={24}
                  md={8}
                  span={8}
                  className="summary_card"
                >
                  <Row
                    align="middle"
                    className="transaction_offer-sold"
                    justify="space-between"
                  >
                    <Col>
                      <div
                        style={{ background: COLORS.paid }}
                        className="summary-circle"
                      >
                        <i
                          style={{
                            color: "#fff",
                            fontSize: "1.5rem",
                          }}
                          className="fa fa-tag"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </Col>
                    <Col>
                      <h5 className="summary-title">Offers Sold</h5>
                    </Col>
                    <Col>
                      <h5 className="summary-title">{`${row.currencySymbol}${(
                        row.aggTipAmt +
                        row.aggBaseAmt +
                        row.aggTaxAmt
                      ).toFixed(2)}`}</h5>
                    </Col>
                  </Row>
                  {row.totalPurchaseOffers > 0 && (
                    <Row
                      className="transaction_details"
                      align="middle"
                      justify="space-between"
                    >
                      <Col>
                        <h6 className="summary-sub">Quantity</h6>
                      </Col>
                      <Col>
                        <h6 className="summary-sub">{`${row.totalPurchaseOffers}`}</h6>
                      </Col>
                    </Row>
                  )}
                  <Row align="middle" justify="space-between">
                    <Col>
                      <h6 className="summary-sub">Amount</h6>
                    </Col>
                    <Col>
                      <h6 className="summary-sub">{`${
                        row.currencySymbol
                      }${row.aggBaseAmt.toFixed(2)}`}</h6>
                    </Col>
                  </Row>
                  {row.aggTipAmt > 0 && (
                    <Row align="middle" justify="space-between">
                      <Col>
                        <h6 className="summary-sub">Tip</h6>
                      </Col>
                      <Col>
                        <h6 className="summary-sub">{`${
                          row.currencySymbol
                        }${row.aggTipAmt.toFixed(2)}`}</h6>
                      </Col>
                    </Row>
                  )}
                  <Row align="middle" justify="space-between">
                    <Col>
                      <h6 className="summary-sub">Tax</h6>
                    </Col>
                    <Col>
                      <h6 className="summary-sub">{`${row.currencySymbol}${
                        row.aggTaxAmt ? row.aggTaxAmt.toFixed(2) : 0
                      }`}</h6>
                    </Col>
                  </Row>
                  <Row align="middle" justify="space-between">
                    <Col>
                      <h6 className="summary-sub">Total</h6>
                    </Col>
                    <Col>
                      <h6 className="summary-sub">{`${row.currencySymbol}${(
                        row.aggBaseAmt + row.aggTaxAmt
                      ).toFixed(2)}`}</h6>
                    </Col>
                  </Row>
                </Col>
              );
            })}

        {data &&
          data
            .filter((row) => row.channel !== "offers" && row.status === "paid")
            .map((row, index) => {
              return (
                <Col
                  onClick={() => {
                    setFilters({
                      channel: row.channel,
                      type: row.status,
                      months: ctr,
                    });
                  }}
                  xs={24}
                  md={8}
                  span={8}
                  className="summary_card"
                >
                  <Row
                  className="transaction_paid"
                    align="middle"
                    justify="space-between"
                  >
                    <Col>
                      <div
                        style={{ background: COLORS.paid }}
                        className="summary-circle"
                      >
                        <i
                          style={{
                            color: "#fff",
                            fontSize: "1.5rem",
                          }}
                          className="fas fa-file-invoice-dollar"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </Col>
                    <Col>
                      <h5 className="summary-title">
                        {row.channel === "promo"
                          ? "Campaign Invoices Paid"
                          : "Invoices Paid"}
                      </h5>
                    </Col>
                    <Col>
                      <h5 className="summary-title">{`${row.currencySymbol}${(
                        row.aggTipAmt +
                        row.aggBaseAmt +
                        row.aggTaxAmt
                      ).toFixed(2)}`}</h5>
                    </Col>
                  </Row>
                  {row.totalPurchaseOffers > 0 && (
                    <Row
                    className="transaction_details"
                      align="middle"
                      justify="space-between"
                    >
                      <Col>
                        <h6 className="summary-sub">Quantity</h6>
                      </Col>
                      <Col>
                        <h6 className="summary-sub">{`${row.totalPurchaseOffers}`}</h6>
                      </Col>
                    </Row>
                  )}
                  <Row align="middle" justify="space-between">
                    <Col>
                      <h6 className="summary-sub">Amount</h6>
                    </Col>
                    <Col>
                      <h6 className="summary-sub">{`${
                        row.currencySymbol
                      }${row.aggBaseAmt.toFixed(2)}`}</h6>
                    </Col>
                  </Row>
                  {row.aggTipAmt > 0 && (
                    <Row align="middle" justify="space-between">
                      <Col>
                        <h6 className="summary-sub">Tip</h6>
                      </Col>
                      <Col>
                        <h6 className="summary-sub">{`${
                          row.currencySymbol
                        }${row.aggTipAmt.toFixed(2)}`}</h6>
                      </Col>
                    </Row>
                  )}
                  <Row align="middle" justify="space-between">
                    <Col>
                      <h6 className="summary-sub">Tax</h6>
                    </Col>
                    <Col>
                      <h6 className="summary-sub">{`${row.currencySymbol}${
                        row.aggTaxAmt ? row.aggTaxAmt.toFixed(2) : 0
                      }`}</h6>
                    </Col>
                  </Row>
                  <Row align="middle" justify="space-between">
                    <Col>
                      <h6 className="summary-sub">Total</h6>
                    </Col>
                    <Col>
                      <h6 className="summary-sub">{`${row.currencySymbol}${(
                        row.aggTipAmt +
                        row.aggBaseAmt +
                        row.aggTaxAmt
                      ).toFixed(2)}`}</h6>
                    </Col>
                  </Row>
                </Col>
              );
            })}

        {data &&
          data
            .filter(
              (row) => row.channel !== "offers" && row.status === "pending"
            )
            .map((row, index) => {
              return (
                <Col
                  onClick={() => {
                    setFilters({
                      channel: row.channel,
                      type: row.status,
                      months: ctr,
                    });
                  }}
                  xs={24}
                  md={8}
                  span={8}
                  className="summary_card"
                >
                  <Row
                    className="transaction_pending"
                    align="middle"
                    justify="space-between"
                  >
                    <Col>
                      <div
                        style={{ background: COLORS.pending }}
                        className="summary-circle"
                      >
                        <i
                          style={{
                            color: "#fff",
                            fontSize: "1.5rem",
                          }}
                          className="fas fa-file-invoice-dollar"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </Col>
                    <Col>
                      <h5 className="summary-title">
                        {row.channel === "promo"
                          ? "Campaign Invoices Pending"
                          : "Invoices Pending"}
                      </h5>
                    </Col>
                    <Col>
                      <h5 className="summary-title">{`${row.currencySymbol}${(
                        row.aggTipAmt +
                        row.aggBaseAmt +
                        row.aggTaxAmt
                      ).toFixed(2)}`}</h5>
                    </Col>
                  </Row>
                  {row.totalPurchaseOffers > 0 && (
                    <Row
                      className=" transaction_details"
                      align="middle"
                      justify="space-between"
                    >
                      <Col>
                        <h6 className="summary-sub">Quantity</h6>
                      </Col>
                      <Col>
                        <h6 className="summary-sub">{`${row.totalPurchaseOffers}`}</h6>
                      </Col>
                    </Row>
                  )}
                  <Row align="middle" justify="space-between">
                    <Col>
                      <h6 className="summary-sub">Amount</h6>
                    </Col>
                    <Col>
                      <h6 className="summary-sub">{`${
                        row.currencySymbol
                      }${row.aggBaseAmt.toFixed(2)}`}</h6>
                    </Col>
                  </Row>
                  {row.aggTipAmt > 0 && (
                    <Row align="middle" justify="space-between">
                      <Col>
                        <h6 className="summary-sub">Tip</h6>
                      </Col>
                      <Col>
                        <h6 className="summary-sub">{`${
                          row.currencySymbol
                        }${row.aggTipAmt.toFixed(2)}`}</h6>
                      </Col>
                    </Row>
                  )}
                  <Row align="middle" justify="space-between">
                    <Col>
                      <h6 className="summary-sub">Tax</h6>
                    </Col>
                    <Col>
                      <h6 className="summary-sub">{`${row.currencySymbol}${
                        row.aggTaxAmt ? row.aggTaxAmt.toFixed(2) : 0
                      }`}</h6>
                    </Col>
                  </Row>
                  <Row align="middle" justify="space-between">
                    <Col>
                      <h6 className="summary-sub">Total</h6>
                    </Col>
                    <Col>
                      <h6 className="summary-sub">{`${row.currencySymbol}${(
                        row.aggTipAmt +
                        row.aggBaseAmt +
                        row.aggTaxAmt
                      ).toFixed(2)}`}</h6>
                    </Col>
                  </Row>
                </Col>
              );
            })}
      </Row>
    </>
  );
};

export default Summary;
